import corner_img from '../bsl/core/d8d/img/bottom-left-corner.png'
//import flip_img from '../bsl/core/d8d/img/flip-orientation.png'
import cube_img from '../bsl/core/d8d/img/horizontal.png'
import vertical_img from '../bsl/core/d8d/img/vertical.png'

import left_to_right_img from '../bsl/core/d8d/img/left-to-right-arrow.png'
import top_to_bottom_img from '../bsl/core/d8d/img/top-to-bottom-arrow.png'
import right_to_left_img from '../bsl/core/d8d/img/right-to-left-arrow.png'
import bottom_to_top_img from '../bsl/core/d8d/img/bottom-to-top-arrow.png'











( function() {
    var ns          = window.b$l;
    var $$          = ns.$$;
    var sn          = ns.sn;
    var fapp        = sn('fapp');
    var fconf       = ns.fconf;
    var fmethods    = sn('methods',fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );

    var imagesList =
    {
        "bottom-right-corner" : corner_img,
        "cube" : cube_img,
        //"flip-orientation" : flip_img, //possibly bug? todm
        "vertical_img" : vertical_img,
        "left_to_right_img" : left_to_right_img,
        "top_to_bottom_img" : top_to_bottom_img,
        "right_to_left_img" : right_to_left_img,
        "bottom_to_top_img" : bottom_to_top_img,
    };
    fmethods.build_cell__html = build_cell__html;
    return;

    function build_cell__html({
        cix,
        dtable,
        tr$,
        cells$,
        dom$,
        rix,
        ts,
        tscale,
        dataId,
        colFr,
    }) {

        var pmcard = dtable.context;
        var pageIx = pmcard.pageIx;
        var cardIx = pmcard.cardIx;
        var dtcard = pmcard.dtcard;
        var dsg_id = dtcard.dsg_id;
        var cellCssPath = 'td-' + rix + '-' + cix +
                          '-pageix-' + pageIx +
                          '-cardix-' + cardIx;
        var cellval = dtable.dataModel__rows[ rix ][ cix ];

        cells$[ cix ] = $$
            .c( 'td' )
            .addClass( 'fluid-cell ' + cellCssPath )
            .to( tr$ )
            //irrelevant
            //.css( 'word-wrap', 'pre-wrap' )
            //.css( 'overflow', 'hidden' )
            //.css( 'overflow-wrap', 'break-word' )

            .css( 'word-wrap', 'break-word' )

            //todm: input box must be more comfortable ... do not depend
            //      on parent (cell) size ...
            .ch( $$.span()
                 .addClass( 'cellcontent' )
                 .html( cellval )
            )
            ;

        ///makes dataGr editable
        if( dataId === "cells-bound" || dataId === "horizontal-line" ) {

            var series = dtable.context.dtcard.style8data_tpl.series;
            var lscape = ts.orientationLandscape;

            ////unfortunately in context of "build_cell__html",
            ////rows and cells original meaning is lost, so here we restore it
            var virtualRow = lscape ? rix : cix;
            var virtualCell = lscape ? cix : rix;
            var virtSerie = series[ virtualRow ];
            var standaloneStyle = virtSerie.cellstyle[ virtualCell ] =
                                  virtSerie.cellstyle[ virtualCell ] ||
                                  //fresh style is cloned from table css -- poor way:
                                  //ns.clonetree( ts.editableCss );
                                  {};

            var serieDataCell_binding = ns.sn( 'serieDataCell_binding', virtSerie, [] );
            cells$[ cix ]
            .addClass( 'not-under-edit' )
            .e( 'click', () => {
                if( ns.fheap.tasksInProgress && ns.fheap.tasksInProgress.length ) {
                    //ccc( 'tasks are on: skipping', ns.fheap.tasksInProgress );
                    return;
                }
                fmethods.finalizes_tasksInProgress();
                if( dtable.context.dtcard.dataId === 'horizontal-line' ) {
                    //todo ... this is a patch ... not a design,
                    //points to 'hr' not to cell$
                    //todo: this is broke: do investigate:
                    //      var htmlEl$ = cells$[ cix ].q( 'hr' );
                    var htmlEl$ = $$.q( '.' + cellCssPath + ' hr' );

                } else {
                    htmlEl$ = cells$[ cix ];
                    //ccc( 'installing overlay' );
                    ///cell's stand alone content editor
                    fmethods.managesInputOverlay({

                        //path to cell's-span-content
                        cccpath                 : '.fluid-cell.' +
                                                   cellCssPath + ' > .cellcontent',

                        modelObject             : virtSerie.data,
                        model_prop0ix           : virtualCell,
                        serieDataCell_binding,
                        taskCompletionCallback  : () => {
                            if( dsg_id === 'pages-header' ) {
                                //.todm ... rebuilding table is too much for this local task
                                fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard });
                            } else {
                                fmethods.cellBound__tpl_2_model8dom( dtable.context );
                            }
                        },
                    });
                }

                ///cell's standalone style editor
                fmethods.setsup_styleEditor({
                    controlsCssPath : '.content-decoration-controls',
                    styleTpl : standaloneStyle,
                    htmlEl$,
                    //styles2html : //nothing to do ... we don't change table(s)
                    updateHeadersIfAny : function() {
                        if( dsg_id === 'pages-header' ) {
                            fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                        }
                    }
                });
            });
        }
        //---------------------------------------------
        // //\\ builds handles dom
        //---------------------------------------------
        if( fconf.ROLE === 'CONSTRUCTOR' && rix + cix === 0 ) {
            dom$.sizeHandle$ = $$
                .dct( 'table-handle size-hanldle', cells$[ cix ] )
                .ch( $$
                        .img()
                        .cls( 'img' )
                        .a( 'title', 'resize table' )
                        .a( 'src', imagesList[ 'bottom-right-corner' ] )
                );
            ///============================================
            /// hides or shows table's handles
            ///============================================
        }
        if( rix === 0 ) {
            var cellSizes$ = ns.sn( 'cellSizes$', dom$, [] );
            if( dtable.context.dtcard.dataId !== 'horizontal-line' ) { //todm patch
                /// ... empty array cellSizes$ won't break init d8d
                cellSizes$[ cix ] = $$
                    .dct( 'table-handle column-handle', cells$[ cix ] )
                    .addClass( 'column-handle-' + dtable.tableId + '-' + cix )
                    .ch( $$
                            .img()
                            .cls( 'img' )
                            .a( 'title', 'column width' )
                            .a( 'src', imagesList[ 'vertical_img' ] )
                    );
            }
        }

        //---------------------------------------------
        // //\\ columns and rows editor
        //---------------------------------------------
        var isLands = ts.orientationLandscape;
        ///doing job only for landscape for now ...
        ///port will be later ...
        if( ( dataId === 'cells-bound' || dataId === "horizontal-line" ) && isLands ) {
            if( cix === 0 ) {
                var rowInserters$ = ns.sn( 'rowInserters$', dom$, [] );
                rowInserters$[ rix ] = $$
                    .dct( 'table-handle row-inserter', cells$[ cix ] )
                    .addClass( 'row-inserter-' + dtable.tableId + '-' + rix )
                    .ch( $$
                            .img()
                            .cls( 'img' )
                            .a( 'title', 'insert field' )
                            .a( 'src', imagesList[ 'left_to_right_img' ] )
                    )
                    .e( 'click', () => {
                        fmethods.finalizes_tasksInProgress();
                        var series = dtcard.style8data_tpl.series;
                        series.splice( rix, 0,
                            ns.paste( {}, series[ rix ] ) );
                        finalizes_header8table_grid({ pmcard });
                    })
                    ;
            }
            if( rix === 0 ) {
                var recordInserters$ = ns.sn( 'recordInserters$', dom$, [] );
                recordInserters$[ rix ] = $$
                    .dct( 'table-handle record-inserter', cells$[ cix ] )
                    .addClass( 'record-inserter-' + dtable.tableId + '-' + rix )
                    .ch( $$
                            .img()
                            .cls( 'img' )
                            .a( 'title', 'insert record' )
                            .a( 'src', imagesList[ 'top_to_bottom_img' ] )
                    )
                    .e( 'click', () => {
                        fmethods.finalizes_tasksInProgress();
                        var series = dtcard.style8data_tpl.series;
                        series.forEach( ser => {
                            ser.data.splice( cix, 0, ser.data[ cix ] )
                        });
                        finalizes_header8table_grid({ pmcard });
                    })
                    ;
            }
            if( cix === 0 ) {
                var rowRemovers$ = ns.sn( 'rowRemovers$', dom$, [] );
                rowRemovers$[ rix ] = $$
                    .dct( 'table-handle row-remover', cells$[ cix ] )
                    .addClass( 'row-remover-' + dtable.tableId + '-' + rix )
                    .ch( $$
                            .img()
                            .cls( 'img' )
                            .a( 'title', 'remove field' )
                            .a( 'src', imagesList[ 'right_to_left_img' ] )
                    )
                    .e( 'click', () => {
                        fmethods.finalizes_tasksInProgress();
                        var series = dtcard.style8data_tpl.series;
                        if( series.length < 2 ) {
                            alert( 'single field cannot be removed' );
                            return;
                        }
                        series.splice( rix, 1 );
                        finalizes_header8table_grid({ pmcard });
                    })
                    ;
            }
            if( rix === 0 ) {
                var recordRemovers$ = ns.sn( 'recordRemovers$', dom$, [] );
                recordRemovers$[ rix ] = $$
                    .dct( 'table-handle record-remover', cells$[ cix ] )
                    .addClass( 'record-remover-' + dtable.tableId + '-' + rix )
                    .ch( $$
                            .img()
                            .cls( 'img' )
                            .a( 'title', 'remove record' )
                            .a( 'src', imagesList[ 'bottom_to_top_img' ] )
                    )
                    .e( 'click', () => {
                        fmethods.finalizes_tasksInProgress();
                        var series = dtcard.style8data_tpl.series;
                        if( series[0].data.length < 2 ) {
                            alert( 'single record cannot be removed' );
                            return;
                        }
                        series.forEach( ser => {
                            ser.data.splice( cix, 1 )
                        });
                        finalizes_header8table_grid({ pmcard });
                    })
                    ;
            }

            function finalizes_header8table_grid({ pmcard })
            {
                if( dsg_id === 'pages-header' ) {
                    fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard });
                } else {
                    pmcard.cdataOptions = pmcard.dtcard.style8data_tpl;
                    ns.methods.calls_tableAPI({ pmcard, doDrawInlineCssAfter:true });
                }
            }
        }
        //---------------------------------------------
        // \\// columns and rows editor
        // \\// builds handles dom
        //---------------------------------------------
    }

}) ();

