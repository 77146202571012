import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import Edit from './edit';
import ReactInputMask from 'react-input-mask';
import Alert from './alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import PatientsSelect from './patientSelectModal';

class PatientDetails extends Component {
  constructor(props) {
    super(props);
    var id = this.props.data.id;
    this.path = '/patients';
    this.url = this.path + '/' + id;
    this.state = {
      isModalOpen: false,
      alertMessage: null,
      alertType: 'alert-success',
      locked: id !== 0,
      patients: [],
      data: {
        id: id,
      },
      comments: '',
      selectedFile: null,
      filePath: '', // New state for file path
    };
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.read();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.id !== this.props.data.id) {
      this.url = this.path + '/' + this.props.data.id;
      this.read();
    }
  }

  submit(event) {
    if (event) event.preventDefault();
    this.state.data.id ? this.update() : this.create();
  }

  create() {
    var success = this.dataHandler.bind(this);
    var failure = this.errorHandler.bind(this);
    var data = {
      firstName: this.state.data.firstName,
      middleName: this.state.data.middleName,
      lastName: this.state.data.lastName,
      birthDate: this.state.data.birthDate,
      height: this.state.data.height,
      neck: this.state.data.neck,
      gender: this.state.data.gender || 'male',
      maritalStatus: this.state.data.maritalStatus || 'married',
      email: this.state.data.email,
      phone: this.state.data.phone,
      organizationId: this.props.organization.id,
    };
    $.ajax({
      method: 'POST',
      url: this.path,
      data: data,
    })
      .done(success)
      .fail(failure);
  }

  read() {
    if (!this.props.data.id) return;
    var success = this.dataHandler.bind(this);
    var failure = this.errorHandler.bind(this);
    $.ajax({
      method: 'GET',
      url: this.url,
    })
      .done(success)
      .fail(failure);
  }

  update() {
    if (!this.state.data.firstName) {
      return this.setState({
        alertMessage: 'First Name is required.',
        alertType: 'alert-danger',
      });
    }
    if (!this.state.data.lastName) {
      return this.setState({
        alertMessage: 'Last Name is required.',
        alertType: 'alert-danger',
      });
    }
    var success = this.saveSuccess.bind(this);
    var failure = this.errorHandler.bind(this);
    $.ajax({
      method: 'PUT',
      url: this.url,
      data: this.state.data,
    })
      .done(success)
      .fail(failure);
  }

  list() {
    var success = this.dataHandler.bind(this);
    var failure = this.errorHandler.bind(this);
    $.ajax({
      method: 'GET',
      url: this.path,
    })
      .done(success)
      .fail(failure);
  }

  destroy() {
    return;
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  handelPatientSelection = (selectedPatientId) => {
    this.setState(prevState => ({
      mergingPatients: {
        mainPatientId: this.state.data.id,
        secondaryPatientId: selectedPatientId,
      },
    }));
    this.closeModal();
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ selectedFile: file, filePath: event.target.value });
    }
  }

  uploadReport = () => {
    const { selectedFile, comments } = this.state;
    if (!selectedFile) {
      this.setState({
        alertMessage: 'Please select a file to upload.',
        alertType: 'alert-danger',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('organizationId', this.props.organization.id);
    formData.append('patientId', this.state.data.id);
    formData.append('comments', comments); // Include comments if any
    formData.append('studyDateTime', this.state.data.studyDateTime); // Include studyDateTime if any

    $.ajax({
      url: '/reports',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: (response) => {
        this.setState({
          alertMessage: 'Report uploaded successfully.',
          alertType: 'alert-success',
        });
      },
      error: (error) => {
        this.setState({
          alertMessage: 'Failed to upload report.',
          alertType: 'alert-danger',
        });
      },
    });
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  };

  handleCommentsChange = (event) => {
    this.setState({ comments: event.target.value });
  }

  saveSuccess(data) {
    this.setState({
      alertMessage: 'Saved Successfully.',
      alertType: 'alert-success',
    });
    this.dataHandler(data);
  }

  dataHandler(data) {
    this.setState({
      data: data,
      locked: true,
    });
    if (!data.id || data.deleted) {
      this.props.location.history.push(this.path);
    }
  }

  errorHandler(res) {
    var body = res.responseJSON;
    var error = new Error(body.error.message);
    this.setState({
      alertMessage: error.message,
      alertType: 'alert-danger',
    });
  }

  unlock(bool) {
    this.setState({ locked: false });
  }

  formatData(data) {
    if (data.heightFeet || data.heightInches) {
      var feet = parseInt(data.heightFeet);
      var inches = parseInt(data.heightInches);
      data.height = feet * 12 + inches;
    }
    if (data.height) {
      var totalInches = parseInt(data.height);
      data.heightFeet = Math.floor(totalInches / 12);
      data.heightInches = totalInches % 12;
    }
    if (data.birthDate) {
      data.birthDate = moment(data.birthDate).utc().format('YYYY-MM-DD');
    }
    if (data.studyDateTime) {
      data.studyDateTime = moment(data.studyDateTime).format('YYYY-MM-DDTHH:mm');
    }
    return data;
  }

  removeAlert() {
    this.setState({
      alertMessage: null,
    });
  }

  renderCardBody() {
    var data = this.state.data;
    var locked = this.state.locked;
    var handleChange = this.changeHandler.bind(this);
    data = this.formatData(data);
    var height =
      data.heightFeet !== undefined
        ? `${data.heightFeet} ft. ${data.heightInches} in.`
        : null;
    var weight = data.weight !== 0 ? `${data.weight} lbs.` : null;
    var neck = data.neck !== 0 ? `${data.neck} in` : null;

    if (this.state.locked) {
      return (
        <div className="card-body" style={{ padding: 5 }}>
          <div className="row">
            <div className="col">
              <span className="font-weight-bold">Birth Date: </span>
              <span>
                {moment(data.birthDate).utc().format('MMMM DD, YYYY')}
              </span>
            </div>
            <div className="col">
              <span className="font-weight-bold">Gender: </span>
              <span>{data.gender}</span>
            </div>
            <div className="col">
              <span className="font-weight-bold">Phone: </span>
              <span>{data.phone}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="font-weight-bold">Height: </span>
              <span>{height}</span>
            </div>
            <div className="col">
              <span className="font-weight-bold">Weight: </span>
              <span>{weight}</span>
            </div>
            <div className="col">
              <span className="font-weight-bold">Neck: </span>
              <span>{neck}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={data.firstName || ''}
                onChange={handleChange}
                disabled={locked}
              />
            </div>
            <div className="form-group col">
              <label>Middle Name</label>
              <input
                className="form-control"
                type="text"
                name="middleName"
                value={data.middleName || ''}
                onChange={handleChange}
                disabled={locked}
              />
            </div>
            <div className="form-group col">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                name="lastName"
                value={data.lastName || ''}
                onChange={handleChange}
                disabled={locked}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Height</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min="0"
                  max="9"
                  name="heightFeet"
                  value={data.heightFeet || ''}
                  onChange={handleChange}
                  disabled={locked}
                />
                <div className="input-group-append">
                  <div className="input-group-text">ft.</div>
                </div>
                <input
                  className="form-control"
                  type="number"
                  name="heightInches"
                  max="11"
                  value={data.heightInches || ''}
                  onChange={handleChange}
                  disabled={locked}
                />
                <div className="input-group-append">
                  <div className="input-group-text">in.</div>
                </div>
              </div>
            </div>
            <div className="form-group col">
              <label>Weight</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="weight"
                  value={data.weight || ''}
                  onChange={handleChange}
                  disabled={locked}
                />
                <div className="input-group-append">
                  <div className="input-group-text">lbs.</div>
                </div>
              </div>
            </div>
            <div className="form-group col">
              <label>Neck</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="neck"
                  value={data.neck || ''}
                  onChange={handleChange}
                  disabled={locked}
                />
                <div className="input-group-append">
                  <div className="input-group-text">in.</div>
                </div>
              </div>
            </div>
            <div className="form-group col">
              <label>Sex</label>
              <select
                className="form-control"
                name="gender"
                value={data.gender || 'Male'}
                onChange={handleChange}
                disabled={locked}
              >
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
            <div className="form-group col">
              <label>Marital Status</label>
              <select
                className="form-control"
                name="maritalStatus"
                value={data.maritalStatus || 'Married'}
                onChange={handleChange}
                disabled={locked}
              >
                <option>Married</option>
                <option>Single</option>
                <option>Divorced</option>
                <option>Widowed</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Birth Date</label>
              <input
                className="form-control"
                type="date"
                name="birthDate"
                value={data.birthDate || ''}
                onChange={handleChange}
                disabled={locked}
                min="1000-01-01"
                max="3000-01-01"
              />
            </div>
            <div className="form-group col">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={data.email || ''}
                onChange={handleChange}
                disabled={locked}
              />
            </div>
            <div className="form-group col">
              <label>Phone</label>
              <ReactInputMask
                className="form-control"
                type="text"
                name="phone"
                value={data.phone || ''}
                onChange={handleChange}
                mask="(999) 999-9999"
                disabled={locked}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label>Selected File</label>
              <input
                type="text"
                className="form-control"
                value={this.state.filePath}
                disabled
              />
              <input
                type="file"
                ref={this.fileInputRef}
                style={{ display: 'none' }}
                onChange={this.handleFileChange}
              />
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => this.fileInputRef.current.click()}
              >
                <FontAwesomeIcon icon={faFileUpload} /> Select File
              </button>
            </div>
          </div>
          <div className="form-row">
          <div className="form-group col">
            <label>Study Date Time</label>
            <input
              className="form-control"
              type="datetime-local"
              name="studyDateTime"
              value={this.state.data.studyDateTime || ''}
              onChange={this.changeHandler}
              disabled={locked}
              min="1000-01-01T00:00"
              max="3000-01-01T00:00"
            />
          </div>
            <div className="form-group col">
              <label>Comments</label>
              <input
                className="form-control"
                type="text"
                name="comments"
                value={this.state.comments}
                onChange={this.handleCommentsChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={this.uploadReport}
              >
                <FontAwesomeIcon icon={faFileUpload} /> Upload Report
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    var actions = {
      unlock: this.unlock.bind(this),
      read: this.read.bind(this),
      submit: this.submit.bind(this),
    };
    var data = this.state.data;
    var locked = this.state.locked;
    var removeAlert = this.removeAlert.bind(this);
    data = this.formatData(data);
    return (
      <div className="card">
        <form onSubmit={actions.submit}>
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <span className="h5">
                  {data.firstName} {data.middleName} {data.lastName}
                </span>
              </div>
              <div className="col text-right d-flex justify-content-end">

                <button className='btn btn-sm btn-outline-success mr-2'
                  type='button'
                  onClick={this.openModal}>
                  <FontAwesomeIcon icon={faArrowsToDot} />
                </button>
                <Modal
                  ref={this.modalRef}
                  isOpen={this.state.isModalOpen}
                  onRequestClose={this.closeModal}
                  contentLabel="Select Patient"
                  style={{
                    content: {
                      top: '50%',
                      left: '50%',
                      right: 'auto',
                      bottom: 'auto',
                      marginRight: '-50%',
                      transform: 'translate(-50%, -50%)',
                      width: '50%', // Adjust this as needed
                      height: 'auto',
                      overflow: 'auto'
                    },
                    overlay: {
                      zIndex: 1050,
                      position: 'fixed',
                      backgroundColor: 'rgba(0,0,0,0.75)'
                    }
                  }}
                >
                  <PatientsSelect
                    user={this.props.user}
                    mainPatientData={this.state.data}
                    organization={this.props.organization}
                    onSelect={this.handelPatientSelection}
                    closeModal={this.closeModal}
                  ></PatientsSelect>
                </Modal>

                <Edit actions={actions} data={data} locked={locked} />

              </div>
            </div>
          </div>
          {this.renderCardBody()}
        </form>

        <Alert
          message={this.state.alertMessage}
          type={this.state.alertType}
          onExit={removeAlert}
        />
      </div>
    );
  }
}

export default PatientDetails;
