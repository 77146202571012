
export default function ( HRM, pstyle ) {

    return  {
        xAxis: {
            //data API:
            //=dates ... this placeholder must exist ... will be filled with "dates", ...
            categories: [],
        },
        series: [{
            type: 'line',
            lineWidth: 4,
            marker:{
                radius: 6
            },
            name: 'Cycling Time (Airway Instability)',
            //data API:
            data: [], //dataPoints ... this placeholder must exist ... will be filled with "dataPoints"
            METAOPT_round_to_digits : 0,
            //data API:
            METAOPT_data_report_keys :
            [
                //data: 'spO2', //dataname
                'SPO2_INST_PCT',
            ]
        }],
    }
}




