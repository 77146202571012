
( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var fconf       = ns.fconf;
    var methods     = ns.methods        = ns.methods        || {};
    var fapp        = ns.sn('fapp' );
    var fheap       = ns.fheap;
    var fmethods    = ns.sn('methods',fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    methods.calls_tableAPI = calls_tableAPI;
    return;







    function calls_tableAPI({ pmcard, doDrawInlineCssAfter })
    {
        //=======================================================
        // //\\ sets this function-body internal shortcuts
        //=======================================================
        var tableId = pmcard.pageIx + '-' + pmcard.cardIx;
        var dtcard = pmcard.dtcard;
        var style8data_tpl = dtcard.style8data_tpl;
        //=======================================================
        // \\// sets this function-body internal shortcuts
        //=======================================================


        //=======================================================
        // //\\ collects garbage of former table 
        //=======================================================
        if( fconf.ENABLE_REP_EDITOR ) {
            var tableParent$ = pmcard.cardDom$$.tableParent$;
            ///vital: removes empty legends when data arrives:
            ///this logic is not very streamlined:
            if( tableParent$().children.length > 0 ) {
                //ccc( '--------- removing artifact children');
                //todo ... should be no children
                while( tableParent$().children.length ) {
                    tableParent$().children[0].remove();
                }
            }
        }
        //=======================================================
        // \\// collects garbage of former table 
        //=======================================================


        //=======================================================
        // //\\ data
        //      this section binds table to data
        //=======================================================
        var { fieldNames, rows } = fmethods.series_2_portraitTable( pmcard.cdataOptions.series );
        var headerDataRow = fieldNames;
        /*
        ccc( pmcard.pageIx + ' ' + pmcard.cardIx +
             ' series=', pmcard.cdataOptions.series,
             ' rows=', rows,
             ' headerDataRow = ', headerDataRow
        );
        */
        //=======================================================
        // \\// data
        //=======================================================

        //=======================================================
        // //\\ calls table-composer plugin API
        //=======================================================
        //************************************************************
        //the name "dtable" is weird, but it is too late to change it;
        //it originally meant "div-table" because this table
        //was "manually" built without "display:table-row", 
        //and "display:table-cell" styles;
        //************************************************************
        var dtable = pmcard.dtable =
        {
            context : pmcard,
            tableId,
            style8data_tpl,
            rows,
            headerDataRow,
            tableScale : (_=> fheap.editedPageScale),
            tableParent : ( tableParent$ && tableParent$() ) || document.body,
        };
        if( fconf.ENABLE_REP_EDITOR ) {
            fmethods.createGlobalCss({ dtable, });
        }
        fmethods.rebuildTable( dtable, null, doDrawInlineCssAfter );
        //=======================================================
        // \\// calls table-composer plugin API
        //=======================================================
    }

}) ();
