
//*******************************************************************************
//
// This is a default document template;
// It is a central source of layout for HTML, Canvas, and PDF.
//
// When admin creates fresh doc, this default is cloned into fresh doc.
//
//*******************************************************************************


import '../bsl/core/ns.js';

import datfrm from "./data-framework.gen.js"
import pageStyle_tpl from "./page-style.conf.js"

var ns          = window.b$l;
var sn          = ns.sn;
var fconf       = ns.fconf;
var currtpl     = ns.sn('currtpl', fconf);
var ccc         = console.log; ccc && ( ccc = console.log );

ns.fmethods.delete_dataId_fromDocTpl = delete_dataId_fromDocTpl;



//============================================================
// //\\ meta config. parameters ...
//============================================================
var HRM = 1; //High Resolution Mangifier
/*
    page sizes:
    see: report-composer/pdfbuilder/DOC/available-paper-formats.txt
    https://github.com/bpampuch/pdfmake/blob/7b5675d5b9d5d7b815bd721e00504b16560a6382/
          src/standardPageSizes.js
*/
var PAGE_WIDTH = 612.00;
var PAGE_HEIGHT = 792.00;
//============================================================
// \\// meta config. parameters ...
//============================================================




//*******************************************************************************
//
// Template
Object.assign( sn( 'default_empty_doc', fconf ), init_empty_doc() );
//
//*******************************************************************************





function init_empty_doc()
{
    var pstyle = pageStyle_tpl( HRM, PAGE_WIDTH, PAGE_HEIGHT );
    var dataFramework = datfrm.dataFramework_tpl( HRM, pstyle );


    ///*******************************************************************************
    ///
    /// Assembles default empty template
    ///
    /// all in this doc is built from code ... not from repo
    /// this is an important achitectural solution
    ///
    ///*******************************************************************************
    var defaultempty =
    {
        doccaption : "Empty Document",
        unsavedToDb : true,
        docbody :
        {
            repo : {
                dataGroups          : dataFramework.dataGroups,
                dataBinds           : dataFramework.dataBinds,
            },

            dbver : fconf.dbver,
            created_in_appver : ns.fapp.version,
            pstyle,
            contrPanelChosen_tplDatGrIx : dataFramework.contrPanelChosen_tplDatGrIx,
            pages :
            {
                contrPanelChosen_pageIx : 0,
                population :
                [
                    {
                        ////page 0
                        selectedCardIx : 0, //for dashboard editing mode
                        ///no cards in new document
                        cards : [],
                    },
                ],
            },
        },
    };
    return defaultempty;
}


function delete_dataId_fromDocTpl( dataId )
{
    currtpl.docbody.pages.population.forEach( ( pageTpl, pageIx ) => {
        var cards = pageTpl.cards;
        do {
            var headerCix = null;
            //pageTpl.cards.forEach( ( dtcard, cardIx ) => { //React babysitting prevents this
            for( var cardIx=0; cardIx<cards.length; cardIx++ ) {
                var dtcard = cards[ cardIx ];
                if( dtcard.dataId === dataId ) {
                    headerCix = cardIx;
                }
            };
            if( headerCix !== null ) {
                pageTpl.cards.splice( headerCix, 1 );
                pageTpl.selectedCardIx = 0;
            } else {
                break;
            }
        } while( true );
    });
}



