//apparently this module was a derivative from
//  /var/www/html/bwork/vbsl/vendor/bsl/slider/slider-handler.css.js
( function() {
    var ns          = window.b$l;
    var fconf       = ns.fconf;
    var ccc = console.log; ccc && ( ccc = console.log );



    var ret = `
        /* //|| horiz dividor */
        .horiz-dividor-handle {
            position    : absolute;
            cursor      : pointer;
            width       : 15px;
            height      : 60px;
            top         : 0;
            z-index     : 5000;
        }
        .horiz-dividor-handle .img {
            position:absolute;
            width:100%;
            height:100%;
        }
        .horiz-dividor-handle {
            opacity:0.2;
        }
        .horiz-dividor-handle:hover {
            opacity:1;
        }
        /* ||// horiz dividor */
    `;

    if( fconf.ROLE === 'CONSTRUCTOR' ) {

        ret += `

            /* drag8drop */
            .vert-drag-hand,
            .corner-handle-drag-hand {
                position:absolute;
                cursor:pointer;
                z-index : 5000;
            }
            /* vert and horiz position handle */
            .vert-drag-hand {
                width:48px;
                height:12px;
                left:80px;
                top:0%;
                transform:translate(-50%,0%);
            }
            .vert-drag-hand .img {
                position:absolute;
                width:100%;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }
            .vert-drag-hand {
                opacity:0.5;
            }
            .vert-drag-hand:hover {
                opacity:1;
            }

            .corner-handle-drag-hand {
                opacity:0.2;
            }
            .corner-handle-drag-hand:hover {
                opacity:1;
            }
            .corner-handle-drag-hand {
                height : 20px;
                width : 20px;
                bottom : 0;
                right : 0;
            }
            .corner-handle-drag-hand .img {
                position:absolute;
                width:100%;
                bottom : 0%;
                right : 0%;
            }

        `;
    }
    ns.globalCss.upqueue( ret );
})();


