import React, {Component} from 'react'
import $ from 'jquery'
import Alert from './alert'

class Interpretation extends Component {

	constructor(props){
		super(props)
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.getData = this.getData.bind(this)
		this.handleData = this.handleData.bind(this)
		this.state = {
			valid: true,
			interp: false,
			interpretation: '',
			alertMessage: ''
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		$.get('/reports/' + this.props.report.id).done(this.handleData)
	}

	handleChange(event){
		var data = this.state
		var name = event.target.name
		var value = event.target.value
		if (name === 'interp'){
			data.interp = event.target.checked
		} else if (name === 'valid'){
			data.valid = !event.target.checked
		} else {
			data[name] = value
		}
		this.setState(data)
	}

	handleSubmit(event){
		event.preventDefault()
		var data = {
			interpretation: this.state.interpretation,
			interp: this.state.interp,
			interpretingId: this.props.user.id,
			valid: this.state.valid	
		}
		$.ajax({
			method: 'PUT',
			url: '/reports/' + this.props.report.id,
			data: data
		}).done(this.handleSave.bind(this))
	}

	handleSave(data){
		this.setState({
			interpretation: data.interpretation || '',
			interp: data.interp,
			valid: data.valid,
			alertMessage: "Changes have been saved.",
			alertType: 'alert-success'
		})
		
	}

	handleData(data){
		this.setState({
			interpretation: data.interpretation || '',
			interp: data.interp,
			valid: data.valid
		})
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

	render(){
		var removeHandler = this.removeAlertHandler.bind(this)
		return (
			<div className='card'>
				<div className='card-header'>
					<span className='h4'>Review</span>
				</div>
				<div className='card-body'>
					<form onSubmit={this.handleSubmit}>
						<div className='form-group'>
							<textarea className='form-control' 
								name='interpretation'
								rows='7' 
								value={this.state.interpretation}
								onChange={this.handleChange}/>
						</div>
						<div className='form-group'>
							<div className='form-check'>
								<input className='form-check-input' 
									name='interp'
									type='checkbox'
									checked={this.state.interp}
									onChange={this.handleChange}/>
								<label className='form-check-label'>
									Review Complete
								</label>
							</div>
							<div className='form-check'>
								<input className='form-check-input' 
									name='valid'
									type='checkbox'
									checked={!this.state.valid}
									onChange={this.handleChange}/>
								<label className='form-check-label'>
									Invalid Study
								</label>
							</div>
						</div>
						<div className='btn-group'>
							<button className='btn btn-primary'>
								<span>Save</span>
							</button>
						</div>
						<div className='mt-2'>
							<Alert message={this.state.alertMessage} type={this.state.alertType } onExit={removeHandler}/>
						</div>
						
					</form>
				</div>
			</div>
		)
	}

}

export default Interpretation