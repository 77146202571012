import $ from 'jquery'

( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = ns.sn('methods', fapp);
    var fconf       = ns.fconf;
    var tplpopul    = ns.sn('tplpopulation', fconf);
    var currtpl     = ns.sn('currtpl', fconf);
    var fheap       = ns.sn('fheap', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );

    var emptyTpl_uniqueIdCount = 1; //for case data is corrupt ...

    fapp.ajax_tpl_download = ajax_tpl_download;
    fheap.tplDownloadState = 'non-started';
    fheap.reportDownloadState = 'idle';
    fheap.patientDownloadState = 'idle';
    return;









    //===============================
    // //\\ database doc tpl download
    //===============================
    //React problems
    //Stumbling upon reserved name?
    //function useDefaultTpl()
    function ajax_tpl_download( successCallback )
    {
        fheap.tplDownloadState = 'initiated';
        //---------------------
        // //\\ configures ajax
        //---------------------
        if( window.DEVELOPER_MODE__IS__ON ) {
            var url = fconf.dburl +
                  "loaddb.php" +
                  "?filename=" + fconf.dbver
            var ajaxargs =
            {
                method: 'GET',
                crossDomain: true,
                dataType: 'text',
                url: url,
                data: '',
            }

        } else {
            url = '/report-template';
            ajaxargs = {
                method : 'GET',
                url : url,
                dataType : 'text',
            };
        }
        //---------------------
        // \\// configures ajax
        //---------------------

        //--------------------------------------------------------------
        // sends
        // see: version/metatools/r8d/useful-code-stubs
        //      /ajax-dual-mock8server-db---from-ver-1143/applib-ajax.js
        //--------------------------------------------------------------
        $
            .ajax( ajaxargs )
            .done( respTxt => wait_and_run({
                respTxt,
                url,
                successCallback,
            }) )
            .fail( ajerror => wait_and_run({
                ajerror,
                url,
                successCallback,
            }))
            ;
    }
    //===============================
    // \\// database doc tpl download
    //===============================


    ///we need to wait while default doc pictures are loaded;
    ///this is why this function is created;
    function wait_and_run({ respTxt, ajerror, url, successCallback, })
    {
        var timestart = Date.now();
        while( ns.fheap.imagesLoadCount !== ns.fheap.imagesLoadMust ) {
            if( timestart + 10000 < Date.now() ) break; //infinite loop break 
        }
        if( typeof ajerror !== 'undefined' ) {
            ajax_error({ ajerror, url, successCallback });
        } else {
            ajax_success({ respTxt, url, successCallback });
        }
    }


    ///todm ... this should be written to enable overhauling templates
    ///from blank-state at each new ajax update;
    function ajax_success({ respTxt , url, successCallback })
    {
        //debugger
        //ccc( 'ajax_success from ' + url ); //+ ' respTxt=' + respTxt  )
        var respObj = JSON.parse( respTxt );

        //==============================================================
        // //\\ preassembles templates
        //      from ajax data
        //==============================================================
        if( ns.h( respObj, 'error' ) ) {
            ccc( 'db retrieval error: ', respObj );
            loadEmptyTpl({ newtemplate:true, });
            //ccc( 'tplpopul=', JSON.stringify(tplpopul, null, '    ') )
            spawnDocTemplate_2_models( successCallback );
            return;
        }

        if( window.DEVELOPER_MODE__IS__ON ) {
            Object.assign( tplpopul, respObj.data );
            Object.keys( tplpopul ).forEach( tplkey => {
                var tpl = tplpopul[tplkey];
                if( fconf.dbver !== tpl.docbody.dbver ) {
                    missedData( tpl, tplkey );
                }
            });

        } else {

            ///=============================================================
            ///cyclling via server response and populating
            ///individual docs by placing them into fconf.tplpopulation
            ///dictionary
            ///=============================================================
            respObj.forEach( tpl => {
                //ccc( 'each tpl=', tpl );
                //tmp fix
                var tplid = tpl.id+'';
                if( typeof tpl.data === 'string' ) {
                    tpl.data = JSON.parse( tpl.data );
                }
                if( !( tpl.data && tpl.data.pages && tpl.data.pages.population &&
                    fconf.dbver === tpl.data.dbver ) ) {
                    ccc( 'Missed tpl.data.pages or other fragments ... ' ) 
                    missedData( tpl, tplid );
                } else {
                    ////note: id from db is an integer,
                    ////      id in app was and is a string for safety,
                    tplpopul[ tpl.id+'' ] =
                    {
                        docbody         : tpl.data,
                        doccaption      : tpl.name,
                        organizationId  : tpl.organizationId,
                        createdAt       : tpl.createdAt,
                        updatedAt       : tpl.updatedAt,
                        docid           : tplid,
                    }
                }
            });
        }
        //==============================================================
        // \\// preassembles templates
        //==============================================================



        //==========================================
        // //\\ normalizes templates
        //==========================================
        if( Object.keys( tplpopul ).length === 0 ) {
            loadEmptyTpl({ newtemplate:true });
        }
        Object.keys( tplpopul ).forEach( tkey => {
            var tpl = tplpopul[ tkey ];
            ///*************************************
            ///sets missed docbody to empty template
            ///*************************************
            if( !tpl.docbody ||
                !Object.keys( tpl.docbody ).length
            ){
                loadEmptyTpl({ tplkey:tkey })
            }
        });
        ccc( 'ajax-delivered-templates = ', tplpopul );
        //==========================================
        // \\// normalizes templates
        //==========================================
        spawnDocTemplate_2_models( successCallback );
    }

    function ajax_error({ ajerror, successCallback, url })
    {
        ccc(  "error=", ajerror, "responseText=" + ajerror.responseText +
              ' url=' + url );
        loadEmptyTpl({ newtemplate:true, });
        currtpl.docbody.chosenInDash = true;
        currtpl.docid = "-1"; //fake id
        spawnDocTemplate_2_models( successCallback );
    }

    function loadEmptyTpl({ tplkey, newtemplate })
    {
        //creates tmp docid which must be replaced when saved into db
        var docid           = tplkey || 'defaultempty-' + (emptyTpl_uniqueIdCount++);

        var nempty          = ns.paste( {}, ns.fconf.default_empty_doc );
        nempty.newtemplate  = newtemplate;
        nempty.docid        = docid;
        tplpopul[ docid ]   = nempty;
        return nempty;
    }

    function missedData( tpl, tplid )
    {
        var doccaption = tpl.name || tpl.doccaption;
        var nempty     = loadEmptyTpl({ tplkey:tplid });

        //without "substring", this thing may exhaust
        //"string stack" in multiple calls
        nempty.doccaption       =
        (
            '"' + doccaption +
            '", db doc version =' + ( tpl.data && tpl.data.dbver ) +
            ', app doc version = ' + fconf.dbver + ', ' +
            'doc is preserved until resaved.'
        ).substring( 0, 200 );

        nempty.organizationId   = tpl.organizationId;
        nempty.createdAt        = tpl.createdAt;
        nempty.updatedAt        = tpl.updatedAt;
    }

    function spawnDocTemplate_2_models( successCallback )
    {
        //----------------------------------------------------------------------
        // //\\ sets chosen template to detected chosen or to first met template
        //      todm correct by setting to recent time
        //----------------------------------------------------------------------
        var firstChosenDashIdFound = null;
        var firstChosenAsDefaultIdFound = null;
        var firstTpl_docid = null;
        ns.eachprop( tplpopul, ( tpl, docid ) => {
            if( firstTpl_docid === null ) {
                firstTpl_docid = docid;
            }
            if( firstChosenDashIdFound === null ) {
                firstChosenDashIdFound = docid;
            }
            if( firstChosenAsDefaultIdFound === null || tpl.docbody.chosenAsDefault ) {
                firstChosenAsDefaultIdFound = tpl.docid;
            }
        });
        firstChosenAsDefaultIdFound = firstChosenAsDefaultIdFound === null ?
                firstTpl_docid :
                firstChosenAsDefaultIdFound;
        firstChosenDashIdFound = firstChosenDashIdFound === null ?
                firstTpl_docid :
                firstChosenDashIdFound;

        //cleans up
        ns.eachprop( tplpopul, tpl => tpl.docbody.chosenInDash = false );
        //sets unique chosen
        tplpopul[ firstChosenDashIdFound ].docbody.chosenInDash = true;     

        //ccc( 'default download id=' + firstChosenAsDefaultIdFound );
        //----------------------------------------------------------------------
        // \\// sets chosen template to detected chosen or to first met template
        //----------------------------------------------------------------------

        //for dumb-editor, sets default for non-admin-user download;
        //for featured-editor, sets id chosen for dashboard;
        var currtplId = fconf.ENABLE_REP_EDITOR ?
                            firstChosenDashIdFound :
                            firstChosenAsDefaultIdFound;

        Object.keys( currtpl ).forEach( ckey => delete currtpl[ ckey ] ); //cleanup
        Object.assign( currtpl, tplpopul[ currtplId ] );
        fmethods.setChosenAsDefault( true, tplpopul[ firstChosenAsDefaultIdFound ] );

        fmethods.doc__dataModel();
        fheap.tplDownloadState = 'completed';
        successCallback && successCallback();
    }

}) ();

