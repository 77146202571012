
var ns          = window.b$l;
var fconf       = ns.fconf;
var currtpl     = ns.sn('currtpl', fconf);
var fapp        = ns.fapp;
var aModel      = ns.sn('aModel', fapp);
var pgModels    = ns.sn('pgModels', aModel, [] );
var fmethods    = ns.sn('methods', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );
fmethods.removeCard = removeCard;
fmethods.removeCardsDom8models = removeCardsDom8models;
fmethods.pages__datMod_8_internals = pages__datMod_8_internals;
fmethods.pageCards__dataModel = pageCards__dataModel;
fmethods.pagesCards__dataModel = pagesCards__dataModel;




function removeCardsDom8models( pageIx )
{
    fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );

    // dom cleanup
    var pm = pgModels[ pageIx ];
    pm.pmcards.forEach( (pcard, cix) => {
        ////dom cleanup: removes ownself
        pcard.cardDom$$.pcard$().remove();
    });
    pm.dom$$.editorPage$().remove(); // = {};
    //delete pm.dom$$.editorPage$;
    delete pm.dom$$; //.editorPage$;

    // models cleanup
    pm.pmcards.length = 0;
}



function removeCard( pageIx, cardIx, dontResurrect )
{
    //------------------------------------------
    // //\\ cleanup
    //------------------------------------------
    removeCardsDom8models( pageIx );
    //master cleanup: template cleanup
    fmethods.delete_cardTpl_from_docTpl( pageIx, cardIx );
    //------------------------------------------
    // \\// cleanup
    //------------------------------------------

    if( dontResurrect ) return

    //------------------------------------------
    // //\\ cards resurrection from template
    //------------------------------------------
    pageCards__dataModel( pageIx );
    //c cc( cardIx + ' resulted cardModels', pgModels[ pageIx ].pmcards.length );
    fmethods.page__internals( pgModels[ pageIx ] );
    //------------------------------------------
    // \\// cards resurrection from template
    //------------------------------------------

    fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
}

function pageCards__dataModel( pageIx )
{
    var pm = pgModels[ pageIx ]
    var dtpage = currtpl.docbody.pages.population[ pageIx ];
    dtpage.cards.forEach( (dtcard,cardIx) => {
        fmethods.card__dataModel({ pm, dtcard, cardIx, });
    });
}

function pagesCards__dataModel()
{
    currtpl.docbody.pages.population.forEach( ( dtpage, pageIx ) => {
        pageCards__dataModel( pageIx );
    });
}


///compare with "doc__dataModel" which recreates placeholders for dom
function pages__datMod_8_internals()
{
    pagesCards__dataModel();
    //c cc( cardIx + ' resulted cardModels', pgModels[ pageIx ].pmcards.length );
    fmethods.pages__internals();
}




