import React, { Component } from 'react'
import Reports from './reports'
import MultiReports from './multi-night-reports'
import PatientDetails from './patient-details'


class Patient extends Component {

	constructor(props){
		super(props)
		this.path = '/patients'
		this.url = props.location.match.url
		this.state = {
			patient: {
				id: parseInt(props.location.match.params.id)
			}
		}
	}

	componentDidUpdate(props,state){		
		var dataIdChanged = state.patient.id !== this.state.patient.id
		if (dataIdChanged){
			this.url = this.path + '/' + this.state.patient.id
			this.props.location.history.push(this.url)
		}
	}
	
 	render(){
 		return (
			<div id='patient'>
				<div className='row mb-4'>
					<div className='col'>
						<PatientDetails 
						user={this.props.user}
						organization={this.props.organization}
						data={this.state.patient}
                        />
					</div>
				</div>
				<div className='row mb-4'>
					<div className='col'>
						<Reports organization={this.props.organization}
							patient={this.state.patient}
                            includeInMultiNight_dataRetriever={this.props.includeInMultiNight_dataRetriever}
                        />
					</div>
				</div>
				<div className='row mb-4'>
					<div className='col'>
						<MultiReports organization={this.props.organization}
							patient={this.state.patient} />
					</div>
				</div>
			</div>
		)
 	}

}

export default Patient
