
export default function ( HRM, pstyle ) {

    var ret = {

            //:plays img.nativeHeight ... todo ... pull them after load
            width                   : pstyle.PAGE_WIDTH * 0.3,
            height                  : pstyle.PAGE_WIDTH * 0.15,

            left                    : pstyle.PAGE_WIDTH * 0,
            top                     : pstyle.PAGE_HEIGHT * 0,
            imsrc                   : 'img/PSI-Icon_WebOptimized-500x208.png',
            //imsrc                   : 'img/medium-image-DSC00287.png',
            //imsrc                   : 'img/medium-3.png',
    };
    return ret;
}




