//Pulse (% of time in Ranges)                                                       


export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: '>=90',
                data: [],
                METAOPT_summate_arithmetically : true,
                METAOPT_double_point : true,
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_GTE90LT100PCT',
                    'PR_GTE100PCT',
                ]
            },

            {
                type: 'line',
                name: '80-89',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_GTE80LT90PCT',
                ]
            },

            {
                type: 'line',
                name: '70-79',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_GTE70LT80PCT',
                ]
            },

            {
                type: 'line',
                name: '60-69',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_GTE60LT70PCT',
                ]
            },

            {
                type: 'line',
                name: '50-59',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_GTE50LT60PCT',
                ]
            },

            {
                type: 'line',
                name: '<50',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'PR_LT50PCT',
                ]
            },

        ],
    }
}


