( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = ns.sn('methods', fapp);
    var aModel      = ns.sn('aModel', fapp);
    var pgModels    = ns.sn('pgModels', aModel, [] );
    var ccc         = console.log; ccc && ( ccc = console.log );

    ns.fheap.testImg_dataURL =
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA" +
                "AAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO" +
                "9TXL0Y4OHwAAAABJRU5ErkJggg==";

    fmethods.resizesPlugin_bounced      = ns.throttle( resizesPlugin, 200 );
    fmethods.formatFloat                = formatFloat;
    fmethods.createNewId                = createNewId;
    return;










    ///the main aim of the fuss inside this method is to facilitate
    ///tooltips in Highcharts
    function resizesPlugin()
    {
        fmethods.mdcss__dashb();
        pgModels.forEach( pm => {
            fmethods.dashModel_of_pagePosModel( pm );
            fmethods.inlcss__pageWrappers( pm );
            pm.pmcards.forEach( pmcard => {
                fmethods.inlcss__doResizeCardFrames({ pmcard });
                if( ns.haz( pmcard, 'dtable' ) ) {
                    ////todo: in some code this is not checked:
                    ////data may not yet arrived and dtable is not yet ready ...
                    pmcard.dtable.dat8tpl_2_model8inlcss();
                }
            });
        })
        pgModels.forEach( pm => {
            pm.pmcards.forEach( pmcard => {
                if( pmcard.dataGr.isChart ) {
                    fmethods.cnt__chart({ pmcard });
                } else if( pmcard.dataGr.isPicture ) {
                    fmethods.cnt__picture({ pmcard });
                }
            });
        })
        fmethods.setChosenClassOnPageIcon(); //todm ... why? does it depends on resize?
        //ccc( Date.now() + ' resize completed' );
    }

    ///detects and formats float values if digitsAfterPoint is defined
    function formatFloat({ digitsAfterPoint, value })
    {
        if( typeof value === 'number' && digitsAfterPoint &&
            value%1 //is not an integer
        ) {
            return value.toFixed( digitsAfterPoint );
        } else {
            return value;
        }
    }



    //---------------------------------------------
    // //\\ test
    //---------------------------------------------
    /*
    var list = {
        moo : {},
        too : 22,
    };
    var newId = createNewId({ list, new_value:'xx', });
    ccc( JSON.stringify(list) );
    var newId = createNewId({ list, new_value:'yy', });
    ccc( JSON.stringify(list) );
    */
    //---------------------------------------------
    // \\// test
    //---------------------------------------------
    function createNewId({ list, ID_PRFEFIX, new_value })
    {
        ID_PRFEFIX = ID_PRFEFIX || "picture-";
        ///Because of React faulty baby sitting we make this an object
        var detector = {
            new_index : 0,
        };
        do {
            detector.fails = false;
            detector.new_index++;
            detector.new_id = ID_PRFEFIX + detector.new_index;
            ns.eachprop( list, (prop,propname) => {
                if( detector.new_id.toLowerCase() === propname.toLowerCase() ) {
                    detector.fails = true;
                }
            });
        } while( detector.fails )

        if( new_value ) {
            list[ detector.new_id ] = new_value;
        }
        return detector.new_id;
    }

}) ();

