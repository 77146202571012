import React, { Component } from 'react'

class Alert extends Component {

 	render(){
 		var message = this.props.message
 		var type = this.props.type || 'alert-success'
 		if (message){
 			return (
				<div className={"alert " + type} role="alert" >
					<button type="button" className="close" aria-label="Close">
		    			<span onClick={this.props.onExit}>&times;</span>
		  			</button>
	  				{ message }
				</div>
			)
 		} else {
 			return <div></div>
 		}
 		
 	}

}

export default Alert