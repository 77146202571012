import React, {Component} from 'react'
import $ from 'jquery'


class PlanItem extends Component {

	constructor(props){
		super(props)
		this.state = {
			quantity: 1,
			total: 0
		}
	}

	handleChange(event){
		var data = {}
		data[event.target.name] = event.target.value
		this.setState(data)
	}

	handleSubscribe(event){
		var orgId = this.props.organization.id
		var url = '/organizations/' + orgId + '/subscriptions'
		var success = this.handleSubscribeSuccess.bind(this)
		var data = {
			plan: this.props.data.id,
			quantity: this.state.quantity
		}
		$.post({
			url: url,
			data: data
		}).done(success).fail(this.handleSubscribeError)
	}

	handleSubscribeSuccess(){
		this.props.getSubscriptions()
	}

	handleSubscribeError(res){
		var data = res.responseJSON
		alert(data.error.message)
	}

	render(){
		var data = this.props.data
		var id = data.id
		var name = data.name || id
		var amount = data.amount / 100
		var total = this.state.quantity * amount
		var changeHandler = this.handleChange.bind(this)
		var subscribeHandler = this.handleSubscribe.bind(this)
		return (
			<li className='list-group-item' id={id}>
				<div className='row'>
					<div className='col-4'>
						<span>{name}</span>
					</div>
					
					<div className='col-2 input-group'>
						<div className='input-group-prepend'>
							<span className='input-group-text'>x</span>
						</div>
						<input className='form-control'
							name='quantity'
							type='number'
							defaultValue='1'
							onChange={changeHandler}
							/>
					</div>
					<div className='col-2 input-group'>
						<div className='input-group-prepend'>
							<span className='input-group-text'>$</span>
						</div>
						<input className='form-control'
							type='number'
							value={total}
							disabled
							/>
					</div>
					<div className='col'>
						<button className='btn btn-outline-primary'
							onClick={subscribeHandler}
							>Subscribe</button>
					</div>
				</div>
			</li>
		)
	}
	
}

export default PlanItem
