
export default function ( HRM, pstyle ) {

    var ret = {
            orientationLandscape    : true,
            width                   : pstyle.PAGE_WIDTH * 0.9,
            left                    : pstyle.PAGE_WIDTH * 0,
            top                     : pstyle.PAGE_HEIGHT * 0,
            paddings                : [0,0.5,0,0.5], //=line height by setting background
    };
    return ret;
}




