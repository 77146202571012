
var ns          = window.b$l;
var sn          = ns.sn;
var fapp        = sn('fapp');
var fconf       = sn('fconf', fapp);
var ccc = console.log; ccc && ( ccc = console.log );

fapp.appver_dash_css__CP = appver_dash_css__CP;






function appver_dash_css__CP()
{
    var appCP = fconf.CONTROL_PANELS;

    return `


    /*****************************************/
    /* //|| top control panel                */
    /*****************************************/
    .app-control-panel {

        position        : absolute;
        width           : 100%;
        min-width       : 310px;
        height          : ${appCP.APP_CP_HEIGHT}px;
        top             : 0px;
        left            : 0px;
        padding-top     : 10px;
        margin-bottom   : 0;
        z-index         : 1000;
    }
    .plugin-root.changes-not-saved .app-control-panel {
        background-color : ${fconf.CHANGES_UNSAVED_TO_DB_COLOR};
    }
    .app-control-panel .caption
    {
        float           : left;
        padding-left    : 10px;
        font-family     : "Helvetica Neue",Arial, sans-serif;
        font-size       : 20px;
        font-weight     : bold;
        line-height     : 1.2;
        color           : ${fconf.DASHBOARD.COLORS.FONT_DARK};
    }

    @media only screen and (max-width:1200px) {
        .app-control-panel .caption
        {
            font-size       : 13px;
        }
        .app-control-panel {
            height          : ${20 + appCP.APP_CP_HEIGHT}px;
        }
    }

    .app-control-panel .sub-caption {
        float           : right;
        padding-right   : 10px;
    }
    /*****************************************/
    /* ||// top control panel                */
    /*****************************************/




    /******************************************/
    /* //|| side bars                         */
    /* //|| right side bar                    */
    /*----------------------------------------*/
    .dashboard-right-side-bar .right-side-cp2,
    .dashboard-right-side-bar .right-side-cp {
        position : absolute;
        width : 100%;
        top : 0;
        z-index : 100;
    }
    .dashboard-right-side-bar .right-side-cp2 {
        top : 42px;
    }


    .right-side-cp-dropdown-toggler {
        width : 100%;
        height :  20px;
    }

    .right-side-cp-dropdown {
        position : absolute;
        width : 100%;
        background-color : ${fconf.DASHBOARD.COLORS.BACKGROUND};
    }

    .right-side-cp-dropdown {
        top : 20px;
    }
    .right-side-cp2 .right-side-cp-dropdown {
        top : 0px;
    }
    .dashboard-right-side-bar.dropdown-closed .right-side-cp-dropdown {
        display : none;
    }

    .right-side-cp-dropdown-toggler .cp-button {
        float : left;
        margin-left : 4px;
        width : 84%;
        top : 0;
        font-size : 10px;
    }
    .right-side-cp-dropdown .cp-button {
        float : none;
        margin-left : 4px;
        width : 84%;
    }

    /* Disables native highlighting of text and possibly other bonus functionality */
    /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
    /* this really solved firefox problem of "shadow-dragging-object"
       the problem tested and logged in 
       83-current-svg-firefox-problems.zip
       as of June 12, 2019 ( a year later we don't see this problem in FF )
       circle.movable { user-select: none; .... 
    */
    .dashboard-left-side-bar div,
    .dashboard-right-side-bar div {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }


    /*----------------------------------------*/
    /* //|| cards repo                        */
    /*----------------------------------------*/
    .dashboard-right-side-bar .cards-repo {
        position : absolute;
        width : 92%;
        left : 4%;
        top : ${fconf.CONTROL_PANELS.APP_CP_REPOCARDS}px;
        z-index : 10;
    }
    .repocard-superparent {
        position : absolute;
        width : 90%;  /*vital ? for letting scroll bar working */
        overflow : visible;
    }
    .repocard-parent {
        position : absolute;
        opacity : 0.4;
    }
    .repocard-parent.chosen {
        border : 10px solid ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
        opacity : 1;
    }
    /*----------------------------------------*/
    /* ||// cards repo                        */
    /* ||// right side bar                    */
    /*----------------------------------------*/

    /*----------------------------------------*/
    /* //|| left side bar                     */
    /*----------------------------------------*/
    .dashboard-left-side-bar .left-side-cp {
        position : absolute;
        width : 100%;
        left : 0;
        top : 0;
        z-index : 100;
    }
    .left-side-cp-dropdown-toggler {
        width : 100%;
        height :  20px;
    }

    .left-side-cp-dropdown {
        position : absolute;
        width : 100%;
        top : 20px;
        background-color : ${fconf.DASHBOARD.COLORS.BACKGROUND};
    }
    .dashboard-left-side-bar.dropdown-closed .left-side-cp-dropdown {
        display : none;
    }

    .left-side-cp-dropdown-toggler .cp-button {
        width : 80%;
        top : 0;
        font-size : 10px;
    }
    .left-side-cp-dropdown .cp-button {
        width : 80%;
        font-size : 10px;
    }



    /*----------------------------------------*/
    /* //|| pages repo                        */
    /*----------------------------------------*/
    .dashboard-left-side-bar .pages-repo {
        position : absolute;
        width : 92%;
        left : 4%;
        top : 100px;
        z-index : 10;
    }
    .page-repo-superparent {
        position : relative;
        width : 100%;
        height : 130px;
        margin-top : 10px;
        background-color : #aaaaaa;
        overflow : visible;
    }
    .page-repo-parent {
        position : relative;
        left : 4%;
        top : 4%;
        width : 92%;
        height : 92%;
        background-color : #ffffff;
        opacity : 1;
        cursor : pointer;
    }
    .page-repo-parent.chosen {
        border : 4px solid ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
        opacity : 1;
    }
    .page-repo-parent.add-new {
        background-color : #cccccc;
    }
    
    /* temporary */
    .page-repo-parent div,

    .page-repo-parent.add-new div {
        position : absolute;
        left     : 50%;
        top     : 50%;
        transform : translate(-50%,-50%);
        white-space : nowrap;
        font-size : 13px;
    }

    /*----------------------------------------*/
    /* ||// pages repo                        */
    /* ||// left side bar                     */
    /* ||// side bars                         */
    /******************************************/




    /*----------------------------------------*/
    /* //|| cp buttons                        */
    /*----------------------------------------*/
    .cp-button {
        float : right;
        display : inline;
        padding : 1px;
        padding-left : 5px;
        padding-right : 5px;
        margin : 0;
        margin-right : 8px;
        margin-bottom : 3px;
        border-radius : 6px;
        border : none;
        box-sizing : border-box;
        text-align : center;
        font-size : 13px;
        font-family : helvetica, san-serif;
        color : white;
        background-color : ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
        cursor : pointer;
        opacity : 0.6;
    }
    .cp-button:hover {
        opacity : 1;
    }
    .page-card .delete-card {
        position : absolute;
        top : -20px;
        left : 25px;
        font-size : 9px;
        z-index : 20000;
    }

    .doc-level-controls.cp-button,
    .doc-level-controls .cp-button {
        background-color : ${fconf.DASHBOARD.COLORS.DOC_SELECTION_CONTROLS};
    }
    /*----------------------------------------*/
    /* ||// cp buttons                        */
    /*----------------------------------------*/




    /*----------------------------------------*/
    /* //|| cp buttons & documents' selection */
    /*----------------------------------------*/
    select.cp-button option {
        background-color : ${fconf.DASHBOARD.COLORS.DOC_SELECTION_CONTROLS};
    }
    select.cp-button.doc-unsaved-to-db,
    select.cp-button option.doc-unsaved-to-db {
        background-color : ${fconf.CHANGES_UNSAVED_TO_DB_COLOR};
    }
    /*----------------------------------------*/
    /* ||// cp buttons & documents' selection */
    /*----------------------------------------*/





    `;
}

