( function() {
    var ns          = window.b$l;
    var $$          = ns.$$;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = ns.sn('methods', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    var tasksInProgress = ns.sn( 'tasksInProgress', ns.fheap, [] );

    var justFinishedTheJob = false;
    var newImageId;
    fmethods.managesUploadOverlay = managesUploadOverlay;
    return;








    ///enables overlay over dashboard element editee to edit this editee
    ///inserts input-cluster near editee and avoids collision with editee;
    function managesUploadOverlay({
        modelObject,            //property container
        model_prop0ix,          //property keyname
        dom$,                   //dom element where to set property: precedence over cccpath
        cccpath,                //not used if cell$ is supplied
        taskCompletionCallback,
        taskDeletionCallback,
        verticalPosition,
    }) {

        newImageId = model_prop0ix;

        //.in case user did not close other edit boxes
        fmethods.finalizes_tasksInProgress();
        if( justFinishedTheJob ) {
            //ccc( 'parent.querySelector already exists', parent.querySelector( 'input' ) );
            justFinishedTheJob = false;
            return;
        }
        var domEl$ = dom$ || $$.q( cccpath );
        var parent = domEl$().parentNode;

        var input_replace_button$;
        var input_replace$;
        var input_add$;

        verticalPosition = verticalPosition || {
            loc : 'top',
            offset : '-55px',
        };

        var inputwrap = $$
            .c( 'div' )
            ///gives birth to absolute cluster
            .css( 'position', 'relative' )
            .css( verticalPosition.loc, verticalPosition.offset )
            .ch( $$.c( 'div' )
                ///creates absolute holder to hold fluid "input" and "button" elements
                .css( 'position', 'absolute' )
                    .css( 'left', '0px' )
                    .css( 'opacity', '0.9' )
                    .css( 'z-index', '40000' )
                    .ch( input_replace_button$ = $$.c( 'button' ).html( 'c' )
                            .a( 'title', "replace in document's media library" )
                           .e( 'click', () =>  { input_replace$().click(); } )
                    )
                    .ch( input_replace$ =
                        $$
                            .c( 'input' )
                            .a( 'type', 'file' )
                            .a( 'value', "replace" )
                            .e( 'change', handler_of_replacement )
                            .css( 'display', 'none' )
                    )
                    .ch( $$.c( 'button' ).html( '+' )
                           .e( 'click', () =>  { input_add$().click(); } )
                           .a( 'title', "add to document's media library" )
                    )
                    .ch( input_add$ =
                        $$
                            .c( 'input' )
                            .a( 'type', 'file' )
                            .a( 'value', "add" )
                            .e( 'change', handler_of_adding )
                            .css( 'display', 'none' )
                    )
                    .ch( $$
                            .c( 'button' )
                            .a( 'type', 'button' )
                            .html( "-" )
                            .a( 'title', "delete from document's media library" )
                            .e( 'click', handler_of_deleting )
                    )
            )
            ();
        //:inserts cluster near editee and avoids collision with target editee el.
        parent.insertBefore( inputwrap, parent.firstChild );
        input_replace_button$().focus();

        tasksInProgress.push( finishTheJob );
        return;


        function finishTheJob()
        {
            taskCompletionCallback( newImageId );
            inputwrap.remove();
            justFinishedTheJob = true;
        }

        function handler_of_adding( event )
        {
            handler_of_upload( event, 'add' );
        }

        function handler_of_replacement( event )
        {
            handler_of_upload( event, 'replace' );
        }

        function handler_of_deleting()
        {
            inputwrap.remove();
            taskDeletionCallback();
        }

        function handler_of_upload( event, add0replace )
        {
            //c cc( 'files = ', event, event.srcElement.files );
            var files = event.srcElement.files;
            if( !files || !files[0] )
            {
                console.log( files );
                throw new Error( "Cannot open file ... " );
            }
            //var selectedFile = document.getElementById('input').files[0];
            var selectedFile = files[0];
            var img = document.createElement("img");
            img.onload = function() {

                if( add0replace === 'add' ) {
                    ////will update list automatically
                    newImageId = fmethods.createNewId({ list:modelObject, new_value:img.src, });
                    //ccc( newImageId, modelObject );
                } else {
                    ////does update list explicitly
                    modelObject[ model_prop0ix ] = img.src;
                }
                //c cc( 'here is an image', img, selectedFile.name );
                //.does update dom explicitly
                dom$().src = img.src;
                fmethods.finalizes_tasksInProgress();
            }
            var reader = new FileReader();
            reader.onload = (function(aImg)
            { 
                return function(e) { aImg.src = e.target.result; };
            })(img);
            /*
            //reader.onabort = function()
            ///this is for R&D: this never worked:
            reader.addEventListener('abort', function()
            { 
                ccc( 'on abort' );
                inputwrap.remove();
            });
            */
            reader.readAsDataURL( selectedFile );
            inputwrap.remove();
        }
    }

}) ();

