import corner_img from '../../bsl/core/d8d/img/corner.png'
import horizontal_img from '../../bsl/core/d8d/img/horizontal.png'
import flip_img from '../../bsl/core/d8d/img/flip-orientation.png'

var ns          = window.b$l;
var $$          = ns.$$;
var fconf       = ns.fconf;
var fapp        = ns.fapp;
var aModel      = ns.sn('aModel', fapp);
var fmethods    = ns.sn('methods', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );
fmethods.dom__cardFrames= dom__cardFrames;
fmethods.deleteCard = deleteCard;
fmethods.replacedDataURL_to_all_pages_card = replacedDataURL_to_all_pages_card;









function dom__cardFrames({ pgModel, cardModel })
{
    var pmcard      = cardModel;
    var dataId      = pmcard.dataGr.dataId;
    var dsg_id      = pmcard.dtcard.dsg_id;
    var cix         = cardModel.cardIx;
    var editorPage$ = pgModel.dom$$.editorPage$;

    var cardOverlayId = "page-card-"+cix;
    //card's dom descendants
    var d$$ = cardModel.cardDom$$ = {};

    //card dom itself:
    var pageCard$ = d$$.pcard$ = $$
        .div()
        .addClass( dataId + ' dsg-id--' + dsg_id + " page-card " + cardOverlayId )
        .to( editorPage$ )
        ;
    if( pgModel.pageTemplate.selectedCardIx === cix ) {
        pageCard$.addClass( 'selected-card' );
    }
    if( pmcard.dataGr.isChart || pmcard.dataGr.isPicture ) {
        pageCard$

            //the purpose of this super-level of divs is to attach d8d handle
            //avoiding conflict with handle overlays and scaling chart parent
            .ch( d$$.hChartSuperParent$ = $$.div()
                .addClass( "chart-super-parent cardid-"+cix )

                .ch( d$$.hChartParent$ = $$.div()
                    .addClass( "chart-parent cardid-"+cix )
                )
            )
            ;

        //-----------------------------
        // //\\ drag8drop
        //-----------------------------
        d$$.horizDrgHand$ = $$
            .div()
            .addClass( 'corner-handle-drag-hand' )
            .ch( d$$.horizDrgHandImg$ = $$
                .img()
                .cls('img')
                .a( 'src', corner_img )
            )
            .to( d$$.hChartSuperParent$ )
            ;
        //-----------------------------
        // \\// drag8drop
        //-----------------------------
    }
    //c cc( pageIx + ' ' + cix + ' card dom-tree (re)created', d$$.pcard$() );

    if( pmcard.dataGr.isTable ) {
        pageCard$
            .ch( d$$.tableParent$ = $$.div()
                .addClass( "legend-parent cardid-"+cix )
            )
            ;
        //----------------------------------
        // //\\ table controls toggler
        //----------------------------------
        $$
            .dct( 'table-handles-toggler', pageCard$ )
            .e( 'click', event => {
                event.stopPropagation(); //skips cell's own events
                fmethods.finalizes_tasksInProgress();
                var showFlag = 'table-handles-shown';
                pageCard$.togcls( showFlag );
                if( pageCard$.hascls( showFlag ) ) {
                    var dtable = pmcard.dtable;
                    fmethods.setsup_styleEditor({
                        controlsCssPath : '.content-decoration-controls',
                        styleTpl : dtable.style8data_tpl.editableCss,
                        htmlEl$ : dtable.dtable$,
                        styles2html : function() {
                            pmcard.dtable.dat8tpl_2_model8inlcss();
                            dtable.synchArchivedFont(); //todm: do proofcheck
                        },
                        updateHeadersIfAny : function() {
                            if( pmcard.dtcard.dsg_id === 'pages-header' ) {
                                fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                            }
                        }
                    });
                //} else {
                //    fmethods.doDisableDecorationControls();
                }
            })
            .ch( $$.c( 'i' )
                .addClass( "fa fa-cog" )
                .a( 'title', 'show or hide table controls' )
            );
        //----------------------------------
        // \\// table controls toggler
        //----------------------------------

        ///============================================
        /// flips orientation
        ///============================================
        var style8data_tpl = pmcard.dtcard.style8data_tpl;
        $$
            .dct( 'table-handle flip-orientation', pageCard$ )
            .e( 'click', ( event ) => {
                ccc( 'click on flip orientation ... start proceeding ' );
                //this event apparently does not initiates a drag8drop ...
                event.stopPropagation(); //skips irrelevant events
                fmethods.finalizes_tasksInProgress();
                style8data_tpl.orientationLandscape = !style8data_tpl.orientationLandscape;
                if( dsg_id === 'pages-header' ) {
                    fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard });
                } else {
                    fmethods.rebuildTable( pmcard.dtable, null, 'do inline css' );
                }
            })
            .ch( $$
                    .img()
                    .cls( 'img' )
                    .a( 'title', 'flip orientation' )
                    //.a( 'src', imagesList[ 'flip-orientation' ] )
                    .a( 'src', flip_img )
            );
    }


    //-----------------------------
    // //\\ drag8drop
    //-----------------------------
    d$$.vertDrgHand$ = $$
        .div()
        .addClass( 'vert-drag-hand' )
        .ch( d$$.vertDrgHandImg$ = $$
            .img()
            .cls('img')
            .a( 'src', horizontal_img )
        )
        .to( pageCard$ )
        ;
    //-----------------------------
    // \\// drag8drop
    //-----------------------------

    if( fconf.ROLE === 'CONSTRUCTOR' ) {
        $$
            .div()
            .addClass( "cp-button delete-card" )
            .a( "title", "Delete card" )
            .e( 'click', () => { fmethods.deleteCard( pmcard ) })
            .html( 'Delete' )
            .to( pageCard$ )
            ;
    }
}



function deleteCard( pmcard )
{
    fmethods.finalizes_tasksInProgress();
    var dtcard = pmcard.dtcard;
    var dsg_id = dtcard.dsg_id;
    var dataId = dtcard.dataId;
    if( dsg_id === 'pages-header' ) {
        fmethods.removeHeadersFromPage({ dataId, dsg_id });
    } else {
        fmethods.removeCard( pmcard.pageIx, pmcard.cardIx );
    }
}


function replacedDataURL_to_all_pages_card({
    pmcard,
}) {
    var dtcard = pmcard.dtcard;
    var search_dataId = dtcard.dataId;
    var dataURLs = aModel.dataURLs[ dtcard.tpl.imsrc ];
    aModel.pgModels.forEach( ( pageModel, pageIx ) => {
        pageModel.pmcards.forEach( (pmc, cix) => {
            if( search_dataId === pmc.dtcard.dataId && dtcard.isPicture ) {
                pmc.cardDom$$.img$.src( dataURLs ); 
            }
        });
    });
}


