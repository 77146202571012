
export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [], //dates
        },
        series:
        [
            {
                type: 'line',
                name: 'Study Dates',
                data: [],
                METAOPT_time_format : 'MM/DD/YYYY',
                METAOPT_data_report_keys :
                [
                    'STUDY_START',
                ]
            },
            {
                type: 'columnrange',
                name: 'TRT (Duration)',
                data: [], // data
                METAOPT_time_format : 'HH:MM:SS',
                METAOPT_data_report_keys :
                [
                    'TRT',
                ],
            },
            {
                type: 'line',
                name: 'VRT (%)',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'VRT_PCT',
                ]
            },
        ],
    }
}


