
var ns          = window.b$l;
var $$          = ns.$$;
var sn          = ns.sn;
var fapp        = ns.fapp;
var aModel      = sn('aModel', fapp);
var pgModels    = sn('pgModels', aModel, [] );
var fmethods    = ns.sn('methods', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );
fmethods.dom__pageFrame8cardFrames = dom__pageFrame8cardFrames;



///builds "outer-part" of dom for page and cards
function dom__pageFrame8cardFrames({ pgModel })
{
    var pageIx = pgModel.pageIx
    var dom$$ = pgModel.dom$$ = {};
    dom$$.editorPage$ = $$
        .div()
        .addClass( 'editor-page pageix-' + pgModel.pageIx )
        .to( aModel.pagesParent$ )
        ;
    pgModels[ pageIx ].pmcards.forEach( (pcard, cix) => {
        fmethods.dom__cardFrames({ pgModel, cardModel:pcard });
    });
}


