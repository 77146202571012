
//==================================================
//plugin support library
//==================================================
import '../../bsl/core/ajax/ajax.js';


//==================================================
//configures plugin
//==================================================
import '../css-sheets/control-panels.css.js';


//==================================================
//imports react
//==================================================
import React from "react";
import './style-decorations-editor.js';
import FontDecorations from './StyleDecorationsEditor.js';
var ccc = console.log; ccc && ( ccc = console.log );


//==================================================
//sets namespaces for module
//==================================================

var ns          = window.b$l;
var fconf       = ns.fconf;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods',fapp);
var fheap       = ns.fheap;
var currtpl     = ns.sn('currtpl', fconf);
var tplpopul    = ns.sn('tplpopulation', fconf);
var aModel      = ns.sn('aModel', fapp);









class TopBarControlPanel extends React.Component {

    constructor( props ) {
        super( props );
        fmethods.forceUpdateTopBarCP = this.forceUpdate.bind(this);
        this.state = {
            chosenAsDefault : !!currtpl.docbody.chosenAsDefault,
        };
    }

    componentDidUpdate() {
        if( this.state.chosenAsDefault !== !!currtpl.docbody.chosenAsDefault ) {
            this.setState({ chosenAsDefault:!!currtpl.docbody.chosenAsDefault });
        }
    }

    render() {
        var PNAME = 'doccaption'; //prop name to update

        ///apparently, "<div className="caption editable""
        ///is document name, and "onClick" is its editor engine
        return (
            <div className="app-control-panel">
                <div>
                    <div className="caption editable"
                         title={
                            'Database record id="' + currtpl.docid +
                            '" Organization Id="' + currtpl.organizationId +
                            '" Created At="' + currtpl.createdAt +
                            '" Updated At="' + currtpl.updatedAt +
                            '" Created in app version="' + fconf.created_in_appver +
                            '"'
                         }
                         onClick={ (arg) => {
                            fmethods.managesInputOverlay({
                                cccpath                 : '.app-control-panel .caption',
                                modelObject             : tplpopul[ currtpl.docid ],
                                model_prop0ix           : PNAME,
                                taskCompletionCallback  : () => {
                                    currtpl[ PNAME ] = tplpopul[ currtpl.docid ][ PNAME ];
                                    ns.haf( fapp, 'leftSideBarForceUpdate' )();
                                    //.this thing resets caption of option in select-element:

                                    var opt = aModel.docsSelector$().selectedOptions[0];
                                    opt.text = currtpl[ PNAME ];
                                    opt.title =
                                        'Doc Title= "' + currtpl[ PNAME ] +
                                        '" Database Record Id="' + currtpl.docid +
                                        '" Organization Id="' + currtpl.organizationId +
                                        '" Created At="' + currtpl.createdAt +
                                        '" Updated At="' + currtpl.updatedAt +
                                        '"';
                                    fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
                                },
                            })
                        }}>
                        { currtpl.doccaption }
                    </div>
                    &nbsp;<input className="chosen-default-doc"
                        type="checkbox"
                        title={'Check to make this document selected for non-admin user as default'}
                        checked={this.state.chosenAsDefault}
                        onChange={ (event) => {
                            fmethods.setChosenAsDefault( event.target.checked );
                            //ccc( 'cur change happened: curr became='+currtpl.docbody.chosenAsDefault );
                            this.setState({ chosenAsDefault:currtpl.docbody.chosenAsDefault });
                        }}
                    />
                </div>
                <FontDecorations
                    fontSize={fheap.currentFontSize}
                    subjectOfUpdate={_=>{}}
                />
            </div>
        );
    }
}

export default TopBarControlPanel;

