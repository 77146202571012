import React, { useCallback, useEffect, useMemo } from 'react';
import { Tooltip } from 'antd';
import Draggable from 'react-draggable';
import { ReactComponent as ReactLogo } from './cardiogram-svgrepo-com.svg';
import { globalData } from './globals';

let draggingChart = false;
let zoombandPosition = null;
let unusedChartsContainer = null;
const hideActiveContainers = () => {
  document.querySelectorAll('.active.chartName').forEach((c) => {
    c.classList.toggle('active', false);
  });
  document.querySelectorAll('.dragged.chartName').forEach((c) => {
    c.classList.toggle('dragged', false);
  });
};
const headerHeight = 175;

const ChartIcons = ({
  signalsData,
  getAutoScale,
  setChartSettingsModal,
  setZoomSignal,
  zoomSignalId,
  chartRanges,
  chartSizes,
  switchCharts,
  chartColors,
  unusedSignalsIds,
  selectedCharts,
  addCharts,
  allChartData,
  updateChartsOrder,
  addHistoryEvent,
  setChartRanges,
  signalPairs,
  selectedChartIds,
  removeSecondSignal,
  moveSourceChart,
  additionalLines,
  signalIds,
  moveSecondChart,
  setHistogramColor,
  addSecondCharts,
  loading,
}) => {
  useEffect(() => {
    zoombandPosition = document.getElementById('zoombandPosition');
    unusedChartsContainer = document.getElementById('unusedCharts');
  }, [loading]);

  const moveChart = useCallback(
    (e, index, childSignal, move, signalData) => {
      const signalId = signalData.SignalId;
      zoombandPosition.className = 'zoombandPosition';
      hideActiveContainers();
      if (!draggingChart) {
        setChartSettingsModal({
          SignalId: signalData.SignalId,
          Type: signalData.Type,
          name: signalData.name,
          oldRow: index,
          row: index,
          visible: true,
          used: true,
          color: chartColors[signalId],
          range: chartRanges[signalId],
        });
        return;
      }
      if (zoombandPosition.contains(e.target)) {
        setZoomSignal(signalId);
        return;
      }
      const container = e.target.closest('.chartName');
      if (container && container.getAttribute('pair') && parseInt(container.getAttribute('pair')) === signalId) {
        const signalIdSource = signalPairs[signalId];
        if (!additionalLines[signalIdSource]) {
          addSecondCharts(signalIdSource, signalId, false);
        }
        return;
      }

      const unusedElementContainer = document.getElementsByClassName('unusedCharts')[0];
      draggingChart = false;
      if (e.target.className === 'unusedCharts' || e.target.classList.contains('unusedIcon') || e.target.closest('.unusedCharts')) {
        const charts = selectedCharts.slice(0);
        if (childSignal) {
          const signalIdSource = signalPairs[signalId];
          additionalLines[signalIdSource] = null;
          removeSecondSignal(signalId);
        } else {
          if (additionalLines[signalId]) {
            const i = signalIds.findIndex((s) => s.SignalId === signalId);
            charts[index] = i;
            additionalLines[signalId] = null;
            updateChartsOrder(charts);
          } else {
            const charts = selectedCharts.filter((t, i) => i !== index);
            updateChartsOrder(charts);
          }
        }

        unusedElementContainer.className = 'unusedCharts';
        return;
      }
      if (e.target.className !== 'chartPositionInside') {
        return;
      }
      const newIndex = parseInt(e.target.getAttribute('index')) - (move > 0 ? 1 : 0);
      if (!childSignal && additionalLines[signalId]) {
        moveSourceChart(index, newIndex, signalId);
        return;
      }
      if (childSignal && additionalLines[signalPairs[signalId]]) {
        moveSecondChart(signalId, signalPairs[signalId], newIndex);
        return;
      }
      switchCharts(index, newIndex);
    },
    [
      selectedCharts,
      updateChartsOrder,
      switchCharts,
      setZoomSignal,
      moveSecondChart,
      addSecondCharts,
      additionalLines,
      setHistogramColor,
      moveSourceChart,
      chartColors,
      signalPairs,
    ],
  );

  const addUnusedChart = useCallback(
    (e, signalId, move, signalData) => {
      zoombandPosition.className = 'zoombandPosition';
      hideActiveContainers();
      if (!draggingChart) {
        setChartSettingsModal({
          SignalId: signalId,
          Type: signalData.Type,
          name: signalData.name,
          oldRow: null,
          row: 0,
          visible: false,
          used: false,
          color: chartColors[signalId],
          range: chartRanges[signalId],
        });
        return;
      }
      unusedChartsContainer.classList.toggle('active', false);
      if (e.target.className === 'zoombandPosition') {
        addCharts(signalId, selectedCharts.length);
        setZoomSignal(signalId);
        return;
      }

      const container = e.target.closest('.chartNameUsed');
      if (container && container.getAttribute('pair') && parseInt(container.getAttribute('pair')) === signalId) {
        const signalIdSource = signalPairs[signalId];
        if (!additionalLines[signalIdSource]) {
          addSecondCharts(signalIdSource, signalId, true);
        }
        return;
      }
      const topAxisSize = Math.floor((window.innerHeight - headerHeight) / 50);
      draggingChart = false;
      if (e.target.className !== 'chartPositionInside') {
        //e.target.className = 'chartPositionInside'
        return;
      }
      const newIndex = parseInt(e.target.getAttribute('index'));
      addCharts(signalId, newIndex);
      const elements = document.querySelectorAll('.chart .chartNames .chartName.handle');
      elements.forEach((el) => {
        el.setAttribute('style', 'flex-grow:' + topAxisSize);
      });
    },
    [addCharts, setZoomSignal, selectedCharts],
  );

  const iconRightClick = useCallback(
    (e, signalId) => {
      e.preventDefault();
      const scale = getAutoScale(signalId);
      const historyEventDataNext = { signalId: signalId };
      const historyEventDataPrev = { signalId: signalId };
      historyEventDataPrev.range = chartRanges[signalId];
      historyEventDataNext.range = scale;
      setChartRanges(signalId, scale);
      addHistoryEvent({
        type: 'chartSettings',
        prev: historyEventDataPrev,
        next: historyEventDataNext,
        title: 'chart settings change',
      });
    },
    [getAutoScale, addHistoryEvent, chartRanges, setChartRanges],
  );

  const rowSizeSum = useMemo(() => {
    return selectedChartIds
      .map((id) => chartSizes[id])
      .reduce((prev, curr) => {
        return prev + curr;
      }, 0);
  }, [selectedChartIds, chartSizes]);

  const n = selectedCharts.length;
  const topAxisSize = Math.floor((window.innerHeight - headerHeight) / 50);
  const zoomColor = chartColors[zoomSignalId];
  const addedCharts = Object.values(additionalLines).filter((l) => l);
  return (
    <>
      <div className="chartNames">
        <div key="chart_last2" className="chartName" style={{ flexGrow: 15 * n }}></div>
        <div key={'div_zoomband'} className="chartName" style={{ flexGrow: 8 * n, position: 'relative' }}>
          <div id="zoombandPosition" className="zoombandPosition">
            {n > 0 && (
              <div className="chartContainer">
                {/* <div className="title">{zoomSignal?.Type?.slice(0, 20)}</div> */}
                <span className="chartIcons">
                  <span className="visibleChart">
                    {globalData.signalIcon[zoomSignalId] || <ReactLogo fill={zoomColor} width={20} height={20} />}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div style={{ flexGrow: 4 * rowSizeSum }}></div>

        <div className="chartPosition" index={0}>
          <div className="chartPositionInside" index={0}></div>
        </div>
        {selectedCharts
          .map((i) => signalsData[i])
          .map((signalData, i) => {
            if (!signalData || !selectedChartIds.includes(signalData.SignalId)) return null;
            let color = chartColors[signalData.SignalId];
            let secondSignal =
              additionalLines[signalData.SignalId] && signalsData.find((s) => s.SignalId === additionalLines[signalData.SignalId]);
            return (
              <React.Fragment key={signalData.Type + i + 'frgament'}>
                <div
                  className="chartName chartNameUsed"
                  t={signalData.name}
                  pair={additionalLines[signalData.SignalId] ? '' : signalPairs[signalData.SignalId]}
                  style={{
                    flexGrow: Math.floor(4 * topAxisSize * chartSizes[signalData.SignalId]),
                  }}
                >
                  <div
                    className="chartContainer"
                    key={signalData.Type + i + 'div'}
                    onContextMenu={(e) => iconRightClick(e, signalData.SignalId)}
                    style={{}}
                  >
                    {/* <div className="chartTitle">{signalData.Type.slice(0, 20)}</div> */}
                    <div className="signalPair"></div>
                    {/* <div className="chartTitleType">{additionalLines[signalData.SignalId] ? "Multiple" : signalData.Specification.slice(0, 20)}</div> */}
                    <span className="chartIcons">
                      <Draggable
                        handle=".handle"
                        defaultPosition={{ x: 0, y: 0 }}
                        position={{ x: 0, y: 0 }}
                        key={signalData.Type + i}
                        grid={[10, 10]}
                        scale={1}
                        onStart={(e, data) => {
                          e.target.closest('.chartName').classList.toggle('dragged', true);
                          zoombandPosition.classList.toggle('active');
                        }}
                        onDrag={(e, data) => {
                          draggingChart = true;
                          const container = e.target.closest('.chartName');
                          if (container) {
                            container.classList.toggle(
                              'active',
                              container.getAttribute('pair') && parseInt(container.getAttribute('pair')) === signalData.SignalId,
                            );
                          }
                          // if (cont.contains(e.target)) {
                          //   cont.className = "unusedCharts dragHover";
                          // } else {`
                          //   cont.className = "unusedCharts";
                          // }
                        }}
                        onStop={(e, data) => moveChart(e, i, false, data.y, signalData)}
                      >
                        <span className="handle visibleChart">
                          <Tooltip title={signalData.name}>
                            <span>{globalData.signalIcon[signalData.SignalId] || <ReactLogo fill={color} width={20} height={20} />}</span>
                          </Tooltip>
                        </span>
                      </Draggable>
                      {additionalLines[signalData.SignalId] && (
                        <Draggable
                          handle=".handle"
                          defaultPosition={{ x: 0, y: 0 }}
                          position={{ x: 0, y: 0 }}
                          key={signalData.Type + i + 'second'}
                          grid={[10, 10]}
                          scale={1}
                          onStart={() => {
                            zoombandPosition.classList.toggle('active');
                          }}
                          onDrag={(e, data) => {
                            draggingChart = true;
                            const cont = document.getElementsByClassName('unusedCharts')[0];
                          }}
                          onStop={(e, data) => moveChart(e, i, true, data.y, secondSignal)}
                        >
                          <span className="handle visibleChart">
                            <Tooltip title={secondSignal.name}>
                              <span>
                                {globalData.signalIcon[additionalLines[signalData.SignalId]] || (
                                  <ReactLogo fill={color} width={20} height={20} />
                                )}
                              </span>
                            </Tooltip>
                          </span>
                        </Draggable>
                      )}
                    </span>
                  </div>
                </div>
                <div className="chartPosition" key={signalData.Type + i + 'position'} index={i + 1}>
                  <div className="chartPositionInside" index={i + 1}></div>
                </div>
              </React.Fragment>
            );
          })}
        <div key="chart_last" className="chartName" style={{ flexGrow: 14, position: 'relative' }}></div>
      </div>
      {!loading && (
        <div className="unusedCharts" id="unusedCharts">
          {Object.keys(allChartData).map((signalId, i) => {
            if (!unusedSignalsIds.includes(parseInt(signalId)) || addedCharts.includes(parseInt(signalId))) {
              return null;
            }
            const signalData = allChartData[signalId];
            if (!signalData) return null;
            if (signalData.name === 'Position') return null;
            let color = chartColors[signalData.SignalId];
            return (
              <Draggable
                bounds={{ left: -500, right: 3000, bottom: 2000 }}
                key={signalId + i + 'unused'}
                position={{ x: 0, y: 0 }}
                onDrag={(e, data) => {
                  if (typeof e.target.className === 'string')
                    zoombandPosition.classList.toggle('hovered', e.target.className.startsWith('zoombandPosition'));
                  const container = e.target.closest('.chartNameUsed');
                  if (container)
                    container.classList.toggle(
                      'active',
                      container.getAttribute('pair') && parseInt(container.getAttribute('pair')) === signalData.SignalId,
                    );
                  draggingChart = true;
                }}
                onStart={(e) => {
                  zoombandPosition.classList.toggle('active');
                  unusedChartsContainer.classList.toggle('active', true);
                }}
                onStop={(e, data) => addUnusedChart(e, parseInt(signalId), data.y, signalData)}
              >
                <div className="chartName handle invisibleChart">
                  <Tooltip title={signalData.name}>
                    <span className=" unusedIcon">
                      {globalData.signalIcon[signalData.SignalId] || <ReactLogo fill={color} width={20} height={20} />}
                    </span>
                  </Tooltip>
                </div>
              </Draggable>
            );
          })}
        </div>
      )}
    </>
  );
};

function updateChartIcons(prevProps, nextProps) {
  if (!nextProps.signalsData.length) return true;
  return (
    prevProps.zoomSignalId === nextProps.zoomSignalId &&
    prevProps.chartsDataUpdated === nextProps.chartsDataUpdated &&
    Object.values(prevProps.additionalLines).filter((l) => l).length === Object.values(nextProps.additionalLines).filter((l) => l).length &&
    prevProps.selectedCharts.join('!') === nextProps.selectedCharts.join('!') &&
    nextProps.signalsData.map((s) => s.SignalId).join('!') === prevProps.signalsData.map((s) => s.SignalId).join('!') &&
    Object.keys(prevProps.allChartData).length === Object.keys(nextProps.allChartData).length
  );
}

export default React.memo(ChartIcons, updateChartIcons);
