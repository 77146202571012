
import '../../bsl/core/ajax/ajax.js';
import DocSelectorsControlPanel from "./DocSelectors-ControlPanel.js";
import React from "react";
var ccc = console.log; ccc && ( ccc = console.log );




//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var $$          = ns.$$;
var fconf       = ns.fconf;
var fapp        = ns.sn('fapp' );
var fmethods    = ns.sn('methods',fapp);
var fheap       = ns.sn('fheap', fapp);
var currtpl     = ns.sn('currtpl', fconf);
var tplpopul    = ns.sn('tplpopulation', fconf);

var aModel      = ns.sn('aModel', fapp);
var pgModels    = ns.sn('pgModels', aModel, [] );

fheap.ajaxInProgress = 0;

fmethods.setChosenClassOnPageIcon = setChosenClassOnPageIcon;
fmethods.cloneHeader_2_page = cloneHeader_2_page;









///===================================================
/// clones header from template page
///===================================================
function cloneHeader_2_page({ pmodel, samplePgModel })
{
    var sample_pmcards = samplePgModel.pmcards;
    //var found_pmcard = null;
    var found_pmcards = [];

    sample_pmcards.forEach( (pmcard, cix) => {
        if( pmcard.dtcard.dsg_id === 'pages-header' ) {
            found_pmcards.push( pmcard );
        }
    });

    found_pmcards.forEach( found_pmcard => {
        var dtcard = ns.clonetree( found_pmcard.dtcard );
        var dataGr = found_pmcard.dataGr;
        var datSGr = dataGr.subGrs[ dtcard.dsg_ix ];
        fmethods.addCardToApp({
            pageModel : pmodel,
            datSGr,
            cpos : dtcard.pos,
            style8data_tpl__sample : found_pmcard.dtcard.style8data_tpl,
        });
    });
}


function setChosenClassOnPageIcon()
{
        var dt = currtpl.docbody;
        var chosenIx = dt.pages.contrPanelChosen_pageIx;
        dt.pages.population.forEach( (tpl_dummy,pageIx) => {
            var repoCont$ = $$.q('.page-repo-parent-'+pageIx);
            var page$ = pgModels[ pageIx ].dom$$.editorPage$;
            if( chosenIx === pageIx ) {
                repoCont$.addClass( 'chosen' );
                page$.addClass( 'chosen' );
            } else {
                repoCont$.removeClass( 'chosen' );
                page$.removeClass( 'chosen' );
            }
        });

        if( ns.h( ns.fapp.fheap, 'editedPageTop' ) ) {
            var MISFIT_PATCH = fconf.DASHBOARD.UNSCALED_PAGE_MARGIN_TOP_PX;
            var pagParTop = MISFIT_PATCH -
                            ns.fapp.fheap.editedPageTop[ chosenIx ][ 1 ] *
                            ns.fapp.fheap.editedPageScale
                            ;
            fmethods.inlcss__pageWrappers( pgModels[ chosenIx ] );
            aModel.pagesParent$.css( 'top', pagParTop.toFixed(2) + 'px');
        }
    }





//==================================================
// builds dashboard controls
//==================================================
class LeftSideBar_ControlPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.handleChange = this.handleChange.bind(this);
        fmethods.populatePagesIcons = this.populatePagesIcons.bind(this);
        fapp.leftSideBarForceUpdate = this.forceUpdate.bind(this);
        this.state = {
            pageIx: currtpl.docbody.pages.contrPanelChosen_pageIx,
        };
    }

    ///toggles drop down pane
    toggleDD () {
        var el$ = $$.q( '.dashboard-left-side-bar' );
        el$.togcls( 'dropdown-closed' );
    }

    addPage() {

        fmethods.finalizes_tasksInProgress();
        var dt = currtpl.docbody;

        //----------------------------------------------------
        // //\\ updates doc template
        //----------------------------------------------------
        //.former location = dt.pages.emptyPage
        var newPageTpl = ns.clonetree(
            fconf.default_empty_doc.docbody.pages.population[0]
        );
        var newPgIx = dt.pages.population.length;
        dt.pages.population.push( newPageTpl );
        //----------------------------------------------------
        // \\// updates doc template
        //----------------------------------------------------

        //----------------------------------------------------
        // //\\ updates doc model and dom
        //----------------------------------------------------
        //todm ... overwork? why to touch other pages?
        fmethods.doc__dataModel();
        dt.pages.contrPanelChosen_pageIx = newPgIx;
        //todm ... overwork? why to touch all internals?
        fmethods.dash8doc__internals();
        //----------------------------------------------------
        // \\// updates doc model and dom
        //----------------------------------------------------

        //----------------------------------------------------
        // //\\ finally clones header if any
        //----------------------------------------------------
        cloneHeader_2_page({ pmodel:pgModels[ newPgIx ], samplePgModel:pgModels[0] });
        //----------------------------------------------------
        // \\// finally clones header if any
        //----------------------------------------------------

        fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
    }

    deleteSelectedPage() {

        fmethods.finalizes_tasksInProgress();

        var dt = currtpl.docbody;
        var ix = dt.pages.contrPanelChosen_pageIx;

        dt.pages.population.splice( ix, 1 );
        dt.pages.contrPanelChosen_pageIx = Math.max( 0, ix-1 );

        //changes models
        //too much:tmp patch:
        pgModels.length = 0;
        //pgModels.splice( ix, 1 ); //no good: inner indices ...
        fmethods.doc__dataModel();
        fmethods.dash8doc__internals();
        fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
    }

    //---------------------------------
    // //\\ selection boxes
    //---------------------------------
    handleChange( event ) {

        fmethods.finalizes_tasksInProgress();

        var dt = currtpl.docbody;
        var foundPageIx = null;
        dt.pages.population.forEach( (tpl,pageIx) => {
            if( event.target.value === ''+pageIx ) {
                foundPageIx = pageIx;
            }
        });
        if( foundPageIx === null ) return;
        dt.pages.contrPanelChosen_pageIx = foundPageIx;
        this.setState({
            pageIx: foundPageIx,
        });
        this.populatePagesIcons();
    }


    //---------------------------------
    // //\\ populates pages pane
    //---------------------------------
    populatePagesIcons()
    {
        var dt = currtpl.docbody;
        var prepo$ = $$.q( '.pages-repo' );
        if( !prepo$() ) {
            return;
        }
        prepo$.html( '' );

        dt.pages.population.forEach( (ct_dummy,pageIx) => {
            var pageSupercontainer$ = $$.div()
              .to( prepo$ )
              .addClass( 'page-repo-superparent page-repo-superparent-'+pageIx )
              ;
            $$.div()
              .addClass( 'page-repo-parent page-repo-parent-'+pageIx )
              .e( 'click', () => {
                    this.handleChange({ target : { value : ''+pageIx } })
              })
              .ch(
                $$.c( 'div' )
                  .html( 'Page ' + (pageIx+1))
              )    
              .to( pageSupercontainer$ )
              ();
        })
        setChosenClassOnPageIcon();


        if( fconf.ROLE === 'CONSTRUCTOR' ) {
            var pageSupercontainer$ = $$.div()
              .to( prepo$ )
              .addClass( 'page-repo-superparent add-new' )
              ;
            $$.div()
              .addClass( 'page-repo-parent add-new' )
              .e( 'click', this.addPage.bind(this) )
              .ch(
                $$.c( 'div' )
                  .html( 'Add New' )
              )    
              .to( pageSupercontainer$ )
              ();
        }
        //todo .... why fails? ... methods.rightCP_CSS();
    }
    //---------------------------------
    // \\// populates pages pane
    //---------------------------------


    ///**********************************************
    /// renders control panel
    ///**********************************************
    render() {
        var dt = currtpl.docbody;
        //ccc( 'renders docid ' + currtpl.docid );
        return (
            <div className="dashboard-left-side-bar dropdown-closed" key="dashboard-left-side-bar">
                <div className="left-side-cp" key="left-side-cp">
                    <div className="left-side-cp-dropdown-toggler">
                        <div className="cp-button" onClick={this.toggleDD} >
                            <i className="fa fa-cog" />
                        </div>
                    </div>
                    <div className="left-side-cp-dropdown">

                        <DocSelectorsControlPanel />

                        {
                            fconf.ROLE === 'CONSTRUCTOR' ?
                            (
                                <div className="cp-button doc-level-controls"  key="save-template"
                                    title="Save Current Doc Template"
                                    onClick={
                                        () => {
                                            fmethods.saveTemplate({
                                                 tpl : tplpopul[ currtpl.docid ],
                                            })
                                        }
                                   }>
                                    Save
                                </div>
                            ) : ''
                        }
                        {
                            dt.pages.population.length > 5 ? (
                                <div className="select-page-template-parent"
                                    title="Go to and Select Page"
                                    key="select-page">
                                    <select className="cp-button select-page-template"
                                        value={ this.state.pageIx }
                                        onChange={ this.handleChange }
                                    >
                                    {
                                        dt.pages.population.map( (pageTpl_dummy,pix) => {
                                            return (
                                                <option key={pix}
                                                    name={pix} value={pix}
                                                >{"Page " + (pix+1)}
                                                </option>
                                            );
                                        })
                                    }
                                    </select>
                                </div>
                            ) : ''
                        }
                        {
                            fconf.ROLE === 'CONSTRUCTOR' ?
                            (
                                <div className="cp-button"
                                    key="delete-template"
                                    title="Delete Highlighted Page"
                                    onClick={this.deleteSelectedPage} >
                                    Delete Page
                                </div>
                            ) : ''
                        }
                        {
                            fconf.ROLE === 'CONSTRUCTOR' ?
                            (
                                <div className="cp-button"
                                     title="Add Page"
                                     key="add-new"
                                     onClick={this.addPage} >
                                    Add Page
                                </div>
                            ) : ''
                        }
                    </div>
                </div>
                <div key="pages-repo" className="pages-repo" />
            </div>
        );  //return
    } //render() {
}


export default LeftSideBar_ControlPanel;

