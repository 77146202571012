import React, {Component} from 'react'

//keep care about this rule:
//this actually must be first among window.b$l modules application module to import:
//it loads ns/b$l library as well:
import '../report-composer/conf.js';
import generates_dataOptions from '../report-composer/templates/multi-tpl/generate-data-options.js';


// https://www.highcharts.com/docs/
import Highcharts from 'highcharts'

// documentation on highcharts-react-official https://www.npmjs.com/package/highcharts-react-official
import HighchartsReact from 'highcharts-react-official'

class GenericChart extends Component {

    constructor(props){
        super(props)
        this.state = {
            chart: null,
            width: 375,
            height: 375,
            chartOptions: {},
            reports: []
        }
        
    }


    componentDidMount(){
        this.populateChart()
    }

    componentDidUpdate(prevProps){
        if (JSON.stringify(this.props.reports) !== JSON.stringify(this.state.reports)){

            /* The data inside of these charts is updated as users choose which reports to include. 
            componentDidUpdate listens for any changes in this state or props as opposed to 
            componentDidMount which only activates once when the component is first loaded.*/

            this.populateChart() 
        }
    }

    populateChart(){
        let report = []
        report = this.props.reports.slice()
        if (!report){
            return
        }
        this.setState({
            reports: report,
            chartOptions: generates_dataOptions({

                //no need: chosen ix, chosenDG.chosenIx, is taken automatically
                //dsg_ix      : 0,

                dataId      : this.props.dataId, // pmcard.dtcard.dataId,
                reports     : this.props.reports, //fheap.data_rows, = ajax-data.reports,
                chartWidth  : this.state.width,
                chartHeight : this.state.height,
            }),
        })
    }
    
	render(){

        //console.log( 'COMMON TEMPLATES ARE IN USE' );
        // options stores the data that the chart will display.
        // This should get it's values from state/props
        var {chartOptions} = this.state

		return(
            <div style={{width: this.state.width, height: this.state.height}}>
                <div className="handle" hidden={this.state.resizing}>{<HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />}
                </div>
            </div>
		)
	}
}

export default GenericChart
