var ccc = console.log; ccc && ( ccc = console.log );

var ns          = window.b$l;
var sn          = ns.sn;
var fapp        = sn('fapp');
var fconf       = sn('fconf', fapp);

fapp.appver_dash_css = appver_dash_css;
fapp.appver_css = appver_css;

var singletonStyle = !'flag';


function appver_css()
{
    if( !singletonStyle ) {
        singletonStyle = document.createElement( 'style' );
        document.head.appendChild( singletonStyle );
    }
    singletonStyle.innerHTML =
        fapp.appver_dash_css() +
        fapp.appver_dash_css__CP() +

        appver_dash__Css4docPage() +
        fapp.appver_css_dash__chart8page() +

        //this belongs to doc-css ... not to CP-css
        fapp.appver_css__chart8card8table()
        ;
}


function appver_dash_css()
{
    var CP = fconf.CONTROL_PANELS;
    var LEFT_WIDTH = CP.DASHBOARD_LEFT_SIDEBAR_WIDTH_PERC;
    var DOC_WIDTH = 100 - LEFT_WIDTH;

    if( fconf.ROLE === 'CONSTRUCTOR' ) {
        DOC_WIDTH -= CP.DASHBOARD_RIGHT_SIDEBAR_WIDTH_PERC;
        var RIGHT_LEFT = DOC_WIDTH + CP.DASHBOARD_LEFT_SIDEBAR_WIDTH_PERC;
    } else {
        RIGHT_LEFT = 100;
    }
    var RIGHT_WIDTH = 100 - RIGHT_LEFT;

    var devFix;
    if( fconf.DEVELOPER_MODE ) {
        devFix = `
            position : relative;
            width : 100%;
        `;
    } else {
        devFix = `
            position : absolute;
            width : 95%;
        `;
    }

    return `


    /*****************************************/
    /* //|| plugin root                      */
    /*****************************************/
    .plugin-root {
        ${devFix}
        height : 100%;
        margin : 0px;
        background-color : ${fconf.DASHBOARD.COLORS.BACKGROUND};
    }
    /*****************************************/
    /* ||// plugin root                      */
    /*****************************************/


    /*---------------------------------------*/
    /* //|| plugin subroots                  */
    /*---------------------------------------*/
    .plugin-root .pages-superparent,
    .plugin-root .dashboard-left-side-bar,
    .plugin-root .dashboard-right-side-bar {
        position : absolute;
        top : ${CP.APP_CP_HEIGHT}px;
        overflow-x : hidden;
        overflow-y : auto;
    }
    .plugin-root .pages-superparent {
        position : absolute;
        width : ${DOC_WIDTH}%;
        left : ${LEFT_WIDTH}%;
        background-color : #bbb;
    }
    .plugin-root .doc-unsaved-to-db.pages-superparent {
        background-color : ${fconf.CHANGES_UNSAVED_TO_DB_COLOR};
    }

    .plugin-root .dashboard-left-side-bar {
        width :  ${LEFT_WIDTH}%;
        left : 0;
    }
    .plugin-root .dashboard-right-side-bar {
        width :  ${RIGHT_WIDTH}%;
        left : ${RIGHT_LEFT}%;
    }
    .plugin-root.user-role-user .dashboard-right-side-bar {
        display : none;
    }
    /*---------------------------------------*/
    /* ||// plugin subroots                  */
    /*---------------------------------------*/



    /*---------------------------------------*/
    /* //|| pages                            */
    /*---------------------------------------*/
    .pages-parent {
        position : relative;
        xxxxleft : 10px;
        top : ${fconf.DASHBOARD.UNSCALED_PAGE_MARGIN_TOP_PX}px;
    }
    .editor-page {
        position        : absolute;
        margin-bottom   : 15px; /* makes grey gaps between pages */
        background-color: #ffffff;
        box-sizing      : content-box;
    }
    /*---------------------------------------*/
    /* ||// pages                            */
    /*---------------------------------------*/



    /*---------------------------------------*/
    /* //|| controls                         */
    /*---------------------------------------*/
    .user-role-constructor.plugin-root .editable {
        border : 1px dashed transparent;
        box-sizing : border-box;
    }
    .user-role-constructor.plugin-root .editable:hover {
        border : 1px dashed ${fconf.DASHBOARD.COLORS.EDITABLE_OVERLAYS};
        //border-width : 4px;
    }
    /*---------------------------------------*/
    /* ||// controls                         */
    /*---------------------------------------*/



    /*---------------------------------------*/
    /* //|| content-decoration-controls      */
    /*---------------------------------------*/
    .user-role-constructor.plugin-root .content-decoration-controls {
        display : none;
        float : right;
        margin-right : 10px;
    }
    .user-role-constructor.plugin-root .content-decoration-controls .dcontrol {
        display : inline;
        margin-left : 5px;
        height : 15px;
        padding : 2px;
        font-size : 13px;
        border : 1px solid blue;
        border-radius : 4px;
    }

    .user-role-constructor.plugin-root .content-decoration-controls .dcontrol .border-width-entry,
    .user-role-constructor.plugin-root .content-decoration-controls .dcontrol .font-size-entry {
        width : 30px;
        height : 13px;
        border : 0;
    }
    .user-role-constructor.plugin-root .content-decoration-controls .dcontrol .border-color-entry,
    .user-role-constructor.plugin-root .content-decoration-controls .dcontrol .font-color-entry {
        width : 60px;
        height : 13px;
        border : 0;
    }
    /*---------------------------------------*/
    /* ||// content-decoration-controls      */
    /*---------------------------------------*/

}

    `;
}

//todo ... duplicates .editor-page.chosen etc.
function appver_dash__Css4docPage()
{

    //non-intuitively: transparent border shows page bg, not parent bg
    return `

    .editor-page {
        border : ${fconf.DASHBOARD.PAGE_BORDER_WIDTH}px solid ${fconf.DASHBOARD.PAGES_SUPERPARENT_BG_COLOR};
    }
    .editor-page.chosen {
        border-color : ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
    }


    `;
}


