
var ns          = window.b$l;
var sn          = ns.sn;
var fapp        = sn('fapp');
var fconf       = sn('fconf', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

fapp.appver_css_dash__chart8page = appver_css_dash__chart8page;



function appver_css_dash__chart8page()
{

    ///todm todm this thing is overengineered
    return `

        .plugin-root.user-role-constructor .chart-parent {
            border : 1px dashed ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
        }

        .plugin-root.user-role-constructor .editor-page.chosen .page-card.selected-card {
            border : 2px dashed ${fconf.DASHBOARD.COLORS.FOCUS_BORDER};
        }

        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.rows-bound,

        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.rows-bound
        .chart-parent,


        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.horizontal-line,

        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.horizontal-line


        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.cells-bound,

        .plugin-root.user-role-constructor .editor-page.chosen
        .page-card.selected-card.cells-bound
        .chart-parent
        {
            border : none;
        }
    `;
}

