
export default function () {
    return  {
        series: [{
            caption : 'enter the caption',      //designed to be field GUI caption
            data : [ '<hr>' ],
            cellstyle :                         //mapped to cells
            [
                {
                    backgroundColor : '#999999',
                    borderStyle : 'none',
                },
            ],
        }],
    }
}




