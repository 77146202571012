
import React from "react";
var ccc = console.log; ccc && ( ccc = console.log );


//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var fconf       = ns.fconf;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var currtpl     = ns.sn('currtpl', fconf);















//==================================================
// builds dashboard controls
//==================================================
class RepocardSelection extends React.Component {

    constructor( props ) {
        super( props );
        var dt = currtpl.docbody;
        this.state = {
            chosenCardIx: dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ].chosenIx,
        };
        fmethods.handleRepocardChangeEvent = this.handleRepocardChangeEvent.bind(this);
    }
    componentDidUpdate() {
        fmethods.modelify8containify_rightB_icons8masks();
        fmethods.inldynCSS_CP();
    }

    //---------------------------------
    // //\\ selection boxes
    //---------------------------------
    //chart template box
    handleRepocardChangeEvent( event ) {
        var dt = currtpl.docbody;
        var newCardIx = parseInt( event.target.value );
        if( dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ].chosenIx === newCardIx ) return;
        dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ].chosenIx = newCardIx;
        fmethods.setChosenClassOnCard();
        this.setState({
            chosenCardIx:newCardIx,
        });
    }
    //---------------------------------
    // \\// selection boxes
    //---------------------------------




    ///**********************************************
    /// renders control panel
    ///**********************************************
    render() {
        var dt = currtpl.docbody;
        var dgIx = dt.contrPanelChosen_tplDatGrIx;
        var datGr = dt.repo.dataGroups[ dgIx ];

        return (
            <div>
                <div className="right-side-cp2" key="right-side-cp2">
                    <div className="right-side-cp-dropdown2 right-side-cp-dropdown">
                        <div className="select-chart-template-parent" key="chart-template">
                            <select
                                className="cp-button select-chart-template"
                                value={ dt.repo.dataGroups[ dgIx ].chosenIx }
                                onChange={ fmethods.handleRepocardChangeEvent }
                            >
                            {
                                datGr.subGrs.map(
                                    (chartGr,cix) => {
                                        var subGr = datGr.subGrs[ cix ];
                                        return (
                                            <option key={cix}
                                                name={cix} value={cix}
                                                title={datGr.dashCaption + ' ' + subGr.dashCaption}
                                            >{
                                                subGr.dashCaption
                                             }
                                            </option>
                                        );
                                })
                            }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="cards-repo" key="cards-repo" />
            </div>
        );  //return
    } //render() {
}

export default RepocardSelection;



