( function() {
    var ns          = window.b$l;
    var $$          = ns.$$;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fconf       = ns.fconf;
    var fmethods    = ns.sn('methods', fapp);
    var currtpl     = ns.sn('currtpl', fconf);
    var tasksInProgress = ns.sn( 'tasksInProgress', ns.fheap, [] );
    var ccc         = console.log; ccc && ( ccc = console.log );

    fmethods.managesInputOverlay        = managesInputOverlay;
    return;






    /*
    //managesInputOverlay nest:
        parent = cells$[ cix ]
            editwrap = $$ //has all active controls
                //wrapdiv$ //css-position absolutifier
                    //input$ - holds editee's-value
                    //selectDataBinding$
                    //selectFieldBindingParent$ - place holder ... goes to life when
                                                  selectDataBinding$::change happens
                        //selectFieldBinding$
                        //   options as fields
                    //button: cleanupJobs();
            domEl$ //cell's-span-content
    */

    ///enables overlay over dashboard fields to edit these fields
    ///inserts cluster near editee and avoids collision with target editee el.
    function managesInputOverlay({
        modelObject,
        model_prop0ix, //is index for cellbound table cells
        serieDataCell_binding,

        dom$,       //preferred over cccpath
        cccpath,    //not used if cell$ is supplied

        taskCompletionCallback
    }) {

        if( fconf.ROLE !== 'CONSTRUCTOR' ) return; //edit is only for admin

        cleanupJobs(); //in case user did not close other box

        var domEl$ = dom$ || $$.q( cccpath );
        var parent = domEl$().parentNode;
        if( parent.querySelector( 'input.text-input-overlay' ) ) {
            //ccc( 'parent.querySelector already exists', parent.querySelector( 'input' ) );
            return; //already set
        }
        $$.$( parent ).removeClass( 'not-under-edit' );

        // //\\ model parameters for select element
        var bindsRepo = currtpl.docbody.repo.dataBinds;

        //such keys as "patient", "report", ... of "major-binds-options-block"
        var bRepoKeys = Object.keys( bindsRepo );

        //caption is a title of "major-binds-options-block"
        var dataBindsCaptions = bRepoKeys.map( brKey => bindsRepo[ brKey ].caption );

        //' ' is a fictitious key of static,no-data, binding
        bRepoKeys = [' '].concat( bRepoKeys );
        dataBindsCaptions = ['s e l e c t group'].concat( dataBindsCaptions );

        var selectDataBinding$;
        var selectFieldBindingParent$;
        var dataBindingCell = serieDataCell_binding && (
            serieDataCell_binding[ model_prop0ix ] =
            serieDataCell_binding[ model_prop0ix ] || {}
        )
        ;
        // \\// model parameters for select element
        var input$;
        var wrapdiv$;
        var editwrap = $$
            .c( 'div' )
            //.cls( 'editwrap-test' )
            ///gives birth to absolute cluster
            .css( 'position', 'relative' )
            .ch( wrapdiv$ = $$.c( 'div' )
                ///creates absolute holder to hold fluid "input" and "button" elements
                .css( 'position', 'absolute' )
                .css( 'left', '0px' )
                .css( 'top', '25px' )
                .css( 'opacity', '0.9' )
                .css( 'z-index', '40000' )
                .ch( input$ = $$
                    .c( 'input' )
                    .addClass( 'text-input-overlay' )

                    //takes value from editee's-dom-element, not from edittee-field
                    .a( 'value', domEl$().innerHTML )

                    .e( 'keyup', ( event ) => {
                        //todm: no keyup: setTimeout for universal solution
                        if( event.key === "Escape" ) {
                            cleanupJobs();
                            return;
                        }
                        if( serieDataCell_binding ) {
                            serieDataCell_binding[ model_prop0ix ] = null;
                        }

                        //puts new value to editee-object
                        modelObject[ model_prop0ix ] = input$().value;

                        //.immediately visualizes update in place
                        //.by putting value to editee's-dom-element
                        domEl$.html( modelObject[ model_prop0ix ] );
                    })
                )
            )
            ();

        if( dataBindingCell ) {
            //**************************************************************************
            //when at the end of this very long usage scenario
            //user finlally clicks on option-of-select-element, selectDataBinding$, then
            //  dataBindingCell.field = field does populate possibly
            //  with compound object and updates the dynamic serie.serieDataCell_binding
            //  and this not all ...
            //  after serie.serieDataCell_binding has been rebuilt, the data-element must be
            //  rebuilt also ... so this happens in "taskCompletionCallback" aka
            //
            //  in serDatCelBin_2_serData():
            //      serie.data[ cix ] =  fieldKey2value({ cix, sdata, fheap, bindsRepo, ...
            //**************************************************************************
            wrapdiv$
                .ch( selectDataBinding$ =
                    $$
                        .c( 'select' )
                        .a( 'title', 'select data binding group' )
                        .a( 'value', bRepoKeys[0] )
                        .e( 'change', ( event ) => {
                            var selIx = event.target.selectedIndex;
                            var bRepoKey = bRepoKeys[ selIx ];

                            //**********************************
                            //step1: dbc gets bRepoKey
                            dataBindingCell.bRepoKey = bRepoKey;
                            //**********************************

                            builds_dataBindFields_selectEl({
                                bRepoKey:bRepoKeys[ selIx ],
                                bindsRepo,
                                dataBindingCell,
                                selectFieldBindingParent$,
                            });
                        })
                )
        }
        wrapdiv$
            .ch( selectFieldBindingParent$ = $$.div() )
            .ch( 
                $$
                    .c( 'button' )
                    .a( 'title', 'propagate your edit through document ' +
                                 'and close cell edit overlay' )
                    .e( 'click', ( event ) => {
                        event.stopPropagation();
                        cleanupJobs();
                    })
                    .html( 'X' )
            )
        if( dataBindingCell ) {
            bRepoKeys.forEach( ( brKey, ix ) => {
                selectDataBinding$.ch(
                     $$.c( 'option' )
                    .a( 'name', brKey )
                    .a( 'value', brKey )
                    .html( dataBindsCaptions[ ix ] )
                );
            });
        }
        //:inserts cluster near editee and avoids collision with target editee el.
        parent.insertBefore( editwrap, parent.firstChild );
        input$().focus();

        tasksInProgress.push( finishTheJob );
        return;








        function finishTheJob()
        {
            //should have code to update changes in entire model
            taskCompletionCallback && taskCompletionCallback();

            //this is not very nice: duplication of flags:
            //  editwrap exists and
            //  !not-under-edit
            editwrap.remove();
            $$.$( parent ).addClass( 'not-under-edit' );
        }

        function cleanupJobs()
        {
            tasksInProgress.forEach( fun => fun() );
            tasksInProgress.length = 0;
        }

        //===============================================
        // //\\ builds final select element
        //      which selects field and quits the overlay;
        //      builds two events which build
        //      data-cell-binding-configuration
        //===============================================
        function builds_dataBindFields_selectEl({
            bRepoKey,
            bindsRepo,
            dataBindingCell,
            selectFieldBindingParent$,
        }) {
            selectFieldBindingParent$.html( '' );
            if( bRepoKey === ' ' ) {
                return;
            }
            var fields = bindsRepo[ bRepoKey ].fields;
            var selectFieldBinding$;
            //can be a compound field
            var defaultField = fields[0];

            //.when sets child-select for the first time
            //.does set card-template to default
            dataBindingCell.field = defaultField;

            ///sets GUI-select element for major-binding-block
            selectFieldBindingParent$
                .ch( selectFieldBinding$ =
                    $$
                        .c( 'select' )
                        .a( 'title', 'select data binding field' )
                        .a( 'value', defaultField )
                        .e( 'change', ( event ) => {

                            //using an index is vital ...
                            //using option's "name" or "value" will "break" the code
                            //"-1" is because of prompt
                            var selIx = event.target.selectedIndex - 1;
                            var field = fields[ selIx ];

                            //**********************************
                            //step2: dbc gets field
                            dataBindingCell.field = field; //this can be a (compound) object
                            //**********************************

                            //does not let user change own mind: closes dialog immediately:
                            cleanupJobs();
                        })
                )
                ;

            selectFieldBinding$.ch(
                 $$.c( 'option' )
                .a( 'name',     '' )
                .a( 'value',    '' )
                .html( 's e l e c t field' )
            );


            ///sets GUI-options of select element for major-binding-block
            ///minor-field-keys in major-binding-block
            fields.forEach( ( fkey, ix ) => {
                var minorKey = fkey;
                var minorCaption = fkey;
                if( typeof fkey === 'object' ) {
                    minorKey = fkey.id;
                    var explanation = fkey.fieldKeys.join( ' + ' );
                    minorCaption = fkey.caption + ' = "' + explanation + '"';
                }
                selectFieldBinding$.ch(
                     $$.c( 'option' )
                    .a( 'name',     minorKey )
                    .a( 'value',    minorKey )
                    .html( minorCaption )
                );
            });
            //ccc( 'set to', dataBindingCell ); 
        }
        //===============================================
        // \\// builds final select element
        //===============================================
    }


}) ();

