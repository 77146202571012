import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Alert from './alert'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

class Login extends Component {

	constructor(props) {
		super(props)
		this.state = {
			errorMessage: null,
			alertType: '',
			form: {
				email: '',
				password: '',
				rememberMe: "off"
			},
			showpass: false

		}
	}

	componentDidMount() {
		if (localStorage.rememberMe === "on" && localStorage.email !== '') {
			this.setState({
				email: localStorage.email,
				password: localStorage.password,
				rememberMe: localStorage.rememberMe
			}, this.autoLogin)
		}
	}

	autoLogin(){
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'POST',
			url: '/login',
			data: {
				username: localStorage.email,
				password: localStorage.password,
			}
		}).done(this.props.handleUser).fail(failure)
	}



	changeHandler(event) {
		var form = this.state.form
		form[event.target.name] = event.target.value
		this.setState({
			form: form
		})
	}

	submit(event) {
		event.preventDefault()
		var failure = this.errorHandler.bind(this)
		if (this.state.form.rememberMe === "on"){
			localStorage.rememberMe = this.state.form.rememberMe
			localStorage.email = this.state.form.email
			localStorage.password = this.state.form.password
		}
		$.ajax({
			method: 'POST',
			url: '/login',
			data: {
				username: this.state.form.email,
				password: this.state.form.password
			}
		}).done(this.props.handleUser).fail(failure)
	}

	errorHandler(res) {
		var body = res.responseJSON
		var error = body.error
		this.setState({
			errorMessage: error.message,
			alertType: 'alert-danger'
		})
	}

	togglePassword = () => {
		//var showPass = this.state.showPass
		this.setState({
			showPass: !this.state.showPass
		})
	}

	removeAlert() {
		this.setState({
			errorMessage: null,
		})
	}

	render() {
		var removeAlert = this.removeAlert.bind(this)
		var submit = this.submit.bind(this)
		var handleChange = this.changeHandler.bind(this)
		var togglePassword = this.togglePassword.bind(this)
		var showPass = this.state.showPass
		return (
			<div id='login'>
				<h1 className='display-4 mt-4 mb-4'>Login</h1>
				<form onSubmit={submit}>
					<div className='form-group'>
						<label htmlFor="email" className="sr-only">Email address</label>
						<input className='form-control' name='email' aria-describedby='emailHelp' placeholder='Enter email' onChange={handleChange} />
					</div>
					<div className='form-group'>
					<label htmlFor='password' classname='sr-only' style={{display: "block"}}>
						<div className="input-group">
							<input type={showPass ? 'text' : 'password'} className='form-control' name='password' placeholder='Password' onChange={handleChange} />
							<button type="button" onClick={togglePassword} style={{background:"none", color:"lightgray", border:"1px solid", borderLeft:"none", borderRadius:"0 3px 3px 0"}}>
								<FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} />
							</button>

						</div>
							

					</label>
					</div>
					<div className='form-check'>
						<input type='checkbox' className='form-check-input' name='rememberMe' onChange={handleChange}></input>
						<label className='form-check-label'>Stay Signed In</label>
					</div>
					<button type='submit' className='btn btn-primary mb-3 mt-2'>Submit</button>
				</form>
				<div>
					<div>
						<Link to='/forgot'>Don't remember your password?</Link>
					</div>
					<div className='mb-2'>
						<Link to='/signup'>Don't have an account?</Link>
					</div>
				</div>
				<div>
					Chrome & Firefox compatible
				</div>
				

				<Alert message={this.state.errorMessage} type={this.state.alertType} onExit={removeAlert} />
			</div>
		)
	}

}

export default Login
