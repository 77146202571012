import generates_dataOptions from '../../templates/multi-tpl/generate-data-options.js';

var ns	        = window.b$l;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods',fapp);
var aModel      = ns.sn('aModel', fapp);
var fheap       = ns.sn( 'fheap', ns.fapp );
var fconf       = ns.fconf;
var currtpl     = ns.sn('currtpl', fconf);
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.dtcardPos_2_cssModel      = dtcardPos_2_cssModel;
fmethods.card__dataModel = card__dataModel;
fmethods.generates_dataOptions_finalizer  = generates_dataOptions_finalizer;
fmethods.delete_cardTpl_from_docTpl = delete_cardTpl_from_docTpl;
fmethods.serDatCelBin_2_serData = serDatCelBin_2_serData;
fmethods.addCardToApp = addCardToApp;
fmethods.removeHeaderFromPage = removeHeaderFromPage;
fmethods.removeHeadersFromPage = removeHeadersFromPage;
fmethods.cellBound__tpl_2_model8dom = cellBound__tpl_2_model8dom;
fmethods.oneheaderTpl_2_allPages_model8dom = oneheaderTpl_2_allPages_model8dom;











function addCardToApp({ pageModel, datSGr, cpos, style8data_tpl__sample })
{
    var docbody = currtpl.docbody;
    var pageCards = docbody.pages.population[ pageModel.pageIx ].cards;

    //------------------------------------
    // //\\ creates document-card-template
    //------------------------------------
    var dtcard = ns.clonetree( datSGr );
    ns.paste( dtcard.pos, cpos );
    //todm ... remove this artifact aliasing
    dtcard.style8data_tpl = dtcard.tpl;

    //updates doc tpl
    pageCards.push( dtcard );
    //------------------------------------
    // \\// creates document-card-template
    //------------------------------------


    //------------------------------------
    // //\\ creates card-model
    //------------------------------------
    var pmcard = card__dataModel({
        pm : pageModel,
        dtcard,
        cardIx : pageCards.length-1,
    });
    //------------------------------------
    // \\// creates card-model
    //------------------------------------

    if( style8data_tpl__sample ) {
        ns.cleanpaste( dtcard.style8data_tpl, style8data_tpl__sample );
    }

    fmethods.dom__cardFrames({
        pgModel : pageModel,
        cardModel : pmcard,
    });

    fmethods.card__internals({ pmcard });
    fmethods.inlcss__doResizeCardFrames({ pmcard });

    fmethods.create_corner8move_draggers( pmcard );
}





///=========================================================================
///creates card model and puts it into placeholder which creates or updates;
///        card model is not saved, docCardTpl is saved;
///=========================================================================
function card__dataModel({
        pm,
        dtcard,
        cardIx,
}) {

    var dataGroups = currtpl.docbody.repo.dataGroups;
    var dataGr = dataGroups[ dtcard.dg_ix ];
    var pmcards = pm.pmcards;

    //creates or refers
    var pmcard = pmcards[ cardIx ] = pmcards[ cardIx ] || {
        pageIx      : pm.pageIx,
        cardIx,
        pgModel     : pm,
        dataGr      : dataGr, //=framework template: for generic reference,
                              // not a part of doc content
        dtcard      : dtcard, //=fragment of doc template
    };
    fmethods.dtcardPos_2_cssModel({ pmcard });
    generates_dataOptions_finalizer( pm, pmcard ); //todo ... do this only for single pmcard
    return pmcard;
}


///this function overhauls serie.data completely from "scratch"
function generates_dataOptions_finalizer( pgModel, onlyForThis_pmcard )
{
    var pmcards = pgModel.pmcards;

    pmcards.forEach( (pmcard,cardIx) => {
        if( onlyForThis_pmcard && onlyForThis_pmcard.cardIx !== cardIx ) return;
        var dtcard      = pmcard.dtcard;
        var dataGroup   = pmcard.dataGr;
        var dataId      = dataGroup.dataId;
        var dsg_ix      = dtcard.dsg_ix;
        var isChart     = pmcard.dataGr.isChart;
        if( isChart || dataId === "rows-bound" ) {
            pmcard.cdataOptions = generates_dataOptions({
                dsg_ix,
                dataId,
                reports : fheap.data_rows,
                isTable : pmcard.dataGr.isTable,
            });
            ///-----------------------------------------------
            /// adds vendor-credits options only to highcharts
            ///-----------------------------------------------
            if( isChart ) {
                var credits = ns.sn( 'credits', pmcard.cdataOptions, {} );
                ///Adds credits.enabled property if missed in original options.
                if( !credits.hasOwnProperty( 'enabled' ) ) {
                    credits.enabled = fconf.ENABLE_HIGHCHARTS_CREDITS;
                }
            }

            ///aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            ///all of this should be moved into "generate data options"
            ///aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa

            ///patch: formats accomulated values like "value - value" like for
            ///       table for "Hight Base Line - Low Base Line" field
            if (!pmcard.cdataOptions) return
            pmcard.cdataOptions.series.forEach( serie => {
                var data = serie.data;
                var round = serie.METAOPT_round_to_digits;
                var doRound = typeof round === 'number';
                round = doRound ? round : 0;
                var len = data.length;
                for( var ix = 0; ix < len; ix++ ) {
                    var el = data[ ix ];
                    /*
                    //good debug:
                    if( serie.name === 'Study Dates' ) {
                        ccc( ix + ' raw data cell=', el );
                    }
                    */
                    if( serie.METAOPT_double_point ) {
                        ////sorts array in descending key
                        if( el[ 1 ] > el[ 0 ] ) {
                            var ww = el[ 0 ];
                            el[ 0 ] = el[ 1 ];
                            el[ 1 ] = ww;
                        }
                        if( serie.METAOPT_summate_arithmetically ) {
                            var wwSum = el[0] + el[1];
                            if( typeof wwSum !== 'number' ) {
                                ////todo
                                ccc( 'bad fields type: field is non-numeric "' +
                                     wwSum + '" leaving it as is' );
                                data[ ix ] = ' ' + wwSum;
                            } else {
                                data[ ix ] = fmethods.doFormatDataEl( el[0] + el[1], serie, isChart );
                            }
                        } else {
                            if( Array.isArray(el) ) {
                                data[ ix ] = el
                                    .map( e =>
                                          ( typeof e === 'number' ?
                                                fmethods.doFormatDataEl( e, serie, isChart ) :
                                                ''
                                          )
                                    )
                                    .join( ' - ' );
                            } else {
                                ////todo
                                ccc( dtcard.dsg_id + ' ' + serie.name +
                                     ' prepared data is not an array: data=', el );
                                data[ ix ] = '' + el;
                            }
                        }
                    }
                }
            });

        } else if( dataId === "cells-bound" || dataId === "horizontal-line" ) {
            fmethods.serDatCelBin_2_serData( pmcard );
        }
    });
}



function delete_cardTpl_from_docTpl( pageIx, deleteIx )
{
    var dtpage = currtpl.docbody.pages.population[ pageIx ];
    dtpage.cards.splice( deleteIx, 1 );
    dtpage.selectedCardIx = 0;
}

function dtcardPos_2_cssModel({ pmcard })
{
    var ps          = currtpl.docbody.pstyle; //todo ... synch with default-doc-scenario
    //dtcard = docbody.pages.population[pageIx].cards[cardIx];
    var dtcard      = pmcard.dtcard;
    var cpos        = dtcard.pos;
    //----------------------------------------------
    // //\\ sets shapes
    //----------------------------------------------
    pmcard.tplWidth_px  = cpos[2] / 100 * ps.PAGE_WIDTH;
    pmcard.tplHight_px  = cpos[3] / 100 * ps.PAGE_HEIGHT;
    pmcard.tplLeft_px   = cpos[0] / 100 * ps.PAGE_WIDTH;
    pmcard.tplTop_px    = cpos[1] / 100 * ps.PAGE_HEIGHT;
    //----------------------------------------------
    // \\// sets shapes
    //----------------------------------------------
}

///a key routine where data enters into card-model and
///becomes dataOptions;
///this routine is not for charts and rowbound, but for cellbound;
function serDatCelBin_2_serData( pmcard )
{
    var bindsRepo = currtpl.docbody.repo.dataBinds;
    pmcard.cdataOptions = pmcard.dtcard.style8data_tpl;
    pmcard.cdataOptions.series.forEach( ( serie, rix ) => {

        //recall: serie does not have to have serieDataCell_binding to exist
        //in initial templates  ... see comment at: data-framework.gen.js::dataBinds ...
        var serDatCel_bin = ns.sn( 'serieDataCell_binding', serie, [] );

        var sdata = serie.data;
        for( var cix=0, datlen = sdata.length; cix < datlen; cix++ ) {
            var dcBin = serDatCel_bin[cix];
            if( dcBin ) {
                var brKey       = dcBin.bRepoKey; //actually "major-block-id"
                var fieldCont   = dcBin.field;    //"field content", can be a compound ...
                if( brKey && brKey !== ' ' ) {
                    //this is a key point ...
                    //sdata[ cix ] must be populated from the
                    //real live, dynamic data from db
                    //but the real live data are prepopulated in
                    //containers of form fheap....
                    //like containers
                    // fheap.fullAjaxData = data ...
                    //
                    //$.ajax({
		            //  method: 'GET',
		            // ......
	                //}).done( data => {
                    //... fheap.fullAjaxData = data;
                    //
                    //so the thing below (ns.hph)
                    //does "suck" from these containers by
                    //brKey and fname ...
                    //and if this path is "dead", then '' is returned:


                    //now, here is another twist ... compound fields
                    if( typeof fieldCont === 'object' ) {
                        var fname = fieldCont.id;
                        sdata[ cix ] = '';
                        fieldCont.fieldKeys.forEach( fname => {
                            //patch: ' ' as a dividor between primitive fields:
                            sdata[ cix ] += ( sdata[ cix ] ? ' ' : '' ) +
                                fieldKey2value({
                                    sdata, fheap, bindsRepo, fname, brKey, cix
                                });
                        });

                    } else {
                        fname = fieldCont;
                        sdata[ cix ] =
                            fieldKey2value({ sdata, fheap, bindsRepo, fname, brKey, cix });
                    }
                }
            }
        }
    });
}


function fieldKey2value({ sdata, fheap, bindsRepo, fname, brKey, cix })
{
    var value = ns.hph(
        fheap,
        bindsRepo[ brKey ].container + '.' + fname );

    ///and a cherry on cake's top: formatting the value:
    var ftr = ns.hph( bindsRepo, brKey + '.field2formatter.' + fname );

    /*
    if( fname === 'birthDate' && !ftr ) {
        ccc( 'still no dob ' );
    }
    */

    if( ftr ) {
        ////if formatter, ftr, is specified, then value, sdata[ cix ], is
        ////formatted according predefined function, ftr.funct, with
        ////format "ftr.format":
        var result = ns.fmethods.dataFormatters[ ftr.funct ]( value, ftr.format );
        //debug:
        /*
        if( fname === 'birthDate' ) {
            ccc( "function's index=" + ftr.funct +
                 ", format=", ftr.format,
                 ', arg value=', value,
                 ", result=", result );
        }
        */
        value = result;
    }
    return value;
}














function removeHeaderFromPage({ pageModel,dataId, dsg_id, })
{
    var headerCix = null;
    pageModel.pmcards.forEach( (pmcard, cix) => {
        if( pmcard.dtcard.dsg_id === 'pages-header' && pmcard.dtcard.dataId === dataId ) {
            headerCix = cix;
        }
    });
    if( headerCix !== null ) {
        fmethods.removeCard( pageModel.pageIx, headerCix )
    }
}

function removeHeadersFromPage({ dataId, dsg_id })
{
    aModel.pgModels.forEach( pageModel => {
        fmethods.removeHeaderFromPage({ pageModel,dataId, dsg_id, });
    });
}

///used when user chnaged the cell, then tpl goes to dat.opt and to entire table
function cellBound__tpl_2_model8dom( pmcard ) {
    fmethods.serDatCelBin_2_serData( pmcard );
    ns.methods.calls_tableAPI({ pmcard, doDrawInlineCssAfter:true });
}



///--------------------------------------------------------
/// updates page headers
///--------------------------------------------------------
function oneheaderTpl_2_allPages_model8dom({
    pmcard, skipOwn, search_dataId,
}) {
    var masterStyle = pmcard.dtcard.style8data_tpl;
    var dtcard = pmcard.dtcard;
    search_dataId = search_dataId || dtcard.dataId;
    var newPos = pmcard.dtcard.pos;

    //.does own page
    !skipOwn && dtcard.isTable && fmethods.cellBound__tpl_2_model8dom( pmcard );
    ///does other pages
    aModel.pgModels.forEach( ( pageModel, pageIx ) => {

        if( pmcard.pageIx !== pageIx ) {
            pageModel.pmcards.forEach( (pmc, cix) => {

                if( search_dataId === pmc.dtcard.dataId &&
                    pmc.dtcard.dsg_id === 'pages-header' ) {
                    ns.cleanpaste( pmc.dtcard.style8data_tpl, masterStyle );
                    pmc.dtcard.pos = ns.clonetree( newPos );
                    //ccc( 'pos has been changed: to ', pmc.dtcard.pos );
                    //will be done at table rebuilt: pmc.dtable.synchArchivedFont();
                    pmc.synchArchivedParams();
                    dtcardPos_2_cssModel({ pmcard:pmc }) //...moves card ...
                    fmethods.inlcss__doResizeCardFrames({ pmcard:pmc });
                    if( dtcard.isTable ) {
                        fmethods.cellBound__tpl_2_model8dom( pmc );
                    } else if( dtcard.isPicture ) {
                        pmc.cardDom$$.img$.src( aModel.dataURLs[ dtcard.tpl.imsrc ] )
                        pmc.dtcard.dataId = dtcard.dataId;
                    }
                }
            });
        }
    });
}


