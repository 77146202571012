

//**************************************************
// fills data-options with data
// for charts and rowbound-tables
//**************************************************


import moment from 'moment'

var ns = window.b$l;
var fconf = ns.fconf;
var fapp = ns.sn('fapp');
var fmethods = ns.sn('methods', fapp);
var currtpl = ns.sn('currtpl', fconf);
var ccc = console.log; ccc && (ccc = console.log);

fmethods.series_2_portraitTable = series_2_portraitTable;
fmethods.doFormatDataEl = doFormatDataEl;

export default generates_dataOptions

function generates_dataOptions({
    dataId,
    reports,
    dsg_ix,
    chartWidth,     // : this.state.width,
    chartHeight,    // : this.state.height,
    isTable,
    tpl,
}) {
    if (!reports){
        return
    }
    //API: reports = reports in format as from Network,
    if (ns.h('docbody', currtpl)) {
        ////if doc. tpl is already downloaded, then use it;
        var drepo = currtpl.docbody.repo;
    } else {
        ////if doc. tpl is not already downloaded, then use default from JS-code
        drepo = ns.fconf.default_empty_doc.docbody.repo;
    }

    //------------------------------------
    // //\\ selects template by card dataId
    //------------------------------------

    // DG stands for Data Group
    var chosenDG = null;
    // SG stands for Sub-Group
    var chosenSG = null;

    //todm ... not too complex?
    var dsg_ix_normal = dsg_ix || dsg_ix === 0 ? dsg_ix : null;
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence

    drepo.dataGroups.forEach(dG => {
        if (dG.dataId === dataId) {
            chosenDG = dG;
            ////todm ... not too complex?
            var dsg_ix_ = dsg_ix_normal !== null ?
                dsg_ix_normal : chosenDG.chosenIx;
            chosenSG = chosenDG.subGrs[dsg_ix_];
        }
    });
    if (!chosenSG) return;

    //preserves template for having data inside

    //dop - data options
    //and still "chart-options" for chart-containing-cards
    var dop = ns.paste({}, chosenSG.tpl);
    var series = dop.series;
    var isChart = chosenSG.isChart;

    //------------------------------------
    // \\// selects template by card dataId
    //------------------------------------


    //------------------------------------
    // //\\ does reverence series data-arrays
    //      prepares fieldName2column placeholder
    //------------------------------------
    var fieldName2column = {}; //"landscape table model"
    series.forEach((s, six) => {
        ////ASSUMPTION: this code assumes: different series s[six]
        ////must have different set of data_report_keys;
        //ccc( six + ' ' + JSON.stringify( s,null,'    ' ) );
        s.METAOPT_data_report_keys.forEach(drk => {
            //it is yet an empty array === "empty placeholder for column"
            var f2c = ns.sn(drk, fieldName2column, []);
            f2c[six] = s;
        });
    });
    //------------------------------------
    // \\// does reverence series data-arrays
    //------------------------------------

    //------------------------------------
    // //\\ constructs dataOptions
    //------------------------------------
    // //\\ chart-specific data-options
    if (!isTable) {
        dop.chart.width = chartWidth || dop.chart.width;
        dop.chart.height = chartHeight || dop.chart.height;
    }
    // \\// chart-specific data-options

    var sdate = [];
    var accomulatedDataPoint = [];
    var ACCUMULATED_DATA_POINT_LIMIT = 2;

    // Arrange reports chronologically
    var orderedReports = []
    for (let i = 0; i < reports.length; i++) {
        const report = reports[i];
        var reportDate = parseInt(moment(reports[i].data.STUDY_START).format('YYYYMMDD'))
        if (orderedReports.length < 1 && report.data) {
            orderedReports.push(report)
        } else {
            var isAssinged = false
            var volatileReports = []
            for (let ii = 0; ii < orderedReports.length; ii++) {
                const orderedReport = orderedReports[ii];
                var orderedReportDate = parseInt(moment(orderedReport.data.STUDY_START).format('YYYYMMDD'))
                if (reportDate <= orderedReportDate && isAssinged === false) {
                    volatileReports.push(report)
                    isAssinged = true;
                }
                volatileReports.push(orderedReport)
                if (ii === orderedReports.length - 1 && isAssinged === false) {
                    volatileReports.push(report)
                }
            }
            orderedReports = volatileReports
        }
    }
    reports = orderedReports

    reports && reports.forEach((reprow, key) => {
        if (reprow.data === null) return;
        var startTime = moment(reprow.startTime).format('MMM D, YY')
        series.forEach((serie, serieIx) => {
            accomulatedDataPoint[serieIx] = [];
        });

        //for( fname in reprow.data ) { //dangerous ... catches prototype props ...
        var pullingCounter = 0
        ns.eachprop(reprow.data, (prop, fname, propix) => {

            //skips fields non-specified in data-widget
            if (!ns.haz(fieldName2column, fname)) return;
            pullingCounter++;

            ///loops via all series which contain field with fname
            ///and contributes to series' data
            fieldName2column[fname].forEach((fserie, serieIx) => {

                var column = fserie.data;
                var double_point = fserie.METAOPT_double_point;

                //if( propix === 0 ) {
                if (pullingCounter === 1) {
                    if (!double_point) {

                        /*
                        //never runs
                        if( '' === sdate[ serieIx ] ) {
                            ////falls back to key for empty dates
                            sdate[ serieIx ] = '(' + key + ')';
                        } else {
                        */
                        sdate[serieIx] = startTime;
                        dop.xAxis.categories.push(sdate[serieIx]);
                        //ccc( chosenSG.id + ' xxxxxxxx not doubl p', sdate[ serieIx ] );
                    }
                }

                ////populates data of the chartOptions;
                ////data are referenced by fname, so
                ////only record which matches fname falls into data-array;
                var finalValue = reprow.data[fname];
                if (!fserie.METAOPT_time_format) {
                    finalValue = parseFloat(finalValue);
                }

                if (double_point) {
                    accomulatedDataPoint[serieIx].push(finalValue);
                    if (accomulatedDataPoint[serieIx].length ===
                        ACCUMULATED_DATA_POINT_LIMIT) {
                        ////this option generator is a patch ... it assumes that
                        //// in coming report row only two elements match fieldName2column
                        //// it is a db-server responsibility to have two matching values
                        column.push(accomulatedDataPoint[serieIx].concat([]));
                        accomulatedDataPoint[serieIx].length = 0;
                        dop.xAxis.categories.push(startTime);
                    }
                } else {
                    //ccc( chosenSG.id + ' non dob-point; finalValue=', finalValue );
                    finalValue = doFormatDataEl(finalValue, fserie, isChart);
                    column.push(finalValue);
                }
            });
        });
    });
    //------------------------------------
    // \\// constructs dataOptions
    //------------------------------------

    //------------------------------------------------
    // //\\ protects data-options against empty data
    //------------------------------------------------
    //      to avoid widgets to crash on empty data
    //      todm: possibly excessive code
    series = ns.sn('series', dop, []);

    ///just a patch ... supplies dummy series to not break the run-time
    if (series.length === 0) {
        series[0] = {
            type: "line",
            name: "Data Name is missed",
            data: [0],
        };
    }
    //.explicitly sets missed name
    ns.sn('name', series[0], "Data name is missed");
    //.makes sure data array does exist
    ns.sn('data', series[0], []);
    ///makes sure at least one point of data exists
    if (series[0].data.length === 0) {
        if (dataId === 'bd') {
            ////this "fixes" empty "bd" data ... just a hack
            series[0].data[0] = [1, 2];
        } else {
            series[0].data[0] = 0;
        }
    }

    //--------------------------------------------
    // //\\ extends short data arrays
    //      making them equal to all series
    //--------------------------------------------
    var maxDataLen = 1;
    series.forEach(ser => {
        if (ser.data.length > maxDataLen) {
            maxDataLen = ser.data.length;
        }
    });
    var DATA_NOT_AVAILABLE = '-';
    series.forEach(ser => {
        if (ser.data.length < maxDataLen) {
            for (var wwix = ser.data.length; wwix < maxDataLen; wwix++) {
                ser.data[wwix] = DATA_NOT_AVAILABLE;
            }
        }
    });
    //--------------------------------------------
    // \\// extends short data arrays
    // \\// protects data-options against empty data
    //------------------------------------------------


    //----------------------------------------------------
    // //\\ removes meta options to
    //      keep Highcharts input options clean
    //----------------------------------------------------
    series.forEach((s, six) => {
        if (!isTable) {
            delete s.METAOPT_data_report_keys;
            delete s.METAOPT_double_point;
            delete s.METAOPT_round_to_digits;
        }
    });
    //----------------------------------------------------
    // \\// removes meta options to
    //----------------------------------------------------
    adjustChartToExtremeValues(dop)
    return dop;
}

///returns { fieldNames, rows },
///        fieldNames can be empty;
function series_2_portraitTable(series) {
    ///series is a logical landscape of a data-set,
    ///data is a "pivot" for logical record dimension ("horizontal");
    ///looping through this dimension and returning tuples will
    ///build up sequence of records
    var rows = series[0].data.map((cell, horizontal_ix) =>
        ///creating a tuple
        series.map(
            ////vertical_ix loop
            ser => ser.data[horizontal_ix]
        )
    );

    ///if all names are "falsy", then fieldNames
    ///becomes an empty array
    var fieldNames = series.reduce(
        (acc, ser, ix) => {
            if (ser.name) {
                acc.push(ser.name);
            }
            return acc;
        },
        []
    );
    return { fieldNames, rows };
}

// Checks if the data range on y axis for a chart exceeds it's default value.
// If so, adjusts the chart range to fit
function adjustChartToExtremeValues(data) {
    if (!data || !data.yAxis) return
    let min = data.yAxis.min
    let max = data.yAxis.max
    if (data.title.text === 'Baseline Drift') {

    } else {
        for (let i = 0; i < data.series.length; i++) {
            const series = data.series[i];
            for (let j = 0; j < series.data.length; j++) {
                const dataPoint = series.data[j];
                if (dataPoint > max) {
                    max = Math.ceil(dataPoint / data.yAxis.tickInterval) * data.yAxis.tickInterval
                } else if (dataPoint < min) {
                    min = dataPoint
                }
            }
        }
    }
    if (min < data.yAxis.min) {
        data.yAxis.min = min
    }
    if (max > data.yAxis.max) {
        let stops = data.chart.plotBackgroundColor.stops
        for (let i = 0; i < stops.length; i++) {
            const stop = stops[i];
            let stopValue = stop[0] * data.yAxis.max
            let newStop = stopValue / max
            data.chart.plotBackgroundColor.stops[i][0] = newStop
            
        }
        data.yAxis.max = max
    }


}

function doFormatDataEl(value, serie, doNumberToNumber) {
    var round = serie.METAOPT_round_to_digits;
    var doRound = typeof round === 'number';
    round = doRound ? round : 0;

    var ret;
    if (!doNumberToNumber && serie.METAOPT_time_format) {

        if (serie.METAOPT_time_format === 'HH:MM:SS') {
            var ww = moment.duration(value);

            var hours = '0' + ww.days() * 24 + ww.hours();
            hours = hours.substring(hours.length - 2);

            var minutes = '0' + ww.minutes();
            minutes = minutes.substring(minutes.length - 2);

            var seconds = '0' + ww.seconds();
            seconds = seconds.substring(seconds.length - 2);

            ret = hours + ':' + minutes + ':' + seconds;
            //format( serie.METAOPT_time_format );
            //ccc( 'time duration='+value+ 'ms = ww='+ww );
        } else {

            //ccc( 'moment ARG=', value );
            ret = moment(value).format(serie.METAOPT_time_format);
            /*
            //good debug:
            c cc( 'ssssssssssssssssss ' + serie.name +
                 ' serie.METAOPT_time_format=', serie.METAOPT_time_format,
                 'value=', value, 'ret=', ret );
            */
        }
    } else {
        if (value === 0 && ns.fconf.allTables__ZERO_2_INTEGER) {
            ret = 0;
        } else {
            ret = value.toFixed(round);
        }
        if (doNumberToNumber) {
            ret = parseFloat(ret);
        }
    }
    return ret;
}


