//SpO2 (% of Time In Ranges)                                                        

export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: '>=90',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE90LTE100PCT',
                ]
            },
            {
                type: 'line',
                name: '80-89',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE80LT90PCT',
                ]
            },
            {
                type: 'line',
                name: '70-79',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE70LT80PCT',
                ]
            },
            {
                type: 'columnrange',
                name: '60-69',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE60LT70COUNT',
                ],
            },
            {
                type: 'columnrange',
                name: '50-59',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE50LT60COUNT',
                ],
            },
            {
                type: 'columnrange',
                name: '<50',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_LT50COUNT',
                ],
            },
        ],
    }
}


