
( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fconf       = ns.fconf;
    var currtpl     = ns.sn('currtpl', fconf);
    var fmethods    = sn('methods',fapp);
    var d8d_p       = sn('d8d-point',fmethods);
    var aModel      = sn('aModel', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.initRepoDragModel = initRepoDragModel;
    return;








    //todo add function to clean up touch and click events when
    //page deleted

    //==========================================
    // //\\ inits drag points
    //==========================================
    function initRepoDragModel( dragSurface$ )
    {
        var d8dFramework = d8d_p.createFramework({
            dragSurface : dragSurface$(),
            findDraggee : findDraggee,
        });
        aModel.repoThumbMasks.forEach( (thumbMask,mix) => {
            createDragger( thumbMask,mix );
        });
        return d8dFramework;








        //==========================================
        // //\\ creates mask dragger
        //==========================================
        // //\\ creates wrap over draggee
        //------------------------------------------
        function createDragger( thumbMask, mix )
        {
            //--------------------------------------------
            // //\\ virtual point
            //      which we pretend to drag
            //--------------------------------------------
            var pointWrap = {
                hand$       : thumbMask.dom$,
                widgetRack  : thumbMask, //rack contains all the mask goodies and props ...
                makeCentralDiskInvisible : true,
            }
            //pointWrap.orientation = orientation; //patch as pointWrap itself ...
            //----------------------------
            // \\// virtual point
            //----------------------------

            //----------------------------
            // //\\ wrapping drag
            //----------------------------
            /*
            if(
                thumbMask.dsg_id === 'spo2_events'
            )
                //ccc( 'inti d8d top=', thumbMask.dragsurf2mask[1].toFixed()
            */
            d8dFramework.pointWrap_2_dragWrap({
                pointWrap           : pointWrap,
                doProcess           : doProcess,
                orientation         : 'rotate',
                dragHandleDOM       : pointWrap.hand$(),
                no_spinner          : true,
                //************************************************
                //Vital. Keeps start pos. Mouse wrappers return
                //       accomulated move only.
                //------------------------------------------------
                //Must clone to preserve itself during drag
                achieved            : ns.paste( [], thumbMask.dragsurf2mask ),
                //************************************************
            });
            //----------------------------
            // \\// wrapping drag
            //----------------------------
        }
        //------------------------------------------
        // \\// creates wrap over draggee
        //------------------------------------------

        //------------------------------------------
        // //\\ does process mask drag
        //------------------------------------------
        function doProcess( arg )
        {
            var dragWrap    = arg.dragWrap;
            var pointWrap   = dragWrap.pointWrap;
            var widgetRack  = pointWrap.widgetRack;
            var mask        = widgetRack;
            var sur2mask    = widgetRack.dragsurf2mask;
            var achieved    = dragWrap.achieved;
            var surBox      = mask.dragSurface$.box();

            switch( arg.down_move_up ) {
                case 'up':
                        //stashing where we are
                        var sur2mask_x = sur2mask[0];
                        var sur2mask_y = sur2mask[1];

                        //returns back
                        sur2mask[0] = achieved[0];
                        sur2mask[1] = achieved[1];
                        widgetRack.dom$.css( 'left', sur2mask[0]+'px' );
                        widgetRack.dom$.css( 'top', sur2mask[1]+'px' );
                        pointWrap.hand$.css( 'opacity', '0' );

                        //=====================================================
                        // //\\ inserting new card
                        //=====================================================
                        // //\\ detecting page closest to mask
                        //-----------------------------------------------------
                        var selectedPageModel = null;
                        aModel.pgModels.forEach( pgModel => {
                            if( selectedPageModel !== null ) return; //found
                            var p$ = pgModel.dom$$.editorPage$;
                            var pageBox = p$.box();
                            var x = sur2mask_x - (pageBox.left - surBox.left);
                            var y = sur2mask_y - (pageBox.top - surBox.top);
                            /*
                            ccc( 'x='+x + ' y=' + y + ' pageBox.left=' +
                                 pageBox.left + ' pageBox.top='+pageBox.top +
                                 'pageBox.width=' + pageBox.width +
                                 ' pageBox.height=' + pageBox.height );
                            */
                            if(
                                x > 0 && x < pageBox.width &&
                                y > 0 && y < pageBox.height
                            ){
                                selectedPageModel = { pgModel, x, y };
                                //ccc( ' selectedModel=', selectedPageModel );
                            }
                        });
                        if( selectedPageModel === null ) return; //not found

                        var wws     = fapp.fheap.editedPageScale;
                        var ps      = currtpl.docbody.pstyle;
                        var cpos =
                        [
                            //we will paste only offsets, not the dimensions,
                            //dimensions come from card template, so
                            //we set only two elements (offsets) for this array of four:
                            Math.floor( selectedPageModel.x / wws / ps.PAGE_WIDTH * 100 ),
                            Math.floor( selectedPageModel.y / wws / ps.PAGE_WIDTH * 100 ),
                        ];
                        //-----------------------------------------------------
                        // \\// detecting page closest to mask
                        //-----------------------------------------------------
                        var datSGr = mask.datSGr;
                        if( mask.dsg_id === "pages-header" ) {
                            fmethods.removeHeadersFromPage({ dataId:mask.dataId, dsg_id:mask.dsg_id });
                            aModel.pgModels.forEach( pageModel => {
                                fmethods.addCardToApp({
                                    pageModel,
                                    datSGr,
                                    cpos,
                                });
                            });
                        } else {
                            fmethods.addCardToApp({
                                pageModel : selectedPageModel.pgModel,
                                datSGr,
                                cpos,
                            });
                        }
                        fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
                        //=====================================================
                        // \\// inserting new card
                        //=====================================================
                        break;

                case 'move':
                        pointWrap.hand$.css( 'opacity', '0.7' );
                        sur2mask[0] = achieved[0] + arg.surfMove[0];
                        sur2mask[1] = achieved[1] + arg.surfMove[1];
                        widgetRack.dom$.css( 'left', sur2mask[0]+'px' );
                        widgetRack.dom$.css( 'top', sur2mask[1]+'px' );

                        if(
                            pointWrap.widgetRack.dsg_id === 'spo2_events   '

                        )
                            ccc(
                                pointWrap.widgetRack.dsg_id + 
                                ' moving to =' + sur2mask[1].toFixed(),
                            );

                        break;
                default:
            }
        }
        //------------------------------------------
        // \\// does process mask drag
        // \\// creates mask dragger
        //==========================================



        //====================
        // //\\ finds draggee
        //====================
        ///Returns: point drag Wrap
        ///         which is closest to testPoint.
        ///Algo:    if distance to point_on_dragSurf is "outside" of this par.,
        ///         then dragWrap is not "considered" for drag
        function findDraggee( point_on_dragSurf, dragWraps )
        {
            var testMediaX = point_on_dragSurf[0];
            var testMediaY = point_on_dragSurf[1];

            //------------------------------------
            // //\\ loops and find closest item
            //------------------------------------
            var closestDragWrap = null;
            var closestTd = null;
            //.the bigger is priority, the more "choicable"
            //.is the drag Wrap point
            var closestDragPriority = 0;
            //ccc( 'click='+testMediaX.toFixed(2)+','+ testMediaY.toFixed(2) +
            //     ' dragWraps=', dragWraps);

            var dragSurface = dragSurface$();
            var dragSurfBox = dragSurface.getBoundingClientRect();

            dragWraps.forEach( function( dragWrap, dix ) {
                var pointWrap   = dragWrap.pointWrap;
                var h_box       = dragWrap.dragHandleDOM.getBoundingClientRect();
                var hpos = {
                    left : h_box.left - dragSurfBox.left,
                    top : h_box.top - dragSurfBox.top,
                };
                hpos.right   = hpos.left + h_box.width;
                hpos.bottom  = hpos.top + h_box.height;
                hpos.centerX = hpos.left + h_box.width/2;
                hpos.centerY = hpos.top + h_box.height/2;

                var distanceToCenter_x = Math.abs( testMediaX - hpos.centerX );
                var distanceToCenter_y = Math.abs( testMediaY - hpos.centerY );
                //max axial distance to the center of handle
                var maxDistance       = Math.max( distanceToCenter_x, distanceToCenter_y );
                //if( pointWrap.widgetRack.tableId === '0-0' )

                if(
                    hpos.left <= testMediaX && hpos.right >= testMediaX &&
                    hpos.top <= testMediaY && hpos.bottom >= testMediaY
                ) {
                    //ccc( 'here: maxDistance='+maxDistance );
                    if( !closestDragWrap || closestTd > maxDistance ||
                        (pointWrap.dragPriority || 0 ) > closestDragPriority ) {
                        //if( pointWrap.widgetRack.tableId === '0-0' )
                        //ccc( 'closestDragWrap=',dragWrap );
                        closestDragWrap = dragWrap;
                        closestTd = maxDistance;
                        closestDragPriority = pointWrap.dragPriority || 0;
                   }
                }
            });
            //------------------------------------
            // \\// loops and find closest item
            //------------------------------------
            return closestDragWrap;
        }
        //====================
        // \\// finds draggee
        //====================


    };
    //==========================================
    // \\// inits drag points
    //==========================================

}) ();

