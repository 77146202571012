import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            currentLimitedData: [],
        };
        this.limitData = this.limitData.bind(this);
        this.renderPageItems = this.renderPageItems.bind(this);
        this.renderPageItem = this.renderPageItem.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    componentDidMount() {
        this.limitData();
    }

    componentDidUpdate(prevProps, prevState) {
        const propsDataChanged = prevProps.data !== this.props.data;
        const pageChanged = prevState.page !== this.state.page;

        if (propsDataChanged || pageChanged) {
            this.limitData();
        }
    }

    limitData() {
        const { data, limit, render } = this.props;
        const { page } = this.state;
        const offset = (page - 1) * limit;
        const limitedData = data.slice(offset, offset + limit);

        console.log('Paginator.limitData called');
        console.log('Page:', page);
        console.log('Data:', limitedData);

        const isDataChanged = JSON.stringify(this.state.currentLimitedData) !== JSON.stringify(limitedData);

        if (isDataChanged) {
            this.setState({ currentLimitedData: limitedData }, () => {
                render(limitedData);
            });
        }
    }

    getNearbyPagesInRange(range) {
        const count = this.props.data.length;
        const currentPage = this.state.page;
        const maxPages = Math.ceil(count / this.props.limit);
        let min = Math.max(1, currentPage - range);
        let max = Math.min(maxPages, currentPage + range);

        return {
            min,
            max,
        };
    }

    renderPageItems() {
        const range = this.getNearbyPagesInRange(2);
        const pages = [];
        const currentPage = this.state.page;

        for (let i = range.min; i <= range.max; i++) {
            if (i > 0) {
                const isActive = i === currentPage;
                pages.push(this.renderPageItem(i, isActive));
            }
        }
        return pages;
    }

    renderPageItem(num, isActive) {
        const active = isActive ? 'active' : '';
        return (
            <li key={num} className={'page-item ' + active}>
                <button id={num} className='page-link' onClick={this.setPage}>{num}</button>
            </li>
        );
    }

    handlePrevious() {
        this.setState((prevState) => ({
            page: Math.max(prevState.page - 1, 1),
        }));
    }

    handleNext() {
        const count = this.props.data.length;
        const maxPages = Math.ceil(count / this.props.limit);
        this.setState((prevState) => ({
            page: Math.min(prevState.page + 1, maxPages),
        }));
    }

    setPage(event) {
        const page = parseInt(event.target.id, 10);
        this.setState({
            page,
        });
    }

    render() {
        if (this.props.data.length === 0) return (<ul></ul>);
        return (
            <ul className='pagination justify-content-center'>
                <li className='page-item' onClick={this.handlePrevious}>
                    <button className='page-link'>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                </li>
                {this.renderPageItems()}
                <li className='page-item'>
                    <button className='page-link' onClick={this.handleNext}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                </li>
            </ul>
        );
    }
}

export default Paginator;
