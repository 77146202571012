//keep care about this rule:
//this actually must be first among window.b$l modules application module to import:
//it loads ns/b$l library as well:
import Dashboard from '../report-composer/Dashboard.js';

import React, {Component} from 'react'
import $ from 'jquery'

//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var fapp        = ns.sn('fapp' );
var fmethods    = ns.sn('methods', fapp);
var fconf       = ns.fconf;
var currtpl     = ns.sn('currtpl', fconf);
var fheap       = ns.sn('fheap', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

//purpose: if multiNightReport.id changes, then pat. and rep. data do retrieve again
var multiNightReport = {};














//========================================================
// //\\ HTML-PDF and PDF twin proecessors
//========================================================
///PDF works
///this functin is ran only for non-admin user:
function CompareReports2PDF( props )
{
    ccc( 'skips dashb., loads docs, then calls-back mult.night.dat. ')
    ccc(props)
    ajax_tpl_download( function() { check_and_getMultinightData( props ); } );
}




///HTML and PDF works
class CompareReports extends Component {

	constructor(props){
		super(props)
        console.log( '###### constructs <CompareReports/>, props=', props );
        //starts everything over except downloaded doc templates:
        fheap.fullAjaxData = null;
        fheap.data_rows = null;
	}

    componentDidMount(){
        ccc( '<CompareReports/> did mount ... ' );
        if( !fconf.ENABLE_REP_EDITOR ) {
            ///restoring "lost" admin role flag,
            //todm ... not sure why ENABLE_REP_EDITOR can be lost
            var adm = localStorage.getItem('systemAdmin' );
            if( adm +'' === '1' ) {
                fconf.ENABLE_REP_EDITOR = adm +'' === '1';
                //ccc( 'lost admin role is restored ...' );
            } else {
                ccc( 'WARNING: non-admin, but editor-GUI loads ... ' );
            }
        }
        ajax_tpl_download( this.forceUpdate.bind( this ) );
    }
    
    componentDidUpdate(prevProps, prevState)
    {
        //ccc( 'comp-reps is updated: checking multiNightReport.id changes ...' );
        check_and_getMultinightData( this.props, this );
    }



    render() {
        var currKeys = Object.keys( currtpl );
        var doRenderDashboard = currKeys.length && fheap.data_rows;
        //ccc( doRenderDashboard ? 'rendering Dashb' : 'rendering Dashb empty pane' );
        return (
            doRenderDashboard ?
                (
                    <Dashboard
                        organization={this.props.organization}
                        location={this.props.location}
                    />
                ) :
                ///waits till template and data arrived
                (
                    <div className="download-doc-spinner-parent">
                        <div className="download-doc-spinner">
                            <i title="loading document"
                               className="fa fa-spinner fa-pulse fa-3x fa-fw"></i><br/><br/>
                            downloading document
                        </div>
                    </div>
                )
        )
    }
}

export const CompareReports_2_PDF = CompareReports2PDF
export default CompareReports
//========================================================
// \\// HTML-PDF and PDF twin proecessors
//========================================================
















//========================================================
// //\\ db-downloaders
//========================================================
///downloads doc-template
function ajax_tpl_download( continue_with_data_load )
{
    if( fheap.tplDownloadState === 'non-started' ) {
        fapp.ajax_tpl_download( () => {
            //c cc( 'tpl downloaded ... going to continue_with_data_load()' );
            continue_with_data_load();
        });
    } else {
        continue_with_data_load();
    }
}

function check_and_getMultinightData( props, that )
{
    if( window.DEVELOPER_MODE__IS__ON ) {
        ////assumes there will be no more "componentDidUpdate"
        fmethods.getDevModeReports({ organization:{}, location_match_params:{} });
        return;
    }

    //ccc( 'check_and_getMultinightData() begins; props=', props, ' that=', that );
    // //\\ the multiId must be changed to intialte data updates
    var multiId = parseInt(props.location.match.params.id);
    if( multiId !== multiNightReport.id ) {
        multiNightReport.id = multiId;
        //ccc( 'multiId changed ... ajaxing pats and reps ... ' );
        //ccc( 'this.props.location.location.pathname=',
        //     this.props.location.location.pathname );
        if( window.DEVELOPER_MODE__IS__ON ) return;
        $.ajax({
		    method: 'GET',
		    url: props.location.location.pathname
	    }).done( data => {
            ccc( 'multiId='+ multiId + '. ajax-delivered-pats-and-reps = ', data );
            fheap.fullAjaxData = data;
            fheap.data_rows = data.reports;

            if( fconf.ENABLE_REP_EDITOR ) {
                ////this time, non-empty-Dashboard-control will be constructed or updated
                /*
                ccc( '### comp..rep.. rebuilds ... in GUI: patient=',
                     fheap.fullAjaxData.patient.id + ' ' +
                     fheap.fullAjaxData.patient.lastName );
                */
                that.forceUpdate.bind( that )();
            } else {
                ccc( '### comp..rep.. rebuilds ... no GUI: patient=',
                     fheap.fullAjaxData.patient.id + ' ' +
                     fheap.fullAjaxData.patient.lastName );
                ////emulates models of Dashboard ( which is never called in this mode )
                fmethods.runs_DashboardIsMount_tasks();
                //todm: bounced is not required ...
                //fmethods.docModel_8_pagesCardsInterns(); //_bounced();
            }
        });
    // \\// the multiId must be changed to intialte data updates

    } else if( !fconf.ENABLE_REP_EDITOR && ns.haz( multiNightReport, 'id' ) ) {
        ////this is a repeated "multiNightReport.id" case:
        ////pdf has been already run and user wants to run it again;
        ////todo clarify will pdfmake work reliably in repeated case ...
        ccc( 'repeats pdf download' );

        ////we are assuming that this is the same patient as before and
        ////don't rebuild document contents

        ns.methods.pdfmake8pdfdoc__lifecycle({ pdfTask:'download' });

        //todo ... proliferation
        //ns.haf( ns.methods, 'enableButton' )( ns.nheap.previewButton$, true );
    }
}
//========================================================
// \\// db-downloaders
//========================================================




