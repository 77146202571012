import React, {Component} from 'react'
import zxcvbn from 'zxcvbn'

class PasswordStrength extends Component {

	createPasswordLabel(result){
	switch (result.score) {
	  case 0:
		return 'Weak'
	  case 1:
		return 'Weak'
	  case 2:
		return 'Moderate'
	  case 3:
		return 'Strong'
	  case 4:
		return 'Very Strong'
	  default:
		return 'Weak'
	}
  }

  createPasswordColor(result){
	switch (result.score) {
	  case 0:
		return 'bg-danger'
	  case 1:
		return 'bg-danger'
	  case 2:
		return 'bg-warning'
	  case 3:
		return 'bg-success'
	  case 4:
		return 'bg-success'
	  default:
		return 'bg-danger'
	}
  }

	render() {
	const { password } = this.props
	const strength = zxcvbn(password)
	if (password === ''){
	  return(
		<div></div>
	  )
	}
	var label = this.createPasswordLabel(strength)
	var color = this.createPasswordColor(strength)
	var percent = (strength.score / 4) * 100
	var style = {
		width: percent + '%'
	}
	return (
		<div className='col-3'>
			<div className='progress'>
				<div className={ 'progress-bar ' + color }
					style={style}
					role='progressbar'
					aria-valuenow={percent}
					aria-valuemin='0'
					aria-valuemax='100'>
			  	</div>
			</div>
			<label>
		  		<strong>Password Strength : </strong>{label}
			</label>
		</div>
	)
  } 

}

export default PasswordStrength