import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faTrash} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import $ from 'jquery'

import fconf from '../report-composer/conf.js';
import { CompareReports_2_PDF } from '../report-composer/compare-reports'

class MultiReportListItem extends Component {

	constructor(props){
		super(props)
		this.state = {
			patient: {},
			analysis: null,
			pdf: null,
			pdfGenerating: false,
			analysisGenerating: false,
			interpreting: false
		}
	}


	deleteReport(){
		var url = '/multi-night-reports/' + this.props.data.id
		$.ajax({
			url: url,
			method: 'DELETE',
			dataType: 'json'
		}).done(this.props.removeHandler)
	}

	navigateToView(){

        var adm = localStorage.getItem('systemAdmin' )
        fconf.ENABLE_REP_EDITOR = adm +'' === '1';

		if( fconf.ENABLE_REP_EDITOR ) {
    		window.location.hash = '/multi-night-reports/' + this.props.data.id
    	} else {
            CompareReports_2_PDF({
                location : {
                    location : {
                        pathname: "/multi-night-reports/" + this.props.data.id,
                    },
                    match :
                    {
                        params :
                        {
                            id : this.props.data.id,
                        }
                    }
                },
                organization : { id : this.props.data.organizationId },
            });
        }
	}

	renderNightIncluded(data,index){
		var reports = this.props.reports.rows
		const multiIds = this.props.data.containedReportIds
		var date = ''

		for (let i = 0; i < multiIds.length; i++) {
			const id = multiIds[i];
			for (let j = 0; j < reports.length; j++) {
				const report = reports[j];
				if (id === report.id){
					if (date !== ''){
						date = date + ', ' + moment(report.startTime).format('MMMM DD YYYY')
					} else {
						date = date + moment(report.startTime).format('MMMM DD YYYY')
					}
				}
			}
		}
		return (
			<div key={index} className='row'>
				<div className='col'>
					<span className='text-center'>{date}</span>
				</div>
			</div>
			
		)
	}

 	render(){
		var navigateToView = this.navigateToView.bind(this)
		var deleteReport = this.deleteReport.bind(this)
		var studyDates = ''

		if (this.props.reports.rows){
			studyDates = this.renderNightIncluded()
		}

 		return (
			<li className='list-group-item border border-muted rounded'>
				<div className='row'>
					{/* Date Field */}
					<div className='col-6 '>
						<div className='row '>
							<div className='col' align='center'>
								{studyDates}
							</div>
						</div>
					</div>
					<div className='col-2 '>
						<div className='row '>
							<div className='col' align='center'>
								{moment(this.props.data.createdAt).format('MMMM DD, YYYY')}
							</div>
						</div>
					</div>
					<div className='col'>
						<div className='row float-right' >
							<div className='col-2 btn-group btn-group-sm' role='group'>
								<button className='btn btn-danger'
								onClick={navigateToView}>
									<FontAwesomeIcon className='fa-fw' icon={faFilePdf}/>
								</button>
								<button className='btn btn-secondary'
								onClick={deleteReport}>
									<FontAwesomeIcon className='fa-fw' icon={faTrash}/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</li>
		)
 	}

}

export default MultiReportListItem
