
( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var methods     = ns.methods        = ns.methods        || {};
    var fconf       = ns.fconf;
    var fapp        = ns.sn('fapp' );
    var aModel      = ns.sn('aModel', fapp);
    var nheap       = ns.nheap          = ns.nheap          || {};
    var ddCont      = nheap.ddCont      = nheap.ddCont      || [];
    var ccc         = console.log; ccc && ( ccc = console.log );
    methods.addCardForPDFExport = addCardForPDFExport;
    return;







    //Does:     places content into ddCont directly.
    function addCardForPDFExport({ pmcard, pgModel })
    {
        var innerBodyCell =
        {
            ///pdfmake fits by the minimal dimension: takes aspect-rationed image fitting
            ///both max and min constrains of this fit-array
            absolutePosition : {
              x: pmcard.tplLeft_px,
              y: pmcard.tplTop_px,
            }
        };

        if( pmcard.dataGr.isChart ) {

            innerBodyCell.fit =
            [
                pmcard.tplWidth_px,
                pmcard.tplHight_px,
            ];

            if( fconf.IN_SVG_EDITOR ) {
                var contCharts  = nheap.contCharts;
                contCharts.push({
                    widgetDescription : 'Chart ' + contCharts.length,
                    ddContRack  : innerBodyCell,
                    options : ns.paste( {}, pmcard.cdataOptions ),
                });
            }
            ddCont.push( innerBodyCell );

        } else if( pmcard.dataGr.isPicture ) {
            innerBodyCell.fit =
            [
                pmcard.tplWidth_px,
                pmcard.tplHight_px,
            ];
            innerBodyCell.image = aModel.dataURLs[ pmcard.dtcard.tpl.imsrc ];
            ddCont.push( innerBodyCell );

        } else if( pmcard.dataGr.isTable ) {
            fapp.build_cardTable_inPDF({ pmcard });
        }
        //------------------------------------------------------------------
        // \\// places content into placeholder
        //------------------------------------------------------------------
        //ddCont.push( { text : pmcard.cardIx + '' } );
    }

}) ();

