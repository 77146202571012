
var dataOptions =
{
    title: {
        text: 'Cycling Severity'
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    chart: {
        width: 500,
        height: 500,
        plotBackgroundColor: {
            linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
            stops: [
                [0, '#0e8204'], //green
                [.25, '#fff700'], //yellow
                [.35, '#fc5000'], //red
                [.5, '#ba0202'], //dark red
                
                
                
            ]
        },
    },
    yAxis: {
        title: {
            text: 'Severity'
        },
        min: 0,
        max: 100,
        tickInterval: 25,
        gridLineColor: 'black',
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '15px'
                }
            }
        }
    },
    credits: {
        enabled: false
    },
};

export default dataOptions;

