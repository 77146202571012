import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

class PatientSearch extends Component {



	render(){
		return (
			<form onSubmit={this.props.onSubmit}>
				<div className='form-row align-items-end'>
					<div className='form-group col'>
						<label>First Name</label>
						<input className='form-control' 
							name='firstName' />
					</div>
					<div className='form-group col'>
						<label>Last Name</label>
						<input className='form-control' 
							name='lastName' />
					</div>
					<div className='form-group col'>
						<button className='btn btn-outline-primary' type='submit'>
							<FontAwesomeIcon icon={faSearch}/>
						</button>
					</div>
				</div>
			</form>
		)
	}

}

export default PatientSearch