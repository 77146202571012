
export default function ( HRM, pstyle ) {

    return  {
        xAxis: {
            categories: [], // dates
        },
        series: [
            {
                type: 'columnrange',
                name: 'High Baseline - Low Baseline',
                data: [], // data
                METAOPT_round_to_digits : 1,
                METAOPT_double_point : true,
                METAOPT_data_report_keys :
                [
                    'SPO2_BASELINE_HIGH',
                    'SPO2_BASELINE_LOW',
                ],
            },
        ],
    }
}




