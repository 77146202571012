import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSave, faBan } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import Alert from './alert'

class PaymentListItem extends Component {

	constructor(props){
		super(props)
		this.state = {
			editing: false,
			months: [],
			years: [],
			card: {
				exp_month: this.props.data.exp_month,
				exp_year: this.props.data.exp_year,
				name: this.props.data.name
			},
			alertType: 'alert-success',
			alertMessage: ''
		}
	}

	componentDidMount(){
		this.setDates()
	}
    
    toggleEditing(){
        this.setState({
            editing: !this.state.editing
        })
	}
	
	initAlertMessage(message, type){
		this.setState({
			alertMessage: message,
			alertType: type
		})
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

    handleChange(event){
		var data = this.state.card
		var el = event.target
		if (el.name === 'exp_month' || el.name === 'exp_year'){
			data[el.name] = parseInt(el.value)
		} else {
			data[el.name] = el.value
		}
		this.setState({ card: data })
    }

	setDates(){
		var months = []
		var years = []
		for (var i = 1; i < 13; i++){
			months.push(i)
		}
		var year = new Date()
		for (var ii = 0; ii < 21; ii++){
			years.push(year.getFullYear() + ii)
		}
		this.setState({
			months: months,
			years: years
		})
	}

	delete(){
		var url = '/organizations/' + this.props.organization.id + '/cards/' + this.props.data.id
		var success = this.handleUpdate.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'DELETE',
			url: url,
			data: this.state.card
		}).done(success).fail(failure)
	}

	saveUpdate(){
		var url = '/organizations/' + this.props.organization.id + '/cards/' + this.props.data.id
		var success = this.handleUpdate.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'PUT',
			url: url,
			data: this.state.card
		}).done(success).fail(failure)
	}
	
	errorHandler(a,b,c){
		var message = 'Credit Card info is invalid.'
		this.initAlertMessage(message, 'alert-danger')
	}

	handleUpdate(data){
		this.props.payment.getCards()
		this.props.payment.props.subscriptions.getSubscriptions()
		this.toggleEditing()
	}

	renderMonths(month){
		return (
			<option key={month}>{month}</option>
		)
	}

	renderYears(year){
		return (
			<option key={year}>{year}</option>
		)
	}

 	render(){
        var data = this.props.data
		var name = this.state.card.name ? this.state.card.name : ''
		var months = this.state.months.map(this.renderMonths.bind(this))
		var years = this.state.years.map(this.renderYears.bind(this))
		var removeHandler = this.removeAlertHandler.bind(this)

        if (!this.state.editing){
            return (
                <li className={`list-group-item border border-dark rounded`} style={{padding: 2}} key={this.props.index} >
                    <div className='row'>
                        <div className='col text-center mt-2'>
                            <span className='font-weight-bold'>{data.brand} </span>
                            <span>ending in {data.last4}</span>
                        </div>
                        <div className='col text-center mt-2'>
                            <span>{name}</span>
                        </div>
                        <div className='col text-center mt-2'>
                            <span>{this.state.card.exp_month}/{this.state.card.exp_year}</span>
                        </div>
                        <div className='col text-center'>
							<button className='btn btn-outline-primary'
							type='button' 
							onClick={ this.toggleEditing.bind(this) }>
							<FontAwesomeIcon icon={ faPen } />
							</button>
                        </div>
                        
                    </div>
                </li>
            )
        } else {
            return (
                <li className={`list-group-item border border-dark rounded`} style={{padding: 2}} key={this.props.index} >
					<form>
						<div className='row'>
							<div className='col text-center mt-2'>
								<span className='font-weight-bold'>{data.brand} </span>
								<span>ending in {data.last4}</span>
							</div>
							<div className='col text-center'>
							<input type="text" className="form-control"
							value={name}
							onChange={this.handleChange.bind(this)}
							name='name'/>
							</div>
							<div className='col text-center'>
								<div className='row'>
									<div className='col-2'></div>
								<div className='col-4' style={{padding: 0}}>
									<select className="custom-select mr-sm-2"
									defaultValue={this.state.card.exp_month}
									onChange={this.handleChange.bind(this)}
									name='exp_month'>
										{months}
									</select>
								</div>
								<div className='col-4' style={{padding: 0}}>
									<select className="custom-select mr-sm-2"
									defaultValue={this.state.card.exp_year}
									onChange={this.handleChange.bind(this)}
									name='exp_year'>
										{years}
									</select>
								</div>
								</div>
							</div>
							<div className='col text-center'>
								<button className='btn btn-outline-success'
								type='button'
								onClick={ this.saveUpdate.bind(this) }
								>
								<FontAwesomeIcon icon={ faSave } />
								</button>
								<button className='btn btn-outline-primary'
								type='button' 
								onClick={ this.toggleEditing.bind(this) }>
								<FontAwesomeIcon icon={ faBan } />
								</button>
								<button className='btn btn-outline-danger'
								type='button' 
								onClick={ this.delete.bind(this) }>
								<FontAwesomeIcon icon={ faTrash } />
								</button>
								
							</div>
						</div>
					</form>
					<Alert message={this.state.alertMessage} type={this.state.alertType } onExit={removeHandler}/>
                </li>
            )
        }
 	}
}

export default PaymentListItem
