import React, { Component } from 'react';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../stylesheets/app.css';

import Login from './login';
import ProxyLogin from './proxy-login';
import Signup from './signup';
import Forgot from './forgot';
import Logout from './logout';
import ResetPassword from './reset-password';

import Navbar from './navbar';
import Sidebar from './sidebar';
import Organization from './organization';
import Patients from './patients';
import Patient from './patient';
import Reports from './reports';
import Profile from './profile';
import User from './user';
import Report from './report';
import Connections from './connections';
import CompareReports from '../report-composer/compare-reports';
import VideoTours from './video-tours';
import Settings from './settings';

import Payment from './payment';
import Members from './members';
import Subscriptions from './subscriptions';
import Physicians from './physicians';

import Analysis from '../SleepScreen/Screens/AnalysisScreen';
import Charts from './Chart/Charts';

import Test from './test';
import Modal from 'react-modal';

import ns from '../report-composer/bsl/core/ns.js';

Modal.setAppElement('#root');

class App extends Component {
  constructor(props) {
    super(props);

    var userId = parseInt(localStorage.getItem('userId')) || 0;
    var organizationId = parseInt(localStorage.getItem('organizationId')) || 0;
    this.state = {
      user: {
        id: userId,
      },
      organization: { id: organizationId },
      organizations: [],
      isAdmin: false,
    };
    //this.loginCheckFrequency = 1000 * 60 * 60 * 30;
    this.getUser = this.getUser.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    // if (this.state.user.id) {
    //   this.getUserInterval = setInterval(this.getUser, this.loginCheckFrequency);
    // }
    if (this.state.organization.id) {
      this.getOrganizations();
    }
  }


  componentDidMount() {
    this.getUser();
  }

  // Sends a GET request to /login which returns a user if user is logged in, or an empty object if not
  getUser() {
    var success = this.handleUser.bind(this);
    $.ajax({
      method: 'GET',
      url: '/login',
    }).done(success);
  }

  // If the current state for user.id is not the same as the data's user.id, then set the new state as the data's id, and add to localStorage
  handleUser(data) {
    var userStateChanged = this.state.user.id !== data.id;
    if (userStateChanged || !this.state.user.firstName) {
      if (data.id) {
        //this.getUserInterval = setInterval(this.getUser, this.loginCheckFrequency);
        this.setState({ user: data }, this.getOrganizations);
        localStorage.setItem('username', data.username);
        localStorage.setItem('email', data.email);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('systemAdmin', data.systemAdmin);
        //ns.fconf.ENABLE_REP_EDITOR = data.systemAdmin+'' === '1';
      } else {
        clearInterval(this.getUserInterval);
        this.setState({
          user: {
            id: null,
          },
        });
        localStorage.removeItem('userId');
        localStorage.removeItem('systemAdmin');
        //ns.fconf.ENABLE_REP_EDITOR = false;
      }
    }
    //console.log( 'data.systemAdmin='+ data.systemAdmin );
    //ns.fconf.ENABLE_REP_EDITOR = data.systemAdmin+'' === '1';
  }

  // Uses the user's cookie to get all associated organizations
  getOrganizations() {
    var success = this.handleOrganizations.bind(this);
    $.get('/organizations').done(success);
  }

  // Adds all associated organizations to state as an array
  handleOrganizations(data) {
    var success = this.getOrganization.bind(this);
    this.setState(
      {
        organizations: data.rows,
      },
      success,
    );
  }

  getOrganization() {
    var id = this.state.organization.id;
    let organizationMatch = false;

    // Compares locally stored organization id to each organization associated with the user to find a match.
    this.state.organizations.forEach((org) => {
      if (org.id === id) {
        organizationMatch = true;
      }
    });
    // If there is no locally stored id, or that id does not match any of the user's organizations, then set the user's active org to the first org in their organizations array
    // Or if they have no organization, set their org state id to 0
    if (!id || !organizationMatch) {
      var firstOrg = this.state.organizations[0];
      if (firstOrg) {
        id = firstOrg.id;
      } else {
        this.setState({
          organization: { id: 0 },
        });
        return;
      }
    }
    var url = '/organizations/' + id;
    var success = this.handleOrganization.bind(this);
    $.get(url).done(success);
  }

  handleOrganization(data) {
    if (this.state.organizationId !== data.id) {
      localStorage.removeItem('organizationId');
      localStorage.setItem('organizationId', data.id);
    }
    var success = this.getMembers.bind(this);
    this.setState({ organization: data }, success);
  }

  getMembers() {
    var success = this.handleMembers.bind(this);
    $.ajax({
      url: '/members',
      data: {
        userId: this.state.user.id,
        organizationId: this.state.organization.id,
      },
    }).done(success);
  }

  handleMembers(data) {
    var members = data.rows;
    var userId = this.state.user.id;
    var membership = members.find(function (member) {
      return member.userId === userId;
    });
    if (!membership) return;
    var isAdmin = membership.roleId === 1;
    this.setState({
      isAdmin: isAdmin,
    });
  }

  ensureActiveOrganization() {
    var activeOrg = this.state.organization;
    var matchingOrg =
      this.state.organizations.find(function (org) {
        return activeOrg.id === org.id;
      }) ||
      this.state.organizations[0] ||
      {};
    if (matchingOrg.id && matchingOrg.id !== activeOrg.id) {
      this.handleOrganization(matchingOrg);
    }
  }

  logout() {
    var success = this.handleLogout.bind(this);
    var error = this.handleError.bind(this);
    localStorage.rememberMe = 'off';
    $.ajax({
      method: 'GET',
      url: '/logout',
    })
      .done(success)
      .fail(error);
  }

  // Removes locally stored user and set the state's user to empty
  handleLogout(data) {
    localStorage.removeItem('user');
    localStorage.removeItem('organization');
    this.setState({
      user: {},
    });
  }

  handleError(error) {
    console.log('Error: ' + error);
  }

  createOrganization() {
    $.ajax({
      method: 'POST',
      url: '/organizations',
      data: this.state,
    }).done(this.updateOrganization.bind(this));
  }

  requireUser() {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
  }

  renderDefault(location) {
    if (this.state.user.id) {
      return <Redirect to="/patients" />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  renderLogin(location) {
    if (this.state.user.id) {
      return <Redirect to="/patients" />;
    }
    return (
      <Login
        location={location}
        user={this.state.user}
        handleUser={this.handleUser.bind(this)}
      />
    );
  }

  renderProxyLogin(location) {
    if (this.state.user.id) {
      return <Redirect to="/patients" />;
    }
    return (
      <ProxyLogin
        location={location}
        user={this.state.user}
        handleUser={this.handleUser.bind(this)}
      />
    );
  }

  renderLogout(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Logout
        location={location}
        user={this.state.user}
        logout={this.logout.bind(this)}
      />
    );
  }

  renderOrganization(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Organization
        location={location}
        user={this.state.user}
        organization={this.state.organization}
        getOrganizations={this.getOrganizations.bind(this)}
      />
    );
  }

  renderPatients(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Patients
        location={location}
        user={this.state.user}
        organization={this.state.organization}
        includeInMultiNight_dataRetriever={
          this.includeInMultiNight_dataRetriever
        }
      />
    );
  }

  renderPatient(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    //kvk ... where did it get pat data?

    return (
      <Patient
        location={location}
        user={this.state.user}
        organization={this.state.organization}
        includeInMultiNight_dataRetriever={
          this.includeInMultiNight_dataRetriever
        }
      />
    );
  }

  renderReports(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Reports
        location={location}
        organization={this.state.organization}
        includeInMultiNight_dataRetriever={
          this.includeInMultiNight_dataRetriever
        }
      />
    );
  }

  renderReport(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Report
        location={location}
        organization={this.state.organization}
        user={this.state.user}
      />
    );
  }

  renderUser(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return <User location={location} user={this.state.user} />;
  }

  renderProfile(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Profile
        location={location}
        user={this.state.user}
        organization={this.state.organization}
        isAdmin={this.state.isAdmin}
      ></Profile>
    );
  }

  renderPayment(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Payment
        location={location}
        user={this.state.user}
        organization={this.state.organization}
      />
    );
  }

  renderSubscriptions(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Subscriptions
        location={location}
        user={this.state.user}
        organization={this.state.organization}
      />
    );
  }

  renderMembers(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Members
        location={location}
        user={this.state.user}
        organization={this.state.organization}
        isAdmin={this.state.isAdmin}
      />
    );
  }

  renderPhysicians(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Physicians
        location={location}
        user={this.state.user}
        organization={this.state.organization}
      />
    );
  }

  renderResetPassword(location) {
    return <ResetPassword location={location} />;
  }

  renderConnections(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return <Connections location={location} />;
  }

  renderSettings(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <Settings
        location={location}
        organization={this.state.organization}
      ></Settings>
    );
  }

  renderVideoTours(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return <VideoTours location={location} />;
  }

  renderCompareReports(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return (
      <CompareReports
        location={location}
        organization={this.state.organization}
        user={this.state.user}
        includeInMultiNight_dataRetriever={
          this.includeInMultiNight_dataRetriever
        }
      />
    );
  }

  renderAnalysis(location) {
    if (!this.state.user.id) {
      return <Redirect to="/login" />;
    }
    return <Analysis location={location}></Analysis>;
  }

  renderChart(location) {
    return <Charts location={location} />;
  }

  renderTest(location) {
    return <Test location={location} />;
  }

  renderNavbar() {
    let isLoggedIn = this.state.user.id ? true : false;
    var data = {
      user: this.state.user,
      organization: this.state.organization,
      organizations: this.state.organizations,
    };
    var handle = {
      organization: this.handleOrganization.bind(this),
    };
    if (isLoggedIn) {
      return (
        <Navbar
          user={data.user}
          organization={data.organization}
          organizations={data.organizations}
          handleOrganization={handle.organization}
          logout={this.logout.bind(this)}
        />
      );
    }
    return null;
  }

  render(a, b, c) {
    var actions = {
      logout: this.logout.bind(this),
    };
    var render = {
      default: this.renderDefault.bind(this),
      analysis: this.renderAnalysis.bind(this),
      chart: this.renderChart.bind(this),
      login: this.renderLogin.bind(this),
      proxyLogin: this.renderProxyLogin.bind(this),
      logout: this.renderLogout.bind(this),
      organization: this.renderOrganization.bind(this),
      payment: this.renderPayment.bind(this),
      subscriptions: this.renderSubscriptions.bind(this),
      members: this.renderMembers.bind(this),
      physicians: this.renderPhysicians.bind(this),
      patients: this.renderPatients.bind(this),
      patient: this.renderPatient.bind(this),
      reports: this.renderReports.bind(this),
      report: this.renderReport.bind(this),
      user: this.renderUser.bind(this),
      connections: this.renderConnections.bind(this),
      videoTours: this.renderVideoTours.bind(this),
      reset: this.renderResetPassword.bind(this),
      compareReports: this.renderCompareReports.bind(this),
      settings: this.renderSettings.bind(this),
      profile: this.renderProfile.bind(this),

      test: this.renderTest.bind(this),
    };

    var data = {
      user: this.state.user,
      organization: this.state.organization,
      organizations: this.state.organizations,
    };
    return (
      <HashRouter>
        <div id="app">
          <div className="container-fluid">
            <div className="row">
              <div className="w-10 " style={{ height: '700px' }}>
                <Sidebar
                  user={data.user}
                  organization={data.organization}
                  isAdmin={this.state.isAdmin}
                  logout={actions.logout}
                />
              </div>

              <div id="viewport" className="col">
                {this.renderNavbar()}
                <Switch>
                  <Route exact strict path="/" render={render.default} />
                  <Route
                    exact
                    strict
                    path="/connections"
                    render={render.connections}
                  />
                  <Route
                    exact
                    strict
                    path="/video-tours"
                    render={render.videoTours}
                  />
                  <Route exact strict path="/forgot" component={Forgot} />
                  <Route exact strict path="/login" render={render.login} />
                  <Route exact strict path="/logout" render={render.logout} />
                  <Route exact strict path="/members" render={render.members} />
                  <Route
                    exact
                    strict
                    path="/multi-night-reports/:id"
                    render={render.compareReports}
                  />
                  <Route
                    exact
                    strict
                    path="/organizations/:id"
                    render={render.organization}
                  />
                  <Route
                    exact
                    strict
                    path="/patients"
                    render={render.patients}
                  />
                  <Route
                    exact
                    strict
                    path="/patients/:id"
                    render={render.patient}
                  />
                  <Route exact strict path="/payment" render={render.payment} />
                  <Route
                    exact
                    strict
                    path="/physicians"
                    render={render.physicians}
                  />
                  <Route
                    exact
                    strict
                    path="/proxy"
                    render={render.proxyLogin}
                  />
                  <Route exact strict path="/reports" render={render.reports} />
                  <Route
                    exact
                    strict
                    path="/reports/:id"
                    render={render.report}
                  />
                  <Route exact strict path="/reset" render={render.reset} />
                  <Route exact strict path="/signup" component={Signup} />
                  <Route
                    exact
                    strict
                    path="/subscriptions"
                    render={render.subscriptions}
                  />
                  <Route exact strict path="/test" render={render.test} />
                  <Route
                    exact
                    strict
                    path="/analysis"
                    render={render.analysis}
                  />
                  <Route exact strict path="/chart/:id" render={render.chart} />
                  <Route
                    exact
                    strict
                    path="/chart/:id/:patientId"
                    render={render.chart}
                  />
                  <Route exact strict path="/profile" render={render.profile} />
                  <Route
                    exact
                    strict
                    path="/settings"
                    render={render.settings}
                  />
                  <Route exact strict path="/users/:id" render={render.user} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default App;
