
//**********************************************
// data types master configuration file
//**********************************************

import moment from 'moment'
import ns from '../bsl/core/ns.js';
import '../bsl/core/load-images.js'

import ai_tpl_multi             from "./multi-tpl/chart-ai.tpl.js"
import bd_tpl_multi             from "./multi-tpl/chart-bd.tpl.js"
import rdi_tpl_multi            from "./multi-tpl/chart-rdi.tpl.js"
import cs_tpl_multi             from "./multi-tpl/chart-cs.tpl.js"

import table_tpl                from "./multi-tpl/table.tpl.js"
import horizontal_line_table_tpl from "./multi-tpl/horizontal-line-table.tpl.js"
import picture_tpl              from "./multi-tpl/picture.tpl.js"
import picture_pci_tpl          from "./multi-tpl/picture-pci-icon.tpl"


import databind_ai              from './multi-tpl/databind_ai.js';
import databind_rdi             from './multi-tpl/databind_rdi.js';
import databind_bd              from './multi-tpl/databind_bd.js';
import databind_cs              from './multi-tpl/databind_cs.js';
import databind_spO2            from './multi-tpl/databind_spO2.js';
import databind_cells_bound     from './multi-tpl/databind_cells-bound.js';
import databind_horizontal_line from './multi-tpl/databind_horizontal-line.js';

import databind_study_info      from './multi-tpl/databind_study-info.js';
import databind_pattern_bound   from './multi-tpl/databind_pattern-bound.js';
import databind_traditional_report      from './multi-tpl/databind_traditional-report.js';
import databind_traditional_pulse_data  from './multi-tpl/databind_traditional-pulse-data.js';
import databind_spo2_time_in_ranges     from './multi-tpl/databind_spo2-time-in-ranges.js';
import databind_spo2_events             from './multi-tpl/databind_spo2-events.js';
import databind_pulse_of_time           from './multi-tpl/databind_pulse-of-time.js';
import databind_pulse_events            from './multi-tpl/databind_pulse-events.js';

var ccc = console.log; ccc && ( ccc = console.log );


//-------------------------------------------
// secures presense of handy data placeholder
//-------------------------------------------
ns.fheap.data_rows = [];

ns.fconf.allTables__ZERO_2_INTEGER = true;

ns.fconf.editableCss =
{
    //-------------------------------------------------
    // //\\ style editor fields
    //-------------------------------------------------
    fontWeight              : false, //false === "normal", true === 'bold'
    fontStyle               : false, //false === 'normal', true === 'bold',
    fontSize                : 16,
    lineHeight              : 1,
    fontFamily              : 'ArialNova',
    fontColor               : '#0d3e66',
    textAlign               : 'left',
    borderWidth             : 1,
    borderColor             : '#000000',
    borderStyle             : 'solid',
    backgroundColor         : 'transparent',
    //-------------------------------------------------
    // \\// style editor fields
    //-------------------------------------------------
};










///predefined functions
///so far June 22, 2020, used only in fieldKey2value and only in
///serDatCelBin_2_serData
///as of Jul 1, 2000, ver 2790, used only for cellbound cells
ns.fmethods.dataFormatters =
{
    moment : function( value, format ) {

        ///next day "jumper":
        ///it jumps only when we need day-accuracy and only
        ///for this specific format match ...
        ///and only if time === 0
        if(
            format.match( /^\s*(MMM|MMMM)\s*D,\s*(YY|YYYY)\s*$/ )
        ) {
            //ccc( 'match: value1=', value )
            ////todm ... this is still a patch ... moment.hours(), ... minutes()
            ////should be used instead ...

            //fails: value = value.replace( /T00:00:00\.000/g, 'T01:00:00' );
            //see: /metatools/r8d/apparent-moment.js-bug.txt

            //this is a patch:
            value = value.replace( /T00:00:00\.000(Z|)/g, '' );
            //ccc( 'value2=', value )
            value = moment( value ).format( format );
            //ccc( 'moment value for day boundary case=', value );

        } else {
            //ccc( 'arg for moment.js=', value )
            value = moment( value ).format( format );
            //ccc( 'moment value for ordinary case=', value );
        }
        return value;
    },
}


function dataFramework_tpl( HRM, pstyle ) {

    var tableTpl = table_tpl( HRM, pstyle );
    var horLineTpl = horizontal_line_table_tpl( HRM, pstyle );
    var pictureTpl = picture_tpl( HRM, pstyle );
    var picturePCITpl = picture_pci_tpl( HRM, pstyle );


    //===========================================================
    // //\\ static images from code
    //      to default_empty_doc.docbody.repo;
    //      interim patch;
    //===========================================================
    ( function () {
        var imgRepo = '';
        ns.fheap.imagesLoadCount = 0;

        var imgList =
        [ pictureTpl,
          picturePCITpl,
        ];
        ns.fheap.imagesLoadMust = imgList.length;
        imgList.forEach( pictpl => {
            ns.methods.loadSingleImage( pictpl.imsrc, ({ src, dataURL }) => {
                imgRepo = ns.fmethods.img2repo({
                        imageid : src,
                        dataURL,
                        repo : imgRepo,
                });
                ns.fheap.imagesLoadCount++;
                if( ns.fheap.imagesLoadCount === ns.fheap.imagesLoadMust ) {
                    ns.fconf.default_empty_doc.docbody.repo.imgRepo = imgRepo;
                    //ccc( 'default repo loaded='+JSON.stringify( imgRepo ).substring( 0, 200 ) );
                }
            });
        });
    })();
    //===========================================================
    // \\// static images from code
    //===========================================================



    var pretpl =
    {
            contrPanelChosen_tplDatGrIx : 2,



            ///********************************************************************
            ///  dataBinds is a template for dynamic creation of
            ///      serie.serieDataCell_binding
            ///      and
            ///      serie.data
            ///  for cellbound tables
            ///  serie.serieDataCell_binding will have structure aka:
            ///      serie.serieDataCell_binding[ data_el_ix ] =
            ///      {   field : '
            ///            {
            ///                caption : 'Full Name',
            ///                fieldKeys : [ "firstName", "lastName" ],
            ///            }
            ///
            ///          of aka
            ///          field : "birthDate"
            ///
            ///          bRepoKey : 'patient'
            ///      }
            ///  serieDataCell_binding will further direct serie.data rebuild
            ///      based on actually received data from server
            /*
                 in plain words, the scenario is:
                    initially, serieDataCell_binding is an empty array,
                    gradually, admin builds it from session to session ...
                    for that, text-input-overlay is supplied two selection
                        elements: one for dataBinds keys, "bRepoKey", and second for
                                  "fields".
                        when admin selects them, then    
                        serieDataCell_binding is populated with "field" and "bRepoKey";
                            (events are preset in "builds_dataBindFields_selectEl")
                        then correspoding serie's data-cell use both of these prop.
                        to populate the data-cell
                            (the popul. happens in "serDatCelBin_2_serData");
                    note, the serie.serieDataCell_binding and series.data are saved
                    to db when doc is saved;
            */
            ///********************************************************************
            dataBinds :
            {
                patient :
                {
                    container : 'fullAjaxData.patient',
                    caption : 'patient data',
                    fields :
                    [
                        "id",
                        "firstName",
                        "lastName",
                        "birthDate",
                        "new field to detect",
                        "email",
                        "gender",
                        {
                            id      : 'fullName',
                            caption : 'Full Name',
                            fieldKeys : [ "firstName", "lastName" ],
                        }
                    ],
                    field2formatter :
                    {
                        birthDate : { funct : 'moment', format : 'MMMM D, YYYY' },
                    },
                },
                report :
                {
                    container : 'fullAjaxData.multiNightReport',
                    caption : 'report',
                    fields :
                    [
                        "id",
                        "organization_id",
                        "updatedAt",
                    ],
                    field2formatter :
                    {
                        updatedAt : { funct : 'moment', format : 'MMM D, YY' },
                    },
                },
            },

            //======================================================
            // //\\ data groups configuration
            //======================================================
            dataGroups :
            [
                //=================================
                // //\\ table-like
                //=================================
                {
                    isTable : true,
                    dataId : "rows-bound",
                    dashCaption : 'rowsbound data',
                    tpl : tableTpl,
                    chosenIx : 0,
                    digitsAfterPoint : 2,
                    subGrs :
                    [
                        {
                            id : "ai",
                        },
                        {
                            id : "cs",
                        },
                        {
                            id : "bd",
                        },
                        {
                            id : "rdi",
                        },
                        {
                            id : "spO2"
                        },
                        {
                            id : "study_info"
                        },
                        {
                            dashCaption : 'Pattern Based Report',
                            id : "pattern_bound"
                        },
                        {
                            id : "traditional_report"
                        },
                        {
                            id : "traditional_pulse_data"
                        },
                        {
                            id : "spo2_time_in_ranges"
                        },
                        {
                            dashCaption : 'SpO2: Events in Ranges',
                            id : "spo2_events"
                        },
                        {
                            dashCaption : 'Pulse: Time in Ranges',
                            id : "pulse_of_time"
                        },
                        {
                            dashCaption : 'Pulse: Events in Ranges',
                            id : "pulse_events"
                        },
                    ],
                },
                {
                    isTable : true,
                    dataId : "cells-bound",
                    dashCaption : 'cellbound data',
                    tpl : tableTpl,
                    chosenIx : 0,
                    digitsAfterPoint : 2,
                    subGrs :
                    [
                        {
                            id : "pages-body",
                        },
                        {
                            id : "pages-header",
                        },
                    ],
                },
                {
                    isTable : true,
                    dataId : "horizontal-line",
                    tpl : horLineTpl,
                    chosenIx : 0,
                    subGrs :
                    [
                        {
                            id : "pages-body",
                        },
                        {
                            id : "pages-header",
                        },
                    ],
                },

                //=================================
                // \\// table-like
                //=================================

                //=================================
                // //\\ picture-like
                //=================================
                {
                    isPicture : true,
                    dataId : 'picture',
                    dashCaption : 'pictures',
                    chosenIx : 0,
                    tpl : pictureTpl,
                    subGrs :
                    [
                        { id : "generic picture",
                          dashCaption : "picture",  
                          pos : [0,0, 20, 10],
                        },
                        { id : "pages-header",
                          dashCaption : "header picture",
                          pos : [0,0, 20, 10],
                        },
                    ],
                },
                {
                    isPicture : true,
                    dataId : 'picture pci',
                    dashCaption : 'pictures pci',
                    chosenIx : 0,
                    tpl : picturePCITpl,
                    subGrs :
                    [
                        { id : "generic picture",
                          dashCaption : "picture",  
                          pos : [0,0, 20, 10],
                        },
                        { id : "pages-header",
                          dashCaption : "header picture",
                          pos : [0,0, 20, 10],
                        },
                    ],
                },
                //=================================
                // \\// picture-like
                //=================================


                //=================================
                // //\\ chart-like
                //=================================
                {
                    isChart : true,
                    dataId : 'ai',
                    dashCaption : 'ai chart',
                    tpl : ai_tpl_multi,
                    chosenIx : 0,
                    subGrs :
                    [
                        { id : "ai",
                        },
                    ],
                },
                {
                    isChart : true,
                    dataId : 'rdi',
                    dashCaption : 'rdi chart',
                    tpl : rdi_tpl_multi,
                    chosenIx : 0,
                    subGrs :
                    [
                        { id : "rdi",
                        },
                    ],
                },
                {
                    isChart : true,
                    dataId : 'bd',
                    dashCaption : 'bd chart',
                    tpl : bd_tpl_multi,
                    chosenIx : 0,
                    subGrs :
                    [
                        { id : "bd",
                        },
                    ],
                },
                {
                    isChart : true,
                    dataId : 'cs',
                    dashCaption : 'cs chart',
                    tpl : cs_tpl_multi,
                    chosenIx : 0,
                    subGrs :
                    [
                        { id : "cs",
                        },
                    ],
                },
                //=================================
                // \\// chart-like
                //=================================
            ]
            //======================================================
            // \\// data groups configuration
            //======================================================
    }


    //======================================================
    // //\\ spawns data groups configuration
    //      puts data-bound tpl inside of subgroup
    //======================================================
    pretpl.dataGroups.forEach( ( datGr, dg_ix ) => {
        datGr.subGrs.forEach( ( subGr, dsg_ix ) => {
            subGr.dsg_id    = subGr.id; //todm: redundant in theory ...
            subGr.dsg_ix    = dsg_ix;
            subGr.data_ix   = dg_ix;
            subGr.dg_ix     = dg_ix; //todm ... unclean ... duplication
            subGr.dataId    = datGr.dataId;
            subGr.isChart   = datGr.isChart;
            subGr.isTable   = datGr.isTable;
            subGr.isPicture = datGr.isPicture;
            switch ( subGr.id ) {
                case "ai" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_ai() );
                        break;
                case "rdi" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_rdi() );
                        break;
                case "bd" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_bd() );
                        break;
                case "cs" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_cs() );
                        break;
                case "spO2" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_spO2() );
                        break;
                case "study_info" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_study_info() );
                        break;
                case "pattern_bound" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_pattern_bound() );
                        break;
                case "traditional_report" :
                        subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_traditional_report() );
                        break;
                case "traditional_pulse_data" :
                        subGr.tpl = ns.paste(
                                    ns.clonetree( datGr.tpl ),
                                    databind_traditional_pulse_data()
                        );
                        break;
                case "spo2_time_in_ranges" :
                        subGr.tpl = ns.paste(
                                    ns.clonetree( datGr.tpl ),
                                    databind_spo2_time_in_ranges()
                        );
                        break;
                case "spo2_events" :
                        subGr.tpl = ns.paste(
                                    ns.clonetree( datGr.tpl ),
                                    databind_spo2_events()
                        );
                        break;
                case "pulse_of_time" :
                        subGr.tpl = ns.paste(
                                    ns.clonetree( datGr.tpl ),
                                    databind_pulse_of_time()
                        );
                        break;
                case "pulse_events" :
                        subGr.tpl = ns.paste(
                                    ns.clonetree( datGr.tpl ),
                                    databind_pulse_events()
                        );
                        break;
                default:
                        subGr.tpl = subGr.tpl || ns.clonetree( datGr.tpl );
                        break;
            }
            if( datGr.dataId === "horizontal-line" ) {
                subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_horizontal_line() );
            } else if( datGr.dataId === "cells-bound" ) {
                subGr.tpl = ns.paste( ns.clonetree( datGr.tpl ), databind_cells_bound() );
            }
            if( datGr.isPicture ) {
                datGr.dataId = datGr.tpl.imsrc;
                subGr.dataId = datGr.dataId;
            }
            if( datGr.isTable ) {
                subGr.tpl.editableCss = ns.clonetree( ns.fconf.editableCss );
            }
            subGr.pos = ns.sn( 'pos', subGr, [0,10,90,24] ); //card position stub
            if( typeof datGr.digitsAfterPoint === 'number' ) {
                subGr.digitsAfterPoint = subGr.digitsAfterPoint || datGr.digitsAfterPoint;
            }
            subGr.dashCaption = subGr.dashCaption || subGr.dsg_id.replace( /_+/g, ' ' );
        });
        datGr.dashCaption = datGr.dashCaption || datGr.dataId.replace( /_+/g, ' ' );
        if( datGr.isPicture ) {
            datGr.dashCaption = datGr.dataId;
        }
    });
    //======================================================
    // \\// spawns data groups configuration
    //======================================================


    ///used for picture
    ///takes first dataGroup which is a picture, clones it
    ///and adds a new id to it and
    ///inserts new dg into dataGroups ...
    ns.fmethods.clonePictureTpls = function( dataGroups, newId )
    {
        var dg = null;
        dataGroups.forEach( datg => {
            if( !dg && datg.isPicture ) {
                dg = datg;
            }
        });
        dg = ns.clonetree( dg );
        dg.tpl.imsrc = newId;
        dg.dataId = newId;
        dg.dashCaption = newId;
        var dg_ix = dataGroups.length;
        dataGroups.push( dg );
        //todm ... here is a lot of bookkeeping ... indicates overhaul need
        dg.subGrs.forEach( sg => {
            sg.dataId   = newId;
            sg.data_ix  = dg_ix;
            sg.dg_ix    = dg_ix; //todm ... unclean ... duplication
            sg.tpl      = ns.clonetree( dg.tpl );
        });
        return dg;
    }


    ns.fmethods.reindexSplicedDataGroups = function( dataGroups )
    {
        dataGroups.forEach( ( datGr, dg_ix ) => {
            datGr.subGrs.forEach( ( subGr, dsg_ix ) => {
                subGr.data_ix   = dg_ix;
                subGr.dg_ix     = dg_ix; //todm ... unclean ... duplication
            });
        });
    }

    return pretpl;
};
export default { dataFramework_tpl }


