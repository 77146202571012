

( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var fapp        = ns.sn('fapp' );
    var fmethods    = ns.sn('methods', fapp);
    var fheap       = ns.sn('fheap', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.inlcss__doResizeCardFrames  = inlcss__doResizeCardFrames;
    return;






//todm: why this fun messes up witch card contents?
//why card contents needs this fun? for charts ...

    ///----------------------------------------------
    /// css
    ///----------------------------------------------
    //todo ... must be overhauled for data-bound tables
    //         who calls this ... not data-bound?
    //  recall: nest is: pmcard.cardDom$$.pcard$ -> hChartSuperParent$ -> hChartParent$
    //                   "toppest" frame            "middle" frame        "lowest" frame
    //  hChartParent$ is an argument in HC call
    //  this function accomplishes following:
    //      pcard$             is "manually"-shrinked by window-resize
    //      hChartSuperParent$ is "manually"-shrinked by window-resize
    //      hChartParent$      is transform -shrinked by window-resize
    function inlcss__doResizeCardFrames({ pmcard })
    {
        var sc          = fheap.editedPageScale;
        var carVisWidth = pmcard.tplWidth_px*sc;

        //=====================================================
        // //\\ does shrink toppest card frame
        //=====================================================
        var pcard$ = pmcard.cardDom$$.pcard$
            .css( 'width', carVisWidth.toFixed(2) + 'px' )
            .css( 'left', (pmcard.tplLeft_px*sc).toFixed(2) + 'px' )
            .css( 'top', (pmcard.tplTop_px*sc).toFixed(2) + 'px' )
            ;
        if( pmcard.dataGr.isTable ) return;
        //=====================================================
        // \\// does shrink toppest card frame
        //=====================================================


        //==========================================================================
        // //\\ does synch with drag8drop without rerendering the chart
        //==========================================================================
        //      needs to run again after chart is rerendered to fix synch's own work
        //**************************************************************************
        //  TorsteinHonsi: Nov 18, 2013
        //  Tooltip ignores transform: scale
        //  https://github.com/highcharts/highcharts/issues/1753
        //**************************************************************************

        //=====================================================
        // //\\ does shrink toppest card frame
        //=====================================================
        var carVisHight = pmcard.tplHight_px*sc;
        pcard$.css( 'height', carVisHight.toFixed(2) + 'px' );
        //=====================================================
        // \\// does shrink toppest card frame
        //=====================================================


        //=====================================================
        // //\\ does shrink middle card frame
        //=====================================================
        pmcard.cardDom$$.hChartSuperParent$
            //.only for handles
            .css( 'width', carVisWidth.toFixed(2) + 'px' )

            .css( 'height', carVisHight.toFixed(2) + 'px' )
            ;
        //ccc( 'sc=' + sc + ' chartWidthScale=' + chartWidthScale, chartScale );
        //=====================================================
        // \\// does shrink middle card frame
        //=====================================================


        //=====================================================
        // //\\ does shrink lower card frame
        //=====================================================
        if( pmcard.dataGr.isChart ) {
            var dims = pmcard.cdataOptions.chart;
            var chartInnerWidth = dims.width;
            var chartInnerHeight= dims.height;
            //these values do synch the chart
            var widthFix = (carVisWidth/chartInnerWidth).toFixed(4);
            var heightFix = (carVisHight/chartInnerHeight).toFixed(4);
            //if( pmcard.pageIx === 0 && pmcard.cardIx === 0 )
            //    ccc( 'widthFix, heightFix=' + widthFix + ' ' + heightFix );

        } else if( pmcard.dataGr.isPicture ) {
            widthFix = 1;
            heightFix = 1;
        }
        pmcard.cardDom$$.hChartParent$
            .css( 'transform-origin', '0px 0px' )
            .css( 'transform',
                  'scale(' +
                        widthFix + ',' +
                        heightFix +
                  ')'
            )
        ;
        //===================================================================
        // \\// does shrink lower card frame
        // \\// does synch with drag8drop without rerendering the chart
        //===================================================================
    }

}) ();
