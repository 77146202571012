import React, { useEffect, useState, useRef, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Analysis from "../analysis";
import ToolBar from "../components/toolbar";

const AnalysisScreen = (props) => {
  const handle = useFullScreenHandle();
  const [containerHeight] = useState(300);
  const [containerWidth] = useState(550);
  const [chartHeight, setChartHeight] = useState(550 / 3 - 50 / 3);
  const [isFullScreen, setisFullScreen] = useState(false);

  const screenRef = useRef(null);

  useEffect(() => {
    const height = screenRef.current.offsetHeight;
    const width = screenRef.current.offsetWidth;
    if (containerHeight !== height || containerWidth !== width){
      updateChartSize(height, width)
    }
  });

  function updateChartSize(height, width) {
    setChartHeight(height / 3 - 50 / 3)
  }

  const handleScreenChange = useCallback((state, handle) => {
    setisFullScreen(!isFullScreen);
  });

  return (
    <FullScreen
      handle={handle}
      style={{ backgroundColor: "white" }}
      onChange={handleScreenChange}
    >
      <div
        style={{ backgroundColor: "white", width: "100%", height: "100%" }}
        ref={screenRef}
      >
        <ToolBar>
          <button
            className={"btn btn-sm border"}
            title="Full Screen"
            onClick={handle.enter}
          >
            <i className="fa-regular fa-expand"></i>
          </button>
        </ToolBar>
        <Analysis chartHeight={chartHeight}></Analysis>
      </div>
    </FullScreen>
  );
};

export default AnalysisScreen;
