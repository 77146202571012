import React, {Component} from 'react'
import SubscriptionItem from './subscription-item'
import PlanItem from './plan-item'
import $ from 'jquery'
import Payments from './payment'

class Subscriptions extends Component {

	constructor(props){
		super(props)
		this.state = {
			plans: [],
			subscriptions: [],
			cards: []
		}
	}

	componentDidMount(){
		this.getSubscriptions()
		this.getPlans()
		this.getCards()
	}

	componentDidUpdate(prevProps,prevState){
		if (prevProps.organization.id !== this.props.organization.id){
			this.getSubscriptions()
		}
	}

	getPlans(){
		var success = this.handlePlans.bind(this)
		$.get('/plans').done(success)
	}

	handlePlans(data){
		this.setState({
			plans: data.data
		})
	}

	getSubscriptions(){
		var id = this.props.organization.id
		var url = '/organizations/' + id + '/subscriptions'
		var success = this.handleSubscriptions.bind(this)
		$.get(url).done(success)
	}

	handleSubscriptions(data){
		console.log(data)
		this.setState({
			subscriptions: data.subscriptions.data,
			customer: data.customer
		})
	}

	handleSubscriptionError(data){
		this.setState({
			subscriptions: []
		})
	}

	getCards(){
		var url = '/organizations/' + this.props.organization.id +'/cards'
		var success = this.handleCards.bind(this)
		$.ajax({
			url: url
		}).done(success)
	}

	handleCards(data){
		this.setState({
			cards: data.data
		})
	}

	renderPlan(data,index){
		var getSubscriptions = this.getSubscriptions.bind(this)
		return <PlanItem key={index}
			data={data}
			organization={this.props.organization}
			getSubscriptions={getSubscriptions}
			/>
	}

	renderSubscriptionItem(data, index){
		return <SubscriptionItem key={data.id} 
		index={index} 
		data={data} cards={this.state.cards} 
		organization={this.props.organization} 
		subscriptions={this.state.subscriptions}
		customer={this.state.customer}/>
	}

	render(){
		var renderSubscription = this.renderSubscriptionItem.bind(this)
		var subscriptionItems = this.state.subscriptions.map(renderSubscription)
		return (
		<div>
			<div className='text-center'> 
			Purchase the latest oximeters, accessories, and disposable probes at our online store: 
			<a href="https://www.sleepsat.com/online-store"> www.sleepsat.com/online-store</a>
			</div>
			<Payments user={this.props.user} organization={this.props.organization} subscriptions={this}/>
			<div className='card mb-4'>
				<div className='card-header'>
					<span className='mt-4 mb-4 h4'>Subscriptions</span>
				</div>
				<div className='card-body'>
					<legend className='mt-2'>Active Subscriptions</legend>
					<ul className='list-group'>
						{subscriptionItems}
					</ul>
				</div>
			</div>
		</div>
			
		)
	}
	
}

export default Subscriptions
