
export default function ( HRM, pstyle ) {

    var ret = {
            orientationLandscape    : true,
            width                   : pstyle.PAGE_WIDTH * 0.9,
            left                    : pstyle.PAGE_WIDTH * 0,
            top                     : pstyle.PAGE_HEIGHT * 0,
            paddings                : [6,6,10,8],
    };
    return ret;
}




