
export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [], //dates
        },
        series:
        [
            {
                type: 'line',
                name: 'ODI4',
                data: [],
                METAOPT_data_report_keys :
                [
                    'ODI4',
                ]
            },
            {
                type: 'line',
                name: 'Minimum SPO2 (%)',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_MIN',
                ]
            },
            {
                type: 'line',
                name: '<= 88% Total Duration',
                data: [],
                METAOPT_time_format : 'HH:MM:SS',
                METAOPT_data_report_keys :
                [
                    'SPO2_LTE88DUR',
                ]
            },
            {
                type: 'columnrange',
                name: 'CT <90 (%VRT)',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_data_report_keys :
                [
                    'SPO2_LT90PCT',
                ],
            },
        ],
    }
}




