

( function() {
    var ns          = window.b$l;
    var $$          = ns.$$;
    var sn          = ns.sn;
    var fapp        = sn('fapp');
    var fconf       = ns.fconf;
    var fmethods    = sn('methods',fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.rebuildTable = rebuildTable;
    return;









    function rebuildTable( dtable, rebuildTable_cb, doDrawInlineCssAfter )
    {
        var ts = dtable.style8data_tpl;
        var dataId = dtable.context.dataGr.dataId;
        var colFr;
        //-----------------------------------------
        // //\\ reestablishes rows-model and column-fractions
        //      for landscape or portrait
        //-----------------------------------------
        //if( dtable.tableId === '0-0' ) ... 
        setPortait8Landscape( dtable );
        //-----------------------------------------
        // \\// reestablishes rows-model and column-fractions
        //-----------------------------------------

        dtable.dat8tpl_2_model8inlcss = dat8tpl_2_model8inlcss.bind( dtable );
        scapifiedTable_2_dataModel( dtable );
        if( !fconf.ENABLE_REP_EDITOR ) {
            scapifiedTable_2_cssModel( dtable );
            return;
        }


        //-------------------------------
        // //\\ table garbage collection
        //-------------------------------
        if( ns.hap( dtable, 'dtable$' ) ) {
            ////collects dom garbage for former dtable instance
            var drags = dtable.tableParent.querySelectorAll( '.brc-slider-draggee' );
            while( drags.length ) {
                ////todm ineffective
                ////todm this garbage collection must be in d8d engine
                drags[0].remove();
                drags = dtable.tableParent.querySelectorAll( '.brc-slider-draggee' );
            }
            dtable.dtable$().remove();
        }
        //-------------------------------
        // \\// table garbage collection
        //-------------------------------


        //-------------------------------
        // //\\ table dom root
        //-------------------------------
        var tscale = dtable.tableScale();
        dtable.dtable$ = $$
            .c( 'table' ) //display: table;
            .css( 'position', 'relative' )
            .css( 'left', ( ts.left * tscale ).toFixed(4) + 'px' )
            .css( 'top', ( ts.top * tscale ).toFixed(4) + 'px' )
            //.css( 'z-index', ts.originalTableZIndex+'' )
            //irrelevant: .css( 'width', ts.width + 'px' ) 
            .addClass( 'fluid-table dtable id-' + dtable.tableId )
            .to( dtable.tableParent )
            //.e( 'click', function() {
            //})
            ;
        //-------------------------------
        // \\// table dom root
        //-------------------------------


        dtable.rows$ = [];
        var dom$ = dtable.dom$ = {};

        dtable.scapified_head8rows.forEach( ( row, rix ) => {
            //.creates dom-row
            var tr$ = $$
                .c( 'tr' )
                .addClass( 'tr-' + rix )
                .to( dtable.dtable$ )
                ;
            !rix && tr$.addClass( 'dheader' );
            var cells$ = [];
            row.forEach( ( cellval, cix ) => {
                fmethods.build_cell__html({
                    cix,
                    dtable,
                    tr$,
                    cells$,
                    rows$:dtable.rows$,
                    dom$,
                    rix,
                    ts,
                    tscale,
                    //model2inlcss : model2inlcss,
                    dataId,
                    colFr,
                })
            });
            dtable.rows$[ rix ] = { tr$, cells$ }
            //ccc( rix + ' row=', row, ' dtable.rows$[ rix ]=', dtable.rows$[ rix ] )
        });

        /*
        ///to test table position and dimenstions
        ns.ttt$ = $$.div()
            .to( dtable.tableParent )
            .css( 'position', 'absolute' )
            .css( 'width', ( ts.width * tscale ).toFixed(4) + 'px' )
            .css( 'left', ( ts.left * tscale ).toFixed(4) + 'px' )
            .css( 'top', ( ts.top * tscale ).toFixed(4) + 'px' )
            .css( 'border', '3px solid red' )
            .css( 'display', 'none' )
            ;
        */

        //dtable.dcss__legendCalculated = dcss__legendCalculated.bind( dtable );
        //metrify8hardenCss_withoutTableUpdate( dtable, () => {

        if( fconf.ROLE === 'CONSTRUCTOR' ) {
            //ccc( dataId + ' rows$', dtable.rows$ );
            dtable.d8draw = fmethods.initDragModel_tableComposer( dtable ).d8draw;
        }
        if( doDrawInlineCssAfter ) {
            dtable.dat8tpl_2_model8inlcss();
        }
        rebuildTable_cb && rebuildTable_cb( dtable );
        return dtable;






        function setPortait8Landscape( dtable )
        {
            //----------------------------------------------
            // //\\ builds rows-model
            //----------------------------------------------
            var data_rows = dtable.rows;
            var hd = dtable.headerDataRow;

            if( ts.orientationLandscape ) {
                //// landscape
                var pivot = ( hd && hd.length && hd ) || data_rows[0];
                var scapified_head8rows = pivot.map( (hcell,hix) => {
                    ////builds horizontal-row of landscape;
                    ////prepends header's cell to horizontal-row
                    ////and further completes the horizontal-row by adding cell of db-row
                    var newRow = ( hd && hd.length ) ? [ hcell ] : [];
                    for( var rix=0, len = data_rows.length; rix<len; rix++ ) {
                        newRow.push( data_rows[ rix ][ hix ] );
                    }
                    return newRow;
                });
            } else {
                scapified_head8rows = hd && hd.length ? [ hd ].concat( data_rows ) : data_rows;
            }
            //----------------------------------------------
            // \\// builds rows-model
            //----------------------------------------------


            var wwl = scapified_head8rows[0].length;
            //if( dtable.tableId === '0-0' )
            //    ccc( 'landscape=' + ts.orientationLandscape +

            ///establishes fractions (for saving) if missed ...
            ts.columnFractions = ns.sn( 'columnFractions', ts );
            var land0port = ts.orientationLandscape ? 'landscape' : 'portrait';
            colFr = ns.sn( land0port, ts.columnFractions, [] );
            //if( dtable.tableId === '0-0' )
            //    ccc( 'before synching fractions and columns ' + land0port +
            //         " data-row-length=" + wwl + ' colFr = ' +
            //         JSON.stringify(colFr, null, '  ') ); 

            //todm: give this more thought: must we always redo widths?
            if( wwl > colFr.length ) {
                ////the length of column fractions belonging to orientation have been changed
                //if( dtable.tableId === '0-0' )
                //    ccc( "ts.columnFractions=", ts.columnFractions ); 

                //todo double check this ... empty column fractions
                if( wwl > 1 ) {
                    ////completely erases former values of colFr
                    ////the idea is that changing length of the data should not
                    ////attempt to preserve existing fraction and should completely
                    ////reset fractions
                    var capFr = 0.3; //todo ... find reasonable value
                    var ordFr = 0.7 / ( wwl-1 );
                    ts.columnFractions[ land0port ] = [ capFr ].concat(
                        //bug: (new Array( wwl-colFr.length )).fill( ordFr )
                        (new Array( wwl-1 )).fill( ordFr )
                    );
                    /*
                    if( dtable.tableId === '0-0' )
                        ccc( 'added more colFr to satisfy longer data array: ' +
                             'ts.columnFractions[ land0port ]=' +
                             JSON.stringify( ts.columnFractions[ land0port ]
                        )
                    );
                    */
                } else {
                    /*
                    if( dtable.tableId === '0-0' )
                        ccc( 'added single colFr to fit single data cell: land0port=' + land0port );
                    */
                    ts.columnFractions[ land0port ] = [1];
                }
            } else if( wwl < colFr.length
                //todm ... get rid of this block
                /*
                &&
                //.this tries to solve problem of corrupting saved fractions when
                //.table is drawn two times, the first time when templates are downloaded
                //.and table is mistakenly drawn (which erases user's fractions) and
                //.when data arrives then fraction have to be reset again ...
                //.... so the right solution is? not to draw empty table ...
                ( dtable.rows.length > 1 || !ts.orientationLandscape )
                */
            ) {
                ////truncates wild or outdated fraction ...
                colFr.length = wwl;
            }
            colFr = ts.columnFractions[ land0port ];

            //if( dtable.tableId === '0-0' )
            //    ccc( '************ after synching ' + land0port + " ls hea-data-row-length=" + wwl +
            //        ' colFr = ' +  JSON.stringify(colFr, null, '  ') );
            dtable.scapified_head8rows = scapified_head8rows;
        }
    }


    ///must be bound to dtable
    function dat8tpl_2_model8inlcss( skipCss ) {
        var dtable = this;
        scapifiedTable_2_cssModel( dtable );
        if( !skipCss ) {
            inlcss__card_Table( dtable );
        }
    }


    //===================================
    // //\\  throws inline css into table
    //===================================
    function inlcss__card_Table( dtable )
    {
        var dt      = dtable;
        var ts      = dt.style8data_tpl;
        var hr      = dt.scapified_head8rows;
        var tscale  = dt.tableScale();
        var dtable$ = dt.dtable$;
        var rows$   = dt.rows$;

        //:possibly can be moved to separate sub. for d8d legend-mover
        dtable$.css( 'left', ( ts.left * tscale )+ 'px' );

        //vital
        dtable$.css( 'width', ( ts.width * tscale )+ 'px' );
        dtable$.css( 'top', ( ts.top * tscale ) + 'px' );
        //dtable$.removeClass( 'absolute-tr' );

        hr.forEach( ( row, rix ) => {
            var cells$  = rows$[ rix ].cells$;
            row.forEach( ( cellval, cix ) => {
                model2inlcss({
                    rix,
                    cix,
                    dtable,
                    tscale,
                    cell$ : cells$[ cix ],
                });
            });
        });
        //ns.ttt$.css( 'width', ( ts.width * tscale ).toFixed(4) + 'px' )
    }
    //===================================
    // \\//  throws inline css into table
    //===================================


    //====================================
    // //\\ preconverts cell values
    //      for common use in HTML and PDF
    //====================================
    function scapifiedTable_2_dataModel( dtable )
    {
        var dataModel__rows = ns.sn( 'dataModel__rows', dtable, [] );

        dtable.scapified_head8rows.forEach( ( row, rix ) => {
            var dataModel__cells = dataModel__rows[ rix ] =
                                   dataModel__rows[ rix ] || [];
            row.forEach( ( cellval, cix ) => {
                dataModel__cells[ cix ] = cellval + '';

                if( dtable.context.dtcard.isTable ) {
                    //ccc( dtable.context.dtcard, dtable.context.dtcard.digitsAfterPoint );
                    dataModel__cells[ cix ] = fmethods.formatFloat({
                        digitsAfterPoint : dtable.context.dtcard.digitsAfterPoint,
                        value : cellval,
                    });
                }
            })
        })
    }
    //====================================
    // \\// preconverts cell values
    //====================================


    //===================================
    // //\\  throws model css into table
    //===================================
    function scapifiedTable_2_cssModel( dtable )
    {
        var ts      = dtable.style8data_tpl;
        var hr      = dtable.scapified_head8rows;
        var lscape  = ts.orientationLandscape;
        var colFr   = ts.columnFractions[ lscape ? 'landscape' : 'portrait' ];
        var dataId  = dtable.context.dtcard.dataId;
        var cssModel__rows = ns.sn( 'cssModel__rows', dtable, [] );

        var series = dtable.context.dtcard.style8data_tpl.series;

        hr.forEach( ( row, rix ) => {
            var cssModel__cells =
                cssModel__rows[ rix ] =
                cssModel__rows[ rix ] || [];
            row.forEach( ( cellval, cix ) => {
                cssModel__cells[ cix ] = {};
                if( dataId === "cells-bound" || dataId === "horizontal-line" ) {
                    ////unfortunately in context of "inlcss__card_Table",
                    ////rows and cells original meaning is lost, so here we restore it
                    var virtualRow = lscape ? rix : cix;
                    var virtualCell = lscape ? cix : rix;
                    var standaloneStyle = series[ virtualRow ].cellstyle[ virtualCell ];
                }
                scapifiedCell_2_cssModel({
                    cellCssModel : cssModel__cells[ cix ],
                    colFrWidth : colFr[ cix ],
                    ts,
                    standaloneStyle, //only for cells-bound or horizontal-line
                    rix,
                    cix
                });
            });
        });
    }
    //===================================
    // \\//  throws model css into table
    //===================================


    function scapifiedCell_2_cssModel({ cellCssModel, colFrWidth, ts, standaloneStyle, rix, cix })
    {
        if( rix === 0 ) {
            //=======================================================
            //todm ... % may works better and is logically better
            //-------------------------------------------------------
            var widthPercent = Math.max( 1,
                colFrWidth * 100
            );
            cellCssModel[ 'width' ] = widthPercent;
            //=======================================================

        }
        //editable styles
        Object.keys( ns.fconf.editableCss ).forEach( propName => {
            var propValue = standaloneStyle && ( typeof standaloneStyle[ propName ] !== 'undefined' ) ?
                            standaloneStyle[ propName ] :
                            ts.editableCss[ propName ];
            cellCssModel[ propName ] = propValue;
        });
        cellCssModel[ 'padding-left' ]  = ts.paddings[0];
        cellCssModel[ 'padding-top' ]   = ts.paddings[1];
        cellCssModel[ 'padding-right' ] = ts.paddings[2];
        cellCssModel[ 'padding-bottom' ]= ts.paddings[3];
    }




    function model2inlcss({
        rix,
        cix,
        dtable,
        tscale,
        cell$,
    }) {
        var dataId = dtable.context.dtcard.dataId;
        var cellCssModel = dtable.cssModel__rows[ rix ][ cix ];
        if( rix === 0 ) {

            var ts = dtable.style8data_tpl;
            var cellwidth = cellCssModel.width * 0.01 * ts.width * tscale;

            //debugs html/pdf table synch
            /*
            ccc( cix+ ' html major cell width=' + ( cellCssModel.width * 0.01 * ts.width ).toFixed(1) );
            ccc( cix+ ' html cell inner width=' + (
                    cellCssModel.width * 0.01 * ts.width - cellCssModel[ 'padding-left' ] -
                    cellCssModel[ 'padding-right' ]
                ).toFixed(1)
            );
            */
            cell$.css( 'width', cellwidth.toFixed(2) + 'px' );
        }
        //editable styles
        var conv = fmethods.style__modelValue2cssValue;
        Object.keys( ns.fconf.editableCss ).forEach( propName => {
            var propValue = cellCssModel[ propName ];

            //good effect: condences cell's lines vertically
            //.css( 'line-height', ts.editableCss.lineHeight.toFixed(2) )

            if( propName === 'fontSize' || propName ==='borderWidth' ) {
                //dashboard scaling correction
                propValue *= tscale;
            }
            var {propName_css, propValue_css} = conv({ propName, propValue, });
            var cssTarget$ = dataId === 'horizontal-line' &&
                             propName === 'backgroundColor' ?
                                //todo ... this is a patch ... not a design,
                                //changes css of 'hr' not of cell$
                                //cell$.q( 'hr' ).css( propName_css, propValue_css );
                                $$.$( cell$().querySelector( 'hr' ) )
                                :
                                cell$;
            cssTarget$.css( propName_css, propValue_css );
        });

        //todm ... patch ... will confuse programmer ...,
        //padding should belong to editable props,
        var cssTarget$ = dataId === 'horizontal-line' ?
                            $$.$( cell$().querySelector( 'hr' ) ) :
                            cell$;
        cssTarget$
            .css( 'padding-left',   ( cellCssModel[ 'padding-left' ] * tscale ).toFixed(4) + 'px' )
            .css( 'padding-top',    ( cellCssModel[ 'padding-top' ] * tscale ).toFixed(4) + 'px' )
            .css( 'padding-right',  ( cellCssModel[ 'padding-right' ] * tscale ).toFixed(4) + 'px' )
            .css( 'padding-bottom', ( cellCssModel[ 'padding-bottom' ] * tscale ).toFixed(4) + 'px' )
            ;
    }

}) ();

