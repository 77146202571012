
//*******************************************************************
//          Lite-weight PDF creator wrapper based on pdfmake.
//          Part of b$l = Beaver $cript Library.
//          Licenses: MIT, GPL, GPL2.
//*******************************************************************


import '../bsl/core/ns.js'
import '../bsl/core/load-images.js'
import '../bsl/core/string-formatters.js'

import './core/add-card-to-pdf-export.js'
import './core/check-doc-def-debug.js'
import './core/build-doc-def.js'
import './core/load-charts.js'

import './images-list.conf.js'

import './fonts/vfs_arial.js';
import './fonts/vfs_arialnova.js';
import './fonts/vfs_fonts.roboto.js';

//==================================================
// //\\ sets namespaces for plugin
//==================================================
var ns          = window.b$l        = window.b$l        || {};
var $$          = ns.$$;
var fconf       = ns.fconf;
var methods     = ns.methods        = ns.methods        || {};
var nheap       = ns.nheap          = ns.nheap          || {};
var ddDef       = nheap.ddDef       = nheap.ddDef       || {};
//==================================================
// \\// sets namespaces for plugin
//==================================================
var ccc         = console.log; ccc && ( ccc = console.log );

nheap.downloadButton$ = null;
nheap.previewButton$ = null;
var ifr_window = null;
var ifr_elem = null;
var apiInitiated = false;
export default ns;












(function () {

    var stashedButtonName = '';

    methods.pdf_perAppOneTimeSetup = pdf_perAppOneTimeSetup;
    methods.pdf__places_iframe_preview_downl_2_html_placeholders =
            pdf__places_iframe_preview_downl_2_html_placeholders;
    methods.enableButton = enableButton;
    methods.PM__establish8executeOnDoc = PM__establish8executeOnDoc;
    methods.provides_multNightButton_to_pdf = provides_multNightButton_to_pdf;
    methods.pdf__recreate_preview8downl_buttons =
            pdf__recreate_preview8downl_buttons;
    return;





    function pdf_perAppOneTimeSetup()
    {
        if( !apiInitiated ) {
            //needs document.body already existing
            ns.createDebugger();
            createDocIframe();
            apiInitiated = true;
        }
    }

    ///places pdf controls into html-placeholders
    function pdf__places_iframe_preview_downl_2_html_placeholders({
            controlPanel_csspath,
            pdfPreviewContainer_csspath
    }) {
        //iframe
        $$.q( pdfPreviewContainer_csspath )().appendChild( ifr_elem );
        ifr_window = ifr_elem.contentWindow;
        //preview
        var controlPanel = $$.q( controlPanel_csspath )();
        controlPanel.insertBefore( nheap.previewButton$(), controlPanel.children[0] );
        //download
        if( fconf.ENABLE_REP_EDITOR ) {
            controlPanel.insertBefore( nheap.downloadButton$(), controlPanel.children[0] );
        }
    }





    //===================================================
    // //\\ after doc.defs, prepares and runs pdmax
    //
    //      can be doc reopen many times?:
    //      https://stackoverflow.com/questions/34001513/
    //           pdfmake-callback-when-export-is-finished
    //
    //===================================================
    function PM__establish8executeOnDoc( win, pdfTask )
    {
        var pdfCreated = null;
        //============================================================================
        // //\\ prepares pdfmake
        //============================================================================

        //.pdfmake changes original ddDef ... but sometimes we need
        //.preserved original, so we stash the original
        nheap.ddDefCloned = ns.paste( {}, ddDef );
        //ccc( 'ddDef====cloned=', nheap.ddDefCloned, 'origin=', ddDef );
        var pdfMake = window.pdfMake;
        establishes_fonts( pdfMake );


        try{
            // alternatively
            // pdfCreated = pdfMake.createPdf( nheap.ddDefCloned, null, fapp.fonts );
            //ccc( nheap.ddDefCloned );
            pdfCreated = pdfMake.createPdf( nheap.ddDefCloned );
            if( pdfTask === 'preview' ) {
                win = win || window.open('', '_blank');

                pdfCreated.open( {}, win );
                ifr_elem.style.zIndex = '100000';
                ifr_elem.style.visibility = 'visible';
                nheap.previewButton$.html( 'Hide preview' );
                enableButton( nheap.previewButton$, true );

            } else {
                //ccc( 'data of download=', nheap.ddDefCloned )
                runDownload();
            }
        
        } catch (err) {
            ccc( 'Error when ' + (pdfTask==='preview'?'open':'download'), err );
            return;
        }
        return;



        function runDownload()
        {
            var stashedHTML = nheap.downloadButton$().innerHTML;
            nheap.downloadButton$.html( "Preparing PDF ... " );
            setTimeout( function()
                {
                    try{
                        var fname = fconf.CONTROL_PANELS.CAPTION[ 'USER' ];
                        pdfCreated.download( 
                            ( fname || '')
                                .replace( / |\t/g, '-' ),
                            function(arg) {
                                if( fconf.ENABLE_REP_EDITOR ) {
                                    //prevents from reuse
                                    //nheap.downloadButton$.css('display','none');
                                    enableButton( nheap.downloadButton$, true );

                                } else {
                                    nheap.downloadButton$().innerHTML = stashedButtonName;
                                    enableButton( nheap.downloadButton$, true );
                                }
                                nheap.downloadButton$.html( stashedHTML );
                            }
                        );
                    } catch (err) {
                        alert( 'Download error:' + err );
                    }
                },
                100
            );
        }
    }
    //===============================================
    // \\// after doc.defs, prepares and runs pdmax
    //===============================================





    ///==========================================
    /// gui helper
    ///==========================================
    ///safely deletes and inits
    function pdf__recreate_preview8downl_buttons()
    {
        //cleans up possible instances of previous pdfmake lifecycle
        $$.$del( nheap, 'downloadButton$' );
        $$.$del( nheap, 'previewButton$' );

        nheap.downloadButton$ = ns.$$
            .c('div')
            .addClass( 'cp-button doc-level-controls' )
            .e( 'click', ev => {
                methods.pdfmake8pdfdoc__lifecycle({ pdfTask:'download' });
            })
            .ch(
                ns.$$.c( 'i' )
                  .addClass( 'fa fa-download' )
                  .a( 'title', 'Download PDF document' )
            )
            ;

        nheap.previewButton$ = ns.$$
            .c('div')
            .addClass( 'cp-button doc-level-controls' )
            .e( 'click', ev => {
                showPDF();
            })
            .ch(
                ns.$$.c( 'i' )
                  .addClass( 'fa fa-file-pdf-o' )
                  .a( 'title', 'Preview PDF document' )
            )
            ;
        if( fconf.hidePreviewButton ) {
            nheap.previewButton$.css( 'display', 'none' );
        }
        if( fconf.hideDownloadButton ) {
            nheap.downloadButton$.css( 'display', 'none' );
        }
    }


    function provides_multNightButton_to_pdf()
    {
        nheap.downloadButton$ = ns.$$.q( '.multi-night-pdf-button' );
        stashedButtonName = nheap.downloadButton$().innerHTML;
    }

    ///==========================================
    /// gui helper
    ///==========================================
    function showPDF()
    {
        if( ifr_elem.style.visibility === 'visible' ) {
            ////hides PDF and disables all PDF-works
            ifr_elem.style.visibility = 'hidden';
            ifr_elem.style.zIndex = '-1';
            if( fconf.ENABLE_REP_EDITOR ) {
                enableButton( nheap.downloadButton$, true );
                nheap.previewButton$
                    .html( '' )
                    .ch(
                        ns.$$.c( 'i' )
                          .addClass( 'fa fa-file-pdf-o' )
                          .a( 'title', 'Preview PDF document' )
                    )
                    ;
            }

        } else {
            ////begins PDF-works
            methods.pdfmake8pdfdoc__lifecycle({ win:ifr_window, pdfTask:'preview' });
            nheap.previewButton$.html( "Preparing PDF preview" ).css( "display", "inline" )
        }
    }




    //==============================================
    // //\\ sets doc placeholder in iframe
    //==============================================
    function createDocIframe()
    {
        //https://www.dyn-web.com/tutorials/iframes/refs/iframe.php
        //https://stackoverflow.com/questions/251420
        //  /invoking-javascript-code-in-an-iframe-from-the-parent-page
        //window.frames[0].frameElement.contentWindow....
        ifr_elem = document.createElement('iframe');

        ifr_elem.style.position = 'absolute';
        ifr_elem.style.zIndex = '-1';
        ifr_elem.style.visibility = 'hidden';
        ifr_elem.style.left = '1px';
        ifr_elem.style.top = fconf.CONTROL_PANELS.APP_CP_HEIGHT +'px';
        ifr_elem.style.width = 'calc( 100% - 3px )';

        ifr_elem.style.height = "calc( 100% - " + fconf.CONTROL_PANELS.APP_CP_HEIGHT  + 'px )';

        //using reference to iframe (ifr_elem) obtained above
        //var win = ifr_elem.contentWindow; // reference to iframe's window
        //reference to document in iframe
        //var doc = ifr_elem.contentDocument || ifr_elem.contentWindow.document;
        //return win;
    }
    //==============================================
    // \\// sets doc placeholder in iframe
    //==============================================


    function establishes_fonts( pdfMake )
    {
        //============================================================================
        // //\\ fonts and vfs
        //============================================================================
        //By some reason, font files provided in vendor-distribution and
        //generated by vendor-distribuiton-tools have different root for
        //the fonts: in vendor-distribution the root is window.pdfMake.vfs, but
        //in other case, the root is window.vfs
        //In the following line, the custom vfs, if exists, overrides the legacy:

        //ccc( 'establishing fonts: window.vfs=', window.vfs );
        //ccc( 'establishing fonts: pdfMake.vfs=', pdfMake.vfs );

        pdfMake.vfs = window.vfs || pdfMake.vfs || {};

        //for pdfmake version 0.2.... pdfMake.addVirtualFileSystem(window.vfs)
        ns.paste( pdfMake.vfs, window.vfs_arialnova );
        ns.paste( pdfMake.vfs, window.vfs_arial );
        ns.paste( pdfMake.vfs, window.vfs_roboto );
        //*************************************************************
        //c cc( 'shows all fonts which we have=', pdfMake.vfs );
        //*************************************************************

        pdfMake.fonts =
        {
            /*
            icons: {
                normal: "icons.ttf",
                regular: "icons.ttf",
                bold: "icons.ttf",
            },
            */
            //-------------------------------
            // //\\ optional section
            //-------------------------------
            Arial:
            {
                bold: 'Arial-BoldMT.ttf',
                normal : 'ArialMT.ttf',
                regular : 'ArialMT.ttf',
                bolditalics : 'Arial-BoldItalicMT.ttf',
                italics : 'Arial-ItalicMT.ttf',
            },
            ArialNova:
            {
                bold: 'ArialNova-Bold.ttf',
                normal : 'ArialNova.ttf',
                regular : 'ArialNova.ttf',
                bolditalics : 'ArialNova-BoldItalic.ttf',
                italics : 'ArialNova-Italic.ttf',
            },
            //-------------------------------
            // \\// optional section
            //-------------------------------

            ///todm: app does request it at somepoint ...
            ///      it this because of missed explicit Arial font?
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
        };
        //============================================================================
        // \\// fonts and vfs
        // \\// prepares pdfmake
        //============================================================================
    }


    ///==========================================
    /// gui helper
    ///==========================================
    function enableButton( button$, true0false )
    {
        if( true0false ) {
            button$().removeAttribute( 'disabled' );
            button$().style.removeProperty( 'background-color' );
            button$.css( "cursor", "pointer" )
            ;
        } else {
            button$
                .a( 'disabled', 'yes' )
                .css( 'background-color', fconf.ENABLE_REP_EDITOR ? 'grey' : '#eeeeee' )
                .css( "cursor", "auto" )
                ;
        }
    }

})();

