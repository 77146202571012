//---------------------------------------------------------------------
//.for dev mode ... remove for live
import mockd from '../../dev-demo-static-cards/patient-data.js';
//---------------------------------------------------------------------

( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;
    var fapp        = sn('fapp' ); 
    var fheap       = ns.sn('fheap', fapp);
    var fmethods    = ns.sn('methods', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );

    fmethods.getDevModeReports = getDevModeReports;
    return;







    //fetches reports from ReportManager associated with the current patient
    function getDevModeReports({ organization, location_match_params, callBack })
    {
        //// this is a developer mode;
        //// ignore it in production version
        var data = {
            limit: 50
        }
    	data.organizationId = organization.id || 0;
        mockd.dataCount = mockd.dataCount || 0;
        mockd.rows[1].data[ 'spo2_rdi_overall' ] = 22+mockd.dataCount;
        mockd.rows[1].data[ 'spo2_rdi_rera_desat_2To4Percent' ] = 22+mockd.dataCount;
        mockd.rows[1].data[ 'spo2_rdi_rera_desat_2To4Percent' ] = 22+mockd.dataCount;
        fheap.data_rows = mockd.rows;
        //ccc( ' mock reports arrived=', fheap.data_rows );
        fmethods.docModel_8_pagesCardsInterns();
    }

}) ();

