export default {
    "count": 9,
    "rows": [
        {
            "data": {
                "pulse_arousalEvent_count": 10,
                "pulse_arousalEvent_pr_increase_mean": 14.3,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:18",
                "pulse_arousalEvent_desat_mean": 2.550002336502075,
                "pulse_arousalEvent_desat_min": 2,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 1,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 3,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 4,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 1,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 1,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 1.2291974298599195,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 72.59049367442232,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 23.262960450173605,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 2.143113700762262,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.6345532186614519,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.1396815261204454,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:05:08",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:03:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:37:09",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:08:57",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:02:39",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:35",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:00",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 21,
                "pulse_cluster_duration_cumulative": "01:44:00",
                "pulse_prvEvent_duration_cumulative": "01:44:08",
                "pulse_prvEvent_durationPercentage_cumulative": 20.65864372253418,
                "pulse_prvEvent_duration_mean": "00:06:56",
                "pulse_max": 94,
                "pulse_min": 46,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:35",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:12:03",
                "spo2_cluster_duration_mean": "00:03:58",
                "spo2_cluster_duration_cumulative_1": "00:03:58",
                "spo2_cluster_durationPercentage_cumulative_1": 0.7869329452514648,
                "spo2_cluster_oxygenDeficitIndex": 0.008989876136183739,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0.4313788115978241,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": 0.03327208757400513,
                "spo2_startEvent_duration_mean": "00:00:17",
                "spo2_startEvent_magnitude_mean": -2.7000019550323486,
                "spo2_startEvent_slope_mean": -0.23557895421981812,
                "spo2_endEvent_duration_mean": "00:00:17",
                "spo2_endEvent_slope_mean": 0.26787909865379333,
                "spo2_reciprocation_recoveryDuration_mean": "00:01:21",
                "spo2_reciprocation_recoveryRatio_mean": 4.34375,
                "spo2_reciprocation_cumulativeDuration": "00:01:42",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:32",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 5,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:07:58",
                "AvgEventDuration": "00:00:40",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 7,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:08:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 1,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:26",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:04",
                "spo2_sat_min": 82,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 90,
                "spo2_desatEvent_sat_min_mean": 96,
                "spo2_sat_min_lte88Percent_mean": 85,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "06:51:49",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:41",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:26",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "06:57:37",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:32",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "06:51:28",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 99,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 99,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 4.1,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100.1,
                "spo2_cluster_durationPercentage_cumulative_2": 1,
                "spo2_cluster_duration_cumulative_2": "00:03:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 1,
                "pulse_arousalEvent_index_countPerHour": 1,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 45,
                "spo2_meanHighestNadirDifference": 16,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 1,
                "spo2_rdi_rera_desat_2To4Percent": 1,
                "spo2_rdi_rera_desat_2To3Percent": 1,
                "spo2_rdi_overall": 2,
                "spo2_rdi_desat_gt4Percent": 1,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 18,
                "artifact_warningErrorSignal": 17.15,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:23",
                "artifact_timeExcludedArtifactDuration": "01:26:28",
                "motion_timeMotionArtifactTotal": 0.08,
                "artifact_timeExcludedArtifactTotal": 17.15,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 99,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 1,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 4,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 1,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 1,
            "guid": "b34adfea-d0b9-4a4c-a783-5b22e85bf5e6",
            "startTime": "2018-10-25T03:37:15.000Z",
            "endTime": "2018-10-25T12:01:20.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "b34adfea-d0b9-4a4c-a783-5b22e85bf5e6.edf",
            "pdf": "b34adfea-d0b9-4a4c-a783-5b22e85bf5e6.pdf",
            "createdAt": "2019-01-10T02:29:21.000Z",
            "updatedAt": "2019-10-21T13:28:17.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 10,
                "pulse_arousalEvent_pr_increase_mean": 14.3,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:18",
                "pulse_arousalEvent_desat_mean": 2.550002336502075,
                "pulse_arousalEvent_desat_min": 2,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 1,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 3,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 4,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 1,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 1,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 1.2291974298599195,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 72.59049367442232,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 23.262960450173605,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 2.143113700762262,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.6345532186614519,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.1396815261204454,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:05:08",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:03:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:37:09",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:08:57",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:02:39",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:35",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:00",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 21,
                "pulse_cluster_duration_cumulative": "01:44:00",
                "pulse_prvEvent_duration_cumulative": "01:44:08",
                "pulse_prvEvent_durationPercentage_cumulative": 20.65864372253418,
                "pulse_prvEvent_duration_mean": "00:06:56",
                "pulse_max": 94,
                "pulse_min": 46,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:35",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:12:03",
                "spo2_cluster_duration_mean": "00:03:58",
                "spo2_cluster_duration_cumulative_1": "00:03:58",
                "spo2_cluster_durationPercentage_cumulative_1": 0.7869329452514648,
                "spo2_cluster_oxygenDeficitIndex": 0.008989876136183739,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0.4313788115978241,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": 0.03327208757400513,
                "spo2_startEvent_duration_mean": "00:00:17",
                "spo2_startEvent_magnitude_mean": -2.7000019550323486,
                "spo2_startEvent_slope_mean": -0.23557895421981812,
                "spo2_endEvent_duration_mean": "00:00:17",
                "spo2_endEvent_slope_mean": 0.26787909865379333,
                "spo2_reciprocation_recoveryDuration_mean": "00:01:21",
                "spo2_reciprocation_recoveryRatio_mean": 4.34375,
                "spo2_reciprocation_cumulativeDuration": "00:01:42",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:32",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 5,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:07:58",
                "AvgEventDuration": "00:00:40",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 7,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:08:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 1,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:26",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:04",
                "spo2_sat_min": 82,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 90,
                "spo2_desatEvent_sat_min_mean": 96,
                "spo2_sat_min_lte88Percent_mean": 85,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "06:51:49",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:41",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:26",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "06:57:37",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:32",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "06:51:28",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 99,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 99,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 4.1,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100.1,
                "spo2_cluster_durationPercentage_cumulative_2": 1,
                "spo2_cluster_duration_cumulative_2": "00:03:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 1,
                "pulse_arousalEvent_index_countPerHour": 1,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 45,
                "spo2_meanHighestNadirDifference": 16,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 1,
                "spo2_rdi_rera_desat_2To4Percent": 1,
                "spo2_rdi_rera_desat_2To3Percent": 1,
                "spo2_rdi_overall": 2,
                "spo2_rdi_desat_gt4Percent": 1,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 18,
                "artifact_warningErrorSignal": 17.15,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:23",
                "artifact_timeExcludedArtifactDuration": "01:26:28",
                "motion_timeMotionArtifactTotal": 0.08,
                "artifact_timeExcludedArtifactTotal": 17.15,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 99,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 1,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 4,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 1,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 2,
            "guid": "3fc3ef08-31a1-45df-a825-3432405249bd",
            "startTime": "2018-10-25T03:37:15.000Z",
            "endTime": "2018-10-25T12:01:20.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "3fc3ef08-31a1-45df-a825-3432405249bd.edf",
            "pdf": "3fc3ef08-31a1-45df-a825-3432405249bd.pdf",
            "createdAt": "2019-01-10T04:35:29.000Z",
            "updatedAt": "2019-10-21T13:28:21.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 0,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:00",
                "pulse_arousalEvent_desat_mean": 0,
                "pulse_arousalEvent_desat_min": 0,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 0,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 0,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 0,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 0,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 0,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 2.6820067898906075,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 76.08072425499812,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 19.27574500188608,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 1.5692191625801586,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.3055450773293097,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.04526593738211995,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0.04149377593360996,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:11:51",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:36:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:25:10",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:06:56",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:01:21",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:12",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:11",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 23,
                "pulse_cluster_duration_cumulative": "01:42:00",
                "pulse_prvEvent_duration_cumulative": "01:42:31",
                "pulse_prvEvent_durationPercentage_cumulative": 23.158885955810547,
                "pulse_prvEvent_duration_mean": "00:06:24",
                "pulse_max": 126,
                "pulse_min": 43,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:23",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:24:00",
                "spo2_cluster_duration_mean": "00:00:00",
                "spo2_cluster_duration_cumulative_1": "00:00:00",
                "spo2_cluster_durationPercentage_cumulative_1": 0,
                "spo2_cluster_oxygenDeficitIndex": 0,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": "NaN",
                "spo2_startEvent_duration_mean": "00:00:00",
                "spo2_startEvent_magnitude_mean": 0,
                "spo2_startEvent_slope_mean": 0,
                "spo2_endEvent_duration_mean": "00:00:00",
                "spo2_endEvent_slope_mean": 0,
                "spo2_reciprocation_recoveryDuration_mean": "00:00:00",
                "spo2_reciprocation_recoveryRatio_mean": 0,
                "spo2_reciprocation_cumulativeDuration": "00:00:00",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:00",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 0,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:02:27",
                "AvgEventDuration": "00:01:34",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 6,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:09:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 0,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:00",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:00",
                "spo2_sat_min": 92,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 92,
                "spo2_desatEvent_sat_min_mean": 95,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "07:21:35",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:15",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 100,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 3.5,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96.5,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100,
                "spo2_cluster_durationPercentage_cumulative_2": 0,
                "spo2_cluster_duration_cumulative_2": "00:00:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 0,
                "pulse_arousalEvent_index_countPerHour": 0,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 0,
                "spo2_meanHighestNadirDifference": 0,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 0,
                "spo2_rdi_rera_desat_2To4Percent": 0,
                "spo2_rdi_rera_desat_2To3Percent": 0,
                "spo2_rdi_overall": 0,
                "spo2_rdi_desat_gt4Percent": 0,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 0,
                "artifact_warningErrorSignal": 0.19,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:00",
                "artifact_timeExcludedArtifactDuration": "00:00:51",
                "motion_timeMotionArtifactTotal": 0,
                "artifact_timeExcludedArtifactTotal": 0.19,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 0,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 1,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 0,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 3,
            "guid": "7c406b36-3cfd-4e83-a3ab-4bbf6df1417a",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "7c406b36-3cfd-4e83-a3ab-4bbf6df1417a.edf",
            "pdf": "7c406b36-3cfd-4e83-a3ab-4bbf6df1417a.pdf",
            "createdAt": "2019-01-10T22:55:27.000Z",
            "updatedAt": "2019-10-21T14:11:54.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 0,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:00",
                "pulse_arousalEvent_desat_mean": 0,
                "pulse_arousalEvent_desat_min": 0,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 0,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 0,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 0,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 0,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 0,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 2.6820067898906075,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 76.08072425499812,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 19.27574500188608,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 1.5692191625801586,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.3055450773293097,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.04526593738211995,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0.04149377593360996,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:11:51",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:36:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:25:10",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:06:56",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:01:21",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:12",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:11",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 23,
                "pulse_cluster_duration_cumulative": "01:42:00",
                "pulse_prvEvent_duration_cumulative": "01:42:31",
                "pulse_prvEvent_durationPercentage_cumulative": 23.158885955810547,
                "pulse_prvEvent_duration_mean": "00:06:24",
                "pulse_max": 126,
                "pulse_min": 43,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:23",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:24:00",
                "spo2_cluster_duration_mean": "00:00:00",
                "spo2_cluster_duration_cumulative_1": "00:00:00",
                "spo2_cluster_durationPercentage_cumulative_1": 0,
                "spo2_cluster_oxygenDeficitIndex": 0,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": "NaN",
                "spo2_startEvent_duration_mean": "00:00:00",
                "spo2_startEvent_magnitude_mean": 0,
                "spo2_startEvent_slope_mean": 0,
                "spo2_endEvent_duration_mean": "00:00:00",
                "spo2_endEvent_slope_mean": 0,
                "spo2_reciprocation_recoveryDuration_mean": "00:00:00",
                "spo2_reciprocation_recoveryRatio_mean": 0,
                "spo2_reciprocation_cumulativeDuration": "00:00:00",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:00",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 0,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:02:27",
                "AvgEventDuration": "00:01:34",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 6,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:09:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 0,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:00",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:00",
                "spo2_sat_min": 92,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 92,
                "spo2_desatEvent_sat_min_mean": 95,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "07:21:35",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:15",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 100,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 3.5,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96.5,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100,
                "spo2_cluster_durationPercentage_cumulative_2": 0,
                "spo2_cluster_duration_cumulative_2": "00:00:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 0,
                "pulse_arousalEvent_index_countPerHour": 0,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 0,
                "spo2_meanHighestNadirDifference": 0,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 0,
                "spo2_rdi_rera_desat_2To4Percent": 0,
                "spo2_rdi_rera_desat_2To3Percent": 0,
                "spo2_rdi_overall": 0,
                "spo2_rdi_desat_gt4Percent": 0,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 0,
                "artifact_warningErrorSignal": 0.19,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:00",
                "artifact_timeExcludedArtifactDuration": "00:00:51",
                "motion_timeMotionArtifactTotal": 0,
                "artifact_timeExcludedArtifactTotal": 0.19,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 0,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 1,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 0,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 4,
            "guid": "5303b270-0f2f-4956-b1f3-0846e2394422",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "5303b270-0f2f-4956-b1f3-0846e2394422.edf",
            "pdf": "5303b270-0f2f-4956-b1f3-0846e2394422.pdf",
            "createdAt": "2019-01-15T02:25:55.000Z",
            "updatedAt": "2019-10-21T14:12:05.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 0,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:00",
                "pulse_arousalEvent_desat_mean": 0,
                "pulse_arousalEvent_desat_min": 0,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 0,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 0,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 0,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 0,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 0,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 2.6820067898906075,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 76.08072425499812,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 19.27574500188608,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 1.5692191625801586,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.3055450773293097,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.04526593738211995,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0.04149377593360996,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:11:51",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:36:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:25:10",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:06:56",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:01:21",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:12",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:11",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 23,
                "pulse_cluster_duration_cumulative": "01:42:00",
                "pulse_prvEvent_duration_cumulative": "01:42:31",
                "pulse_prvEvent_durationPercentage_cumulative": 23.158885955810547,
                "pulse_prvEvent_duration_mean": "00:06:24",
                "pulse_max": 126,
                "pulse_min": 43,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:23",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:24:00",
                "spo2_cluster_duration_mean": "00:00:00",
                "spo2_cluster_duration_cumulative_1": "00:00:00",
                "spo2_cluster_durationPercentage_cumulative_1": 0,
                "spo2_cluster_oxygenDeficitIndex": 0,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": "NaN",
                "spo2_startEvent_duration_mean": "00:00:00",
                "spo2_startEvent_magnitude_mean": 0,
                "spo2_startEvent_slope_mean": 0,
                "spo2_endEvent_duration_mean": "00:00:00",
                "spo2_endEvent_slope_mean": 0,
                "spo2_reciprocation_recoveryDuration_mean": "00:00:00",
                "spo2_reciprocation_recoveryRatio_mean": 0,
                "spo2_reciprocation_cumulativeDuration": "00:00:00",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:00",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 0,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:02:27",
                "AvgEventDuration": "00:01:34",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 6,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:09:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 0,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:00",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:00",
                "spo2_sat_min": 92,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 92,
                "spo2_desatEvent_sat_min_mean": 95,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "07:21:35",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:15",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 100,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 3.5,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96.5,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100,
                "spo2_cluster_durationPercentage_cumulative_2": 0,
                "spo2_cluster_duration_cumulative_2": "00:00:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 0,
                "pulse_arousalEvent_index_countPerHour": 0,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 0,
                "spo2_meanHighestNadirDifference": 0,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 0,
                "spo2_rdi_rera_desat_2To4Percent": 0,
                "spo2_rdi_rera_desat_2To3Percent": 0,
                "spo2_rdi_overall": 0,
                "spo2_rdi_desat_gt4Percent": 0,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 0,
                "artifact_warningErrorSignal": 0.19,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:00",
                "artifact_timeExcludedArtifactDuration": "00:00:51",
                "motion_timeMotionArtifactTotal": 0,
                "artifact_timeExcludedArtifactTotal": 0.19,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 0,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 1,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 0,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 5,
            "guid": "5ef1581b-d994-4ad4-a81d-883b1aca0be4",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "5ef1581b-d994-4ad4-a81d-883b1aca0be4.edf",
            "pdf": "5ef1581b-d994-4ad4-a81d-883b1aca0be4.pdf",
            "createdAt": "2019-01-15T04:23:23.000Z",
            "updatedAt": "2019-10-21T19:29:58.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 10,
                "pulse_arousalEvent_pr_increase_mean": 14.3,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:18",
                "pulse_arousalEvent_desat_mean": 2.550002336502075,
                "pulse_arousalEvent_desat_min": 2,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 1,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 3,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 4,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 1,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 1,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 1.2291974298599195,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 72.59049367442232,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 23.262960450173605,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 2.143113700762262,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.6345532186614519,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.1396815261204454,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:05:08",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:03:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:37:09",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:08:57",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:02:39",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:35",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:00",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 21,
                "pulse_cluster_duration_cumulative": "01:44:00",
                "pulse_prvEvent_duration_cumulative": "01:44:08",
                "pulse_prvEvent_durationPercentage_cumulative": 20.65864372253418,
                "pulse_prvEvent_duration_mean": "00:06:56",
                "pulse_max": 94,
                "pulse_min": 46,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:35",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:12:03",
                "spo2_cluster_duration_mean": "00:03:58",
                "spo2_cluster_duration_cumulative_1": "00:03:58",
                "spo2_cluster_durationPercentage_cumulative_1": 0.7869329452514648,
                "spo2_cluster_oxygenDeficitIndex": 0.008989876136183739,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0.4313788115978241,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": 0.03327208757400513,
                "spo2_startEvent_duration_mean": "00:00:17",
                "spo2_startEvent_magnitude_mean": -2.7000019550323486,
                "spo2_startEvent_slope_mean": -0.23557895421981812,
                "spo2_endEvent_duration_mean": "00:00:17",
                "spo2_endEvent_slope_mean": 0.26787909865379333,
                "spo2_reciprocation_recoveryDuration_mean": "00:01:21",
                "spo2_reciprocation_recoveryRatio_mean": 4.34375,
                "spo2_reciprocation_cumulativeDuration": "00:01:42",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:32",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 5,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:07:58",
                "AvgEventDuration": "00:00:40",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 7,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:08:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 1,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:26",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:04",
                "spo2_sat_min": 82,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 90,
                "spo2_desatEvent_sat_min_mean": 96,
                "spo2_sat_min_lte88Percent_mean": 85,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "06:51:49",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:41",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:26",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "06:57:37",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:32",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "06:51:28",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 99,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 99,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 4.1,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100.1,
                "spo2_cluster_durationPercentage_cumulative_2": 1,
                "spo2_cluster_duration_cumulative_2": "00:03:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 1,
                "pulse_arousalEvent_index_countPerHour": 1,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 45,
                "spo2_meanHighestNadirDifference": 16,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 1,
                "spo2_rdi_rera_desat_2To4Percent": 1,
                "spo2_rdi_rera_desat_2To3Percent": 1,
                "spo2_rdi_overall": 2,
                "spo2_rdi_desat_gt4Percent": 1,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 18,
                "artifact_warningErrorSignal": 17.15,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:23",
                "artifact_timeExcludedArtifactDuration": "01:26:28",
                "motion_timeMotionArtifactTotal": 0.08,
                "artifact_timeExcludedArtifactTotal": 17.15,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 99,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 1,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 4,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 1,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 6,
            "guid": "fcac23a9-6391-436e-b0d0-8aa8e2f4e8d3",
            "startTime": "2018-10-25T03:37:15.000Z",
            "endTime": "2018-10-25T12:01:20.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "fcac23a9-6391-436e-b0d0-8aa8e2f4e8d3.edf",
            "pdf": "fcac23a9-6391-436e-b0d0-8aa8e2f4e8d3.pdf",
            "createdAt": "2019-01-18T02:05:09.000Z",
            "updatedAt": "2019-10-21T13:28:24.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": 0,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": "00:00:00",
                "pulse_arousalEvent_desat_mean": 0,
                "pulse_arousalEvent_desat_min": 0,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": 0,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": 0,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": 0,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": 0,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": 0,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": 0,
                "pulse_arousalEvent_pr_max_gte100_count": 0,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": 2.6820067898906075,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": 76.08072425499812,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": 19.27574500188608,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": 1.5692191625801586,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": 0.3055450773293097,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": 0.04526593738211995,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": 0.04149377593360996,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": "00:11:51",
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": "05:36:09",
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": "01:25:10",
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": "00:06:56",
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": "00:01:21",
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": "00:00:12",
                "pulse_pr_gte100_vrt_duration_cumulative": "00:00:11",
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": 23,
                "pulse_cluster_duration_cumulative": "01:42:00",
                "pulse_prvEvent_duration_cumulative": "01:42:31",
                "pulse_prvEvent_durationPercentage_cumulative": 23.158885955810547,
                "pulse_prvEvent_duration_mean": "00:06:24",
                "pulse_max": 126,
                "pulse_min": 43,
                "pulse_tachycardia_pr_gte90_duration_cumulative": "00:00:23",
                "pulse_bradycardia_pr_lte50_duration_cumulative": "00:24:00",
                "spo2_cluster_duration_mean": "00:00:00",
                "spo2_cluster_duration_cumulative_1": "00:00:00",
                "spo2_cluster_durationPercentage_cumulative_1": 0,
                "spo2_cluster_oxygenDeficitIndex": 0,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": 0,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": "NaN",
                "spo2_startEvent_duration_mean": "00:00:00",
                "spo2_startEvent_magnitude_mean": 0,
                "spo2_startEvent_slope_mean": 0,
                "spo2_endEvent_duration_mean": "00:00:00",
                "spo2_endEvent_slope_mean": 0,
                "spo2_reciprocation_recoveryDuration_mean": "00:00:00",
                "spo2_reciprocation_recoveryRatio_mean": 0,
                "spo2_reciprocation_cumulativeDuration": "00:00:00",
                "spo2_isArtifactRejectionUsed": true,
                "spo2_sat_lt90_vrt_duration_cumulative": "00:00:00",
                "meanSPO2VRT": -1,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": 0,
                "spo2_od4_desatEvent_desat_gte4Percent_count": 1,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": "00:02:27",
                "AvgEventDuration": "00:01:34",
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": 1,
                "spo2_od3_desatEvent_desat_gte3Percent_count": 6,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": "00:09:23",
                "spo2_desatEvent_sat_min_lte88Percent_count": 0,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": "00:00:00",
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": "00:00:00",
                "spo2_sat_min": 92,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": 92,
                "spo2_desatEvent_sat_min_mean": 95,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": "07:21:35",
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": "00:00:15",
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_lte70_vrt_duration_cumulative": "00:00:00",
                "spo2_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": "00:00:00",
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": "07:21:50",
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": 0,
                "spo2_vrt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": 0,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": 100,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": 3.5,
                "spo2_satBaseline_initialHighSaturationBaseline": 100,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": 96.5,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": 100,
                "spo2_cluster_durationPercentage_cumulative_2": 0,
                "spo2_cluster_duration_cumulative_2": "00:00:00",
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": 0,
                "pulse_arousalEvent_index_countPerHour": 0,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": 0,
                "spo2_meanHighestNadirDifference": 0,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": 0,
                "spo2_rdi_rera_desat_2To4Percent": 0,
                "spo2_rdi_rera_desat_2To3Percent": 0,
                "spo2_rdi_overall": 0,
                "spo2_rdi_desat_gt4Percent": 0,
                "spo2_rdi_desat_gt3Percent": 0,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": 0,
                "artifact_warningErrorSignal": 0.19,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": true,
                "artifact_warningArtifactImage1": true,
                "motion_timeMotionArtifactDuration": "00:00:00",
                "artifact_timeExcludedArtifactDuration": "00:00:51",
                "motion_timeMotionArtifactTotal": 0,
                "artifact_timeExcludedArtifactTotal": 0.19,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": 100,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": 0,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": 0,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": 0,
                "spo2_desatEvent_sat_min_gte90Lte100_count": 1,
                "spo2_desatEvent_sat_min_gte80Lt90_count": 0,
                "spo2_desatEvent_sat_min_gte70Lt80_count": 0,
                "spo2_desatEvent_sat_min_gte60Lt70_count": 0,
                "spo2_desatEvent_sat_min_gte50Lt60_count": 0,
                "spo2_desatEvent_sat_min_lt50_count": 0
            },
            "comments": null,
            "interpretation": null,
            "error": null,
            "id": 7,
            "guid": "513370cc-88e2-4141-9007-685271a41735",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "513370cc-88e2-4141-9007-685271a41735.edf",
            "pdf": "513370cc-88e2-4141-9007-685271a41735.pdf",
            "createdAt": "2019-01-18T03:46:33.000Z",
            "updatedAt": "2019-10-21T19:30:10.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": null,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": null,
                "pulse_arousalEvent_desat_mean": null,
                "pulse_arousalEvent_desat_min": null,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": null,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": null,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": null,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": null,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": null,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": null,
                "pulse_arousalEvent_pr_max_gte100_count": null,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": null,
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": null,
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": null,
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": null,
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": null,
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": null,
                "pulse_pr_gte100_vrt_duration_cumulative": null,
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": null,
                "pulse_cluster_duration_cumulative": null,
                "pulse_prvEvent_duration_cumulative": null,
                "pulse_prvEvent_durationPercentage_cumulative": null,
                "pulse_prvEvent_duration_mean": null,
                "pulse_max": null,
                "pulse_min": null,
                "pulse_tachycardia_pr_gte90_duration_cumulative": null,
                "pulse_bradycardia_pr_lte50_duration_cumulative": null,
                "spo2_cluster_duration_mean": null,
                "spo2_cluster_duration_cumulative_1": null,
                "spo2_cluster_durationPercentage_cumulative_1": null,
                "spo2_cluster_oxygenDeficitIndex": null,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": null,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": null,
                "spo2_startEvent_duration_mean": null,
                "spo2_startEvent_magnitude_mean": null,
                "spo2_startEvent_slope_mean": null,
                "spo2_endEvent_duration_mean": null,
                "spo2_endEvent_slope_mean": null,
                "spo2_reciprocation_recoveryDuration_mean": null,
                "spo2_reciprocation_recoveryRatio_mean": null,
                "spo2_reciprocation_cumulativeDuration": null,
                "spo2_isArtifactRejectionUsed": false,
                "spo2_sat_lt90_vrt_duration_cumulative": null,
                "meanSPO2VRT": null,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": null,
                "spo2_od4_desatEvent_desat_gte4Percent_count": null,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": null,
                "AvgEventDuration": null,
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": null,
                "spo2_od3_desatEvent_desat_gte3Percent_count": null,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": null,
                "spo2_desatEvent_sat_min_lte88Percent_count": null,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": null,
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": null,
                "spo2_sat_min": null,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": null,
                "spo2_desatEvent_sat_min_mean": null,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": null,
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": null,
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": null,
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": null,
                "spo2_sat_lte70_vrt_duration_cumulative": null,
                "spo2_vrt_duration_cumulative": null,
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": null,
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": null,
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": null,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": null,
                "spo2_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": null,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": null,
                "spo2_satBaseline_initialHighSaturationBaseline": null,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": null,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": null,
                "spo2_cluster_durationPercentage_cumulative_2": null,
                "spo2_cluster_duration_cumulative_2": null,
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": null,
                "pulse_arousalEvent_index_countPerHour": null,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": null,
                "spo2_meanHighestNadirDifference": null,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": null,
                "spo2_rdi_rera_desat_2To4Percent": null,
                "spo2_rdi_rera_desat_2To3Percent": null,
                "spo2_rdi_overall": null,
                "spo2_rdi_desat_gt4Percent": null,
                "spo2_rdi_desat_gt3Percent": null,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": null,
                "artifact_warningErrorSignal": null,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": false,
                "artifact_warningArtifactImage1": false,
                "motion_timeMotionArtifactDuration": null,
                "artifact_timeExcludedArtifactDuration": null,
                "motion_timeMotionArtifactTotal": null,
                "artifact_timeExcludedArtifactTotal": null,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": null,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": null,
                "spo2_desatEvent_sat_min_gte90Lte100_count": null,
                "spo2_desatEvent_sat_min_gte80Lt90_count": null,
                "spo2_desatEvent_sat_min_gte70Lt80_count": null,
                "spo2_desatEvent_sat_min_gte60Lt70_count": null,
                "spo2_desatEvent_sat_min_gte50Lt60_count": null,
                "spo2_desatEvent_sat_min_lt50_count": null
            },
            "comments": null,
            "interpretation": null,
            "error": "The page cannot be displayed because an internal server error has occurred.",
            "id": 9,
            "guid": "2649a218-124c-4172-b9de-7c07cd694980",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "2649a218-124c-4172-b9de-7c07cd694980.edf",
            "pdf": null,
            "createdAt": "2019-01-21T21:34:40.000Z",
            "updatedAt": "2019-10-21T19:29:26.000Z",
            "patientId": 1,
            "deviceId": null
        },
        {
            "data": {
                "pulse_arousalEvent_count": null,
                "pulse_arousalEvent_pr_increase_mean": null,
                "pulse_arousalEvent_durationBetweenEvents_mean": null,
                "pulse_arousalEvent_desat_mean": null,
                "pulse_arousalEvent_desat_min": null,
                "pulse_arousalEvent_pr_max_gte0Lt50_count": null,
                "pulse_arousalEvent_pr_max_gte50Lt60_count": null,
                "pulse_arousalEvent_pr_max_gte60Lt70_count": null,
                "pulse_arousalEvent_pr_max_gte70Lt80_count": null,
                "pulse_arousalEvent_pr_max_gte80Lt90_count": null,
                "pulse_arousalEvent_pr_max_gte90Lt100_count": null,
                "pulse_arousalEvent_pr_max_gte100_count": null,
                "pulse_pr_gte0Lt50_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte50Lt60_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte60Lt70_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte70Lt80_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte80Lt90_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte90Lt100_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte100_vrt_durationPercentage_cumulative": null,
                "pulse_pr_gte0Lt50_vrt_duration_cumulative": null,
                "pulse_pr_gte50Lt60_vrt_duration_cumulative": null,
                "pulse_pr_gte60Lt70_vrt_duration_cumulative": null,
                "pulse_pr_gte70Lt80_vrt_duration_cumulative": null,
                "pulse_pr_gte80Lt90_vrt_duration_cumulative": null,
                "pulse_pr_gte90Lt100_vrt_duration_cumulative": null,
                "pulse_pr_gte100_vrt_duration_cumulative": null,
                "pulse_cluster_pr_change_mean": null,
                "pulse_cluster_durationPercentage_cumulative": null,
                "pulse_cluster_duration_cumulative": null,
                "pulse_prvEvent_duration_cumulative": null,
                "pulse_prvEvent_durationPercentage_cumulative": null,
                "pulse_prvEvent_duration_mean": null,
                "pulse_max": null,
                "pulse_min": null,
                "pulse_tachycardia_pr_gte90_duration_cumulative": null,
                "pulse_bradycardia_pr_lte50_duration_cumulative": null,
                "spo2_cluster_duration_mean": null,
                "spo2_cluster_duration_cumulative_1": null,
                "spo2_cluster_durationPercentage_cumulative_1": null,
                "spo2_cluster_oxygenDeficitIndex": null,
                "spo2_reciprocation_min_mean": null,
                "spo2_cluster_reciprocation_index_countPerHour": null,
                "spo2_isCorrelatedToInstability": false,
                "spo2_ventilationInstabilityIndex": null,
                "spo2_startEvent_duration_mean": null,
                "spo2_startEvent_magnitude_mean": null,
                "spo2_startEvent_slope_mean": null,
                "spo2_endEvent_duration_mean": null,
                "spo2_endEvent_slope_mean": null,
                "spo2_reciprocation_recoveryDuration_mean": null,
                "spo2_reciprocation_recoveryRatio_mean": null,
                "spo2_reciprocation_cumulativeDuration": null,
                "spo2_isArtifactRejectionUsed": false,
                "spo2_sat_lt90_vrt_duration_cumulative": null,
                "meanSPO2VRT": null,
                "spo2_odi4_desatEvent_desat_gte4Percent_index_countPerHour": null,
                "spo2_od4_desatEvent_desat_gte4Percent_count": null,
                "spo2_od4_desatEvent_desat_gte4Percent_duration_cumulative": null,
                "AvgEventDuration": null,
                "spo2_odi3_desatEvent_desat_gte3Percent_index_countPerHour": null,
                "spo2_od3_desatEvent_desat_gte3Percent_count": null,
                "spo2_od3_desatEvent_desat_gte3Percent_duration_cumulative": null,
                "spo2_desatEvent_sat_min_lte88Percent_count": null,
                "spo2_desatEvent_sat_min_lte88Percent_duration_cumulative": null,
                "spo2_desatEvent_sat_min_lte88Percent_duration_max": null,
                "spo2_sat_min": null,
                "spo2_desatEvent_min10PercentOfEvents_sat_min_mean": null,
                "spo2_desatEvent_sat_min_mean": null,
                "spo2_sat_min_lte88Percent_mean": null,
                "spo2_sat_gt94Lte100_vrt_duration_cumulative": null,
                "spo2_sat_gt88Lte94_vrt_duration_cumulative": null,
                "spo2_sat_gt80Lte88_vrt_duration_cumulative": null,
                "spo2_sat_gt70Lte80_vrt_duration_cumulative": null,
                "spo2_sat_lte70_vrt_duration_cumulative": null,
                "spo2_vrt_duration_cumulative": null,
                "spo2_sat_gte80Lt90_vrt_duration_cumulative": null,
                "spo2_sat_gte90Lt100_vrt_duration_cumulative": null,
                "spo2_sat_gt94Lte100_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt88Lte94_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt80Lte88_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gt70Lte80_vrt_durationPercentage_cumulative": null,
                "spo2_sat_lte70_vrt_durationPercentage_cumulative": null,
                "spo2_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gte80Lt90_vrt_durationPercentage_cumulative": null,
                "spo2_sat_gte90Lt100_vrt_durationPercentage_cumulative": null,
                "spo2_satBaseline_waveSegmentsWithHighAndLowSatMean_mean_diff": null,
                "spo2_satBaseline_initialHighSaturationBaseline": null,
                "spo2_satBaseline_waveSegmentWithLowSatMean_mean": null,
                "spo2_satBaseline_waveSegmentWithHighSatMean_mean": null,
                "spo2_cluster_durationPercentage_cumulative_2": null,
                "spo2_cluster_duration_cumulative_2": null,
                "spo2_cumulativeOxygenDeficit_desaturationAdjustedVentilationInstabilityIndex": null,
                "pulse_arousalEvent_index_countPerHour": null,
                "spo2_cluster_longestCluster_reciprocation_index_countPerClusterDuration": null,
                "spo2_meanHighestNadirDifference": null,
                "spo2_cyclingSeverityIndex_desaturationAdjustedVentilationInstabilityIndex": null,
                "spo2_rdi_rera_desat_2To4Percent": null,
                "spo2_rdi_rera_desat_2To3Percent": null,
                "spo2_rdi_overall": null,
                "spo2_rdi_desat_gt4Percent": null,
                "spo2_rdi_desat_gt3Percent": null,
                "spo2_standardRecip_desatPercentage_top10Percent_mean": null,
                "artifact_warningErrorSignal": null,
                "artifact_warningDuration": null,
                "artifact_warningArtifactImage": false,
                "artifact_warningArtifactImage1": false,
                "motion_timeMotionArtifactDuration": null,
                "artifact_timeExcludedArtifactDuration": null,
                "motion_timeMotionArtifactTotal": null,
                "artifact_timeExcludedArtifactTotal": null,
                "spo2_sat_gte90Lte100_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte80Lt90_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte70Lt80_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte60Lt70_trt_durationPercentage_cumulative": null,
                "spo2_sat_gte50Lt60_trt_durationPercentage_cumulative": null,
                "spo2_sat_lt50_trt_durationPercentage_cumulative": null,
                "spo2_desatEvent_sat_min_gte90Lte100_count": null,
                "spo2_desatEvent_sat_min_gte80Lt90_count": null,
                "spo2_desatEvent_sat_min_gte70Lt80_count": null,
                "spo2_desatEvent_sat_min_gte60Lt70_count": null,
                "spo2_desatEvent_sat_min_gte50Lt60_count": null,
                "spo2_desatEvent_sat_min_lt50_count": null
            },
            "comments": null,
            "interpretation": null,
            "error": "The page cannot be displayed because an internal server error has occurred.",
            "id": 10,
            "guid": "9df5baf1-ffc4-4d19-8986-e3bc3f06b69c",
            "startTime": "2018-10-25T03:37:07.000Z",
            "endTime": "2018-10-25T10:59:48.000Z",
            "type": null,
            "referringId": null,
            "interpretingId": null,
            "uploadedBy": 2,
            "interp": false,
            "valid": true,
            "edf": "9df5baf1-ffc4-4d19-8986-e3bc3f06b69c.edf",
            "pdf": null,
            "createdAt": "2019-01-21T21:47:49.000Z",
            "updatedAt": "2019-10-21T19:29:30.000Z",
            "patientId": 1,
            "deviceId": null
        }
    ],
    "limit": 50,
    "offset": 0
};
