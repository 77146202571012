//*******************************************************************
//          Lite-weight PDF creator wrapper based on pdfmake.
//          Part of b$l = Beaver $cript Library.
//*******************************************************************

// This module is an interface between this application and chart generators.
//
// Application calls this interface in two steps:
//
//        1 step. methods.makeChartsOptions(); (this step is optional and rebuilds options.series[0].data)
//        2 step. methods.loadCharts( function() {
//

// Before doing any chart generation, the chart options
//       must be created and stored into array.


import Highcharts from 'highcharts'


( function() {

    var ns          = window.b$l        = window.b$l        || {};
    var $$          = ns.$$;
    var methods     = ns.methods        = ns.methods        || {};
    var nheap       = ns.nheap          = ns.nheap          || {};
    var ddCont      = nheap.ddCont      = nheap.ddCont      || [];
    var ccc         = console.log; ccc && ( ccc = console.log );

    var originalVendorFunction_downloadURL = null;
    methods.loadCharts = loadCharts;
    return;









    function loadCharts( continueAfterChartsLoaded )
    {
        var contCharts  = nheap.contCharts;
        if( contCharts.length === 0 ) {
            continueAfterChartsLoaded();
            return;
        }
        doProcessExportLocally();
        return;



        



        //=====================================================================
        // //\\ calls local export
        //      Must run once per app landing.
        //          Because of Highcharts.downloadURL modification wraps
        //          original function, not erases it.
        //
        //      The method of this funciton:
        //          it calls callLocalConverter recursively step by step.
        //            callLocalConverter calls Higcharts library loaded into
        //              web-browser possibly asynchronously.
        //              When image of chart arrives from the Highcharts,
        //              then this image processes down to application to
        //              be inserted into pdf-document
        //         When all charts number arrived from Highcharts reaches
        //         prepared charts number,
        //              arrivedCounter >= contCharts.length,
        //         then execution leave this module
        //=====================================================================
        function doProcessExportLocally()
        {
            var counter = 0;
            var arrivedCounter = 0;
            //debug:
            //var myImage = document.createElement('img');
            //document.body.appendChild(myImage);

            //******************************************************
            // Attention.
            // This line modifies the legacy code of Highcharts.com.
            // This is the only change and the only
            // place were this change happens in this application.
            //------------------------------------------
            originalVendorFunction_downloadURL = originalVendorFunction_downloadURL ||
                                                 Highcharts.downloadURL;
            var pdfgenwrapFlag = '--pdfgenwrap--';
            Highcharts.downloadURL = function( dataURL, filename ) {
                if( filename.indexOf( pdfgenwrapFlag  ) > -1 ) {
                    ////we have pdfgenwrap scenario, so we will not use
                    ////originalVendorFunction, but will use this module function
                    downloadURL( dataURL, filename.replace( pdfgenwrapFlag, '' ) );
                } else {
                    ////gently uses originalVendorFunction to coexist with
                    ////the parent applicaiton of this pdfgenwrap-plugin
                    originalVendorFunction_downloadURL.call( Highcharts, dataURL, filename );
                    ccc( 'filename filename', filename );
                }
            };
            //******************************************************


            ///dataURL is a thing which can be assigned to href in <a href=...
            function downloadURL(dataURL, filename) {
                try{
                    //console.log( filename );
                    var piggyBackCounter = parseInt(filename);
                    insertImage( dataURL, piggyBackCounter )
                    // //\\ good debug
                    //if( piggyBackCounter === 1 ) ccc( dataURL );
                    //myImage.src = dataURL;
                    //ccc( 'chart image ' + piggyBackCounter + ' added to pdfDoc-options' );
                    // \\// good debug
                    arrivedCounter++;
                    if( arrivedCounter >= contCharts.length ) {
                        //ccc( 'all charts are converted' );
                        //not good to reset exporter set this way here: arrivedCounter = 0;

                        //.missing this setTimeout and calling this function directly
                        //.apparently creates repeated calls to exporter in
                        //.case of the function errors and leading to big mess
                        setTimeout( continueAfterChartsLoaded, 1 );
                    }              
                } catch (err) {
                    ccc( 'err in downloadURL=', err );
                }
            };
            //.this function starts the setTimeout-load-loop and continues
            //.inside this loop
            callLocalConverter();
            return;

            ///this function is called in the setTimeout-loop
            function callLocalConverter()
            {
                var contCharts  = nheap.contCharts;
                //.this takes parameters of the specific chart of specific "counter"
                var chartRack = contCharts[counter];
                //-----------------------------------------------
                // //\\ apparently this finalizes configuration
                //-----------------------------------------------
                //      of options which makes
                // the output chart available for interception at output for 
                // conversion to canvas
                chartRack.options = ns.paste( chartRack.options,
                {
                    exporting: {
                        filename : ''+counter+'--pdfgenwrap--',
                        fallbackToExportServer: false, //true,
                        error: function() { ccc( 'error in exporting: ', arguments ); },
                        enabled: false // hides button
                    }
                });

                chartRack.options.chart = chartRack.options.chart || {};
                chartRack.options.chart.events =
                {
                    load: function( event ) {
                            //ccc( Date.now() +' after load');
                            if( !this.userOptions.chart.forExport ) {
                                //ccc( Date.now() +' starting export of chart ' + counter +
                                //     ' "' + chartRack.options.title.text + '"');
                                //     chartRack.options );
                                this.exportChartLocal();
                                //.make blob ... why?
                                //this.exportChartLocal({ type: 'image/svg+xml' });

                                //ccc( Date.now() +' exportChartLocal called' );
                            }
                    } 
                };
                //ccc( '::::: ' + counter + ' final options =', chartRack.options );


                //-----------------------------------------------
                // \\// apparently this finalizes configuration
                //-----------------------------------------------

                //-----------------------------------------------
                // //\\ calls Highcharts and catches error if any
                //-----------------------------------------------
                //ccc( 'exporting chart ' + counter + ' of ' + ( contCharts.length - 1 ) );
                //if( chartRack.options.chart.type === 'columnpyramid' )
                //    chartRack.options.chart.type = 'column';

                var container = $$.div().to(document.body)
                                .css('position', 'absolute' )
                                .css('left', '-10000px' )
                                ();

                try{
                    //ccc( chartRack.widgetDescription );
                    Highcharts.chart( container, chartRack.options );

                } catch (chartErr ) {
                    var briefError = 
                        '\n\n*********\nError in chart ' + chartRack.widgetDescription +
                        '\nError description: ' + chartErr;

                    //container.innerHTML = briefError +
                    //    "<br>For full error log, press Ctrl+Shift+E" +
                    //    "<br>Then to copy log to clipboard: Ctrl+A, Ctrl+C";

                    var fullError = briefError +
                         '\nChart options submitted to Highcharts=' +
                         JSON.stringify( chartRack.options, null, '    ' );
                    //ccc( fullError );
                    ns.d( fullError );

                    ////emulates normal program flow, but outputs error warnings
                    //.instead of outputting chart to pdf, does output error image
                    insertImage(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA" +
                        "AAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO" +
                        "9TXL0Y4OHwAAAABJRU5ErkJggg==",
                        counter
                    );
                    arrivedCounter++;
                    if( arrivedCounter >= contCharts.length ) {
                        ////here this module terminates its job, gives control to
                        ////the parent-application, and exits
                        setTimeout( continueAfterChartsLoaded, 1 );
                    }
                }
                //-----------------------------------------------
                // \\// calls Highcharts and catches error if any
                //-----------------------------------------------


                //-----------------------------------------------
                // //\\ continues the load-loop
                //-----------------------------------------------
                counter++;
                if( counter < contCharts.length ) {
                    ////perhaps this is good to split conversion a bit,
                    ////this is why delay is 1 ms
                    setTimeout( callLocalConverter, 1 );
                }              
                //-----------------------------------------------
                // \\// continues the load-loop
                //-----------------------------------------------
            }
        }
        //===================================
        // \\// calls local export
        //===================================
    }



    //========================================
    // //\\ inserts image into doc def content:
    //
    //      It can be inserted to
    //      different places which are already prepared inside pdfmake.
    //      These places can be an ordinary content, or column, or
    //      cell in table.
    //========================================
    function insertImage( imgData, vix, datImPrefix )
    {
        var contCharts  = nheap.contCharts;
        var insertee;
        datImPrefix = datImPrefix || ''
        var chartRack = contCharts[vix];

        if( chartRack.ddContRack ) {
            ////super vital condition to avoid placing into document-container "directly"
            insertee = chartRack.ddContRack;
        } else {
            var ix = chartRack.ddContIx;
            var colIx = chartRack.columnIx;
            if( colIx || colIx === 0 ) {
                ////charts in page's columns
                insertee = ddCont[ ix ].columns[ colIx ];
            } else {
                ////charts in page: outside of columns
                insertee = ddCont[ ix ];
            }
        }
        if( insertee.image ) {
            ccc( 'incorrect program behavior: image inserts twice to ' +
                 colIx + ' ' + ix + ' former img=' + insertee.image.substring(0, 100) +
                ' new img= ' + (datImPrefix + imgData).image.substring(0, 100)
            );
        }
        insertee.image = datImPrefix + imgData;
    }
    //========================================
    // \\// inserts image into doc def content
    //========================================

}) ();
