// https://www.highcharts.com/docs/
//...we still feed the app from baby-sitter React-HC ...
//...we don't need to do this ... will replace it if needed ...
import Highcharts from 'highcharts'

import legendTableImgSrc from '../img/table-icon.png'
import hr_icon from '../img/hr-icon.png'

var ccc = console.log; ccc && ( ccc = console.log );


//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var $$          = ns.$$;
var fconf       = ns.fconf;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var aModel      = ns.sn('aModel', fapp);
var currtpl     = ns.sn('currtpl', fconf);

fmethods.containifies_rightbar_icons8masks      = containifies_rightbar_icons8masks;
fmethods.modelify8containify_rightB_icons8masks = modelify8containify_rightB_icons8masks;
fmethods.setChosenClassOnCard                   = setChosenClassOnCard;
fmethods.inldynCSS_CP       = inldynCSS_CP;
fmethods.cont_rightCP                           = cont_rightCP;







//---------------------------------
// //\\ populates card repo pane
//---------------------------------
function modelify8containify_rightB_icons8masks()
{
    //:local references
    var dt = currtpl.docbody;
    var datGr = dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ];
    var dataId = datGr.dataId;

    //:detecting crepo$
    var crepo$ = $$.q( '.cards-repo' );
    if( !crepo$() ) return;

    //:purging crepo$
    crepo$.html( '' );

    //---------------------------------------------------------
    // //\\ masks garbage collection
    //---------------------------------------------------------
    var masks = ns.sn( 'repoThumbMasks', aModel, [] );
    ///cleans up former icon-handles from dom and from array
    while( masks.length ) {
        var len1 = masks.length - 1;
        masks[ len1 ].dom$().remove();
        masks[ len1 ].repocardSuperParent$().remove();
        masks.length = len1;
    };
    //---------------------------------------------------------
    // \\// masks garbage collection
    //---------------------------------------------------------



    //:detecting dragSurface$
    var dragSurface$ = $$.q('.plugin-root');




    datGr.subGrs.forEach( (ct,dsg_ix) => {
        //------------------------------------------
        // //\\ buids icon placeholders
        //------------------------------------------
        var repocardSuperParent$ = $$.div()
          .to( crepo$ )
          .addClass( 'repocard-superparent repocard-superparent-'+dsg_ix )
          ;
        ///this is a tricky play with chart, width and scale ...
        ///var repomediaParent$;
        ///this thing is css-scaled ... be careful
        $$.div()
            .addClass( 'repocard-parent repocard-parent-'+dsg_ix )
            .ch( $$.div()
               .addClass( 'repomedia-parent' )
               .css( 'position', 'relative' )
               .css( 'width', '100%' )
               .css( 'height', '100%' )
            )
            .to( repocardSuperParent$ )
            ;
        //------------------------------------------
        // \\// buids icon placeholders
        //------------------------------------------


        //------------------------------------------
        // //\\ buids mask model and placeholder
        //      model, masks, is for initRepoDragModel
        //------------------------------------------
        masks[ dsg_ix ] = {
            dataId,
            dsg_ix,
            dsg_id  : datGr.subGrs[ dsg_ix ].id,
            datGr   : datGr,
            datSGr  : datGr.subGrs[ dsg_ix ],
            repocardSuperParent$,
            dragSurface$,
            chartTemplate : ns.paste( {}, ct ),
            dom$ : $$.div()
                .addClass(
                    'mask-dataid-' + dataId + ' ' +
                    'chart-repo-mask mask-' + dsg_ix )
                .css( 'position', 'absolute' )
                .css( 'opacity', '0.0' )
                .css( 'cursor', 'pointer' )
                .css( 'z-index', '20000' )

                .e( 'click', ( event ) => {
                     fmethods.handleRepocardChangeEvent({ target : { value : ''+dsg_ix }})
                })
                .to( dragSurface$ )
        };
        //------------------------------------------
        // \\// buids mask model and placeholder
        //------------------------------------------
    })
    fmethods.setChosenClassOnCard();
        //:todm seems redundant
        //todo methods.dashboard_ dom_ scale8height();
        //todo methods.dashboard_ page Ancestors_CSS();
        //fmethods.mcss__dashb_detects_condensityScale();
        //fmethods.mcss__dashb_frames_detectsHeights();

    //continues containify
    fmethods.cont_rightCP();
    fmethods.inldynCSS_CP();
    fmethods.initRepoDragModel( dragSurface$ );
}
//---------------------------------
// \\// populates card repo pane
//---------------------------------





function cont_rightCP()
{
    //---------------------------------------
    // //\\ sets cards repo css
    //---------------------------------------
    var dt              = currtpl.docbody;
    var dataIx          = dt.contrPanelChosen_tplDatGrIx;
    var datGr           = dt.repo.dataGroups[ dataIx ];

    datGr.subGrs.forEach( (ct,dsg_ix) => {
        var repomediaParent$ = $$.q( '.repocard-parent-'+dsg_ix + ' .repomedia-parent' );
        fmethods.containifies_rightbar_icons8masks({
            dataIx,
            dsg_ix,
            repomediaParent$,
        })
    })
}



/***********************************************************************************

the tree of icons is a bit complex now: take a note:

remember: superparent dimensions are as is, but must adopt scaling of the child
          child scales so has dims=parent/scale

repocardSuperParent$ = repocard-superparent repocard-superparent-'+dsg_ix //abs pos
    //CSS is not inline

    .repocard-parent .repocard-parent-'+dsg_ix ///abosolute position
        //CSS is inline in px

        repomediaParent$ = .repomedia-parent    ///relative position
            highcharts-u5k726t-55 ... highcharts-container
                svg of highcharts
***********************************************************************************/

function containifies_rightbar_icons8masks({ dataIx, dsg_ix,
        //cardWidth,
        //for super nice, we can use:
        /*
        if( datGr.isChart ) {
            var cardWidth   = ct.tpl.chart.width;
            var height      = ct.tpl.chart.height;
        */
        repomediaParent$ })
{
    var dt              = currtpl.docbody;
    var dataGroup       = dt.repo.dataGroups[ dataIx ];
    var subGr           = dataGroup.subGrs[ dsg_ix ];
    var masks           = aModel.repoThumbMasks;
    var mask            = masks[ dsg_ix ];

    //---------------------------------------------------
    // //\\ builds caption to flow above card-icon
    //---------------------------------------------------
    var iconSrc = dataGroup.dataId === "horizontal-line" ? hr_icon : legendTableImgSrc;
    var tooltip = ' title="' + dataGroup.dashCaption +
                  ' / ' + subGr.dashCaption + '" ';

    //var masktooltip = ' titlex="mask card category: ' + dataGroup.dashCaption +
    //                 ' subcategory: ' + subGr.dashCaption + '" ';

    var legHtmlMask = '';
    if( dataGroup.isTable ) {
        var legHtml =
        '<img style="width:100%; height:100%; position:absolute;  top:0px;' +
                    ' opacity:0.8;"' +
                    tooltip +
                    'src="' +
                    iconSrc +
        '">';
        /*
        var legHtmlMask =
        '<img style="width:100%; height:100%; position:absolute;  top:0px;' +
                    ' opacity:0.8;"' +
                    masktooltip +
                    'src="' +
                    iconSrc +
        '">';
        */        
    } else if( dataGroup.isPicture ) {

        //todo patch ... while empty images arrive from server ...
        var imgsrc = fapp.aModel.dataURLs[subGr.tpl.imsrc ] ||
                     ns.fheap.testImg_dataURL;
        legHtml =
        '<img style="width:100%; height:100%; position:absolute; top:0px;' +
                    ' opacity:0.8;"' +
                    tooltip +
                    'src="' +
                     imgsrc +
        '">';

    } else {
        legHtml = '';
    }

    var fontSize = 77 * 12 / ( subGr.dashCaption.length + 14 );
    legHtml += '<div style=" position:absolute; top:0px; left:0; width:100%; height:100%; ' +
               'font-size:' + fontSize + 'px;' +
               'line-height:1.05; padding:0;' +
               ' font-weight:bold; color:#0000dd; text-align:center;' +
               'z-index:1000;' +
               '"' +
               tooltip +
               '>' +
               subGr.dashCaption +
               '</div>';


    legHtmlMask = legHtml;

    /*
    legHtmlMask +
                '<div style=" position:absolute; top:0px; left:0; width:100%; height:100%; ' +
               'font-size:' + fontSize + 'px;' +
               'line-height:1.05; padding:0;' +
               ' font-weight:bold; color:#0000dd; text-align:center;' +
               'z-index:1000;' +
               '"' +
               masktooltip +
               '>' +
               subGr.dashCaption +
               '</div>';
    */

    repomediaParent$.html( legHtml );
    //---------------------------------------------------
    // \\// builds caption to flow above card-icon
    //---------------------------------------------------

    //=================================================================
    // //\\ loops through data-types and builds html for thumbnails and
    //      their drag8drop masks
    //=================================================================
    if( dataGroup.isTable ) {
        repomediaParent$.html( legHtml );
        mask.dom$.html( legHtmlMask );
    } else if( dataGroup.isPicture ) {
        repomediaParent$.html( legHtml );
        mask.dom$.html( legHtmlMask );
    } else if( dataGroup.isChart ) {
        ////todo: no data here ... show data in stub too for good look ...
        ////              var chartOptions = pg Model.pmcards[ dsg_ix ].cdataOptions;
        var chartOptions = ns.paste(
            {},
            //dataGroup.subGrs[ dsg_ix ].tpl
            dataGroup.tpl
        );

        var cardWidth = 500;
        //0.96 is a patch for nicer look;
        //     right white gap is a bit bigger;
        chartOptions.chart.width = cardWidth*0.96;

        chartOptions.chart.height = cardWidth
            * 0.95 //todmm patch to make bottom icon border visible;
        ;

        //=======================================================
        // //\\ creating svg-picture of chart and
        //      adding it to thumbnail
        //=======================================================
        var inProgress  = false;
        chartOptions.chart.events =
        {
            load: function( event ) {
                if( inProgress ) {
                    inProgress = false;
                    return;
                }
                inProgress = true;

                //if( mask ) {
                //todo needs more thought ...
                //adds caption, legHtml, to identify icon
                mask.dom$.html( this.getSVG() + legHtmlMask );
                var svg$ = $$.$( mask.dom$().children[0] );
                /*
                svg$.a( 'viewBox', '0,0,' + maskWidthStr + ',' +maskWidthStr )
                    .a( 'width', maskWidthStr )
                    .a( 'height', maskWidthStr )
                    .css( 'left', maskLeft.toFixed()+'px' )
                    .css( 'top', maskTop.toFixed()+'px' )
                    ;
                */
                svg$.a( 'viewBox', '0,0,' + 1000 + ',' + 1000 )
                    .a( 'width', 500 )
                    .a( 'height', 250 )
                    .css( 'left', '1px' )
                    .css( 'top', '1px' )
                    ;
            }
        };
        try{
            Highcharts.chart( repomediaParent$(), chartOptions );
        } catch (chartErr ) {
            ccc( chartErr );
        }
        //=======================================================
        // \\// creating svg-picture of chart and
        //=======================================================
    }
    //=================================================================
    // \\// loops through data-types and builds html for thumbnails and
    //=================================================================
}





///==================================================
/// manages layout
///==================================================
///approach mixed between React and rawDom
///resizes document-pages area and cards-small-templates-in-control-panel
function inldynCSS_CP()
{
    //---------------------------------------
    // //\\ sets cards repo css
    //---------------------------------------
    var dt              = currtpl.docbody;
    var dataIx          = dt.contrPanelChosen_tplDatGrIx;
    var datGr           = dt.repo.dataGroups[ dataIx ];

    var masks           = aModel.repoThumbMasks;
    var superParentTop  = 0;

    datGr.subGrs.forEach( (subGr,dsg_ix) => {

        if( datGr.isChart ) {
            var width       = subGr.tpl.chart.width;
            var height      = subGr.tpl.chart.height;

        } else {
            //apparently, this width does not matter
            //width       = subGr.tpl.width || 200; //so far, it is preset in framework templates
            //height      = subGr.tpl.height || subGr.tpl.width*0.3;  //todo patch

            //todo patch
            width       = 900; 
            height      = 300;  
        }
        var aspRatio    = height / width;

        //var repocardSuperParent$ = $$.q('.repocard-superparent-'+dsg_ix);
        var repocardSuperParent$  = masks[ dsg_ix ].repocardSuperParent$;
        var suppar_width          = repocardSuperParent$.box().width
                                    //* 0.95 //todo
                                    ;
        var suppar_height   = suppar_width * aspRatio;
        var scale           = suppar_width/width;

        ///this elem is not scaled but adjusts its height to
        ///be equal to its scaled content
        repocardSuperParent$
          .css( 'height', suppar_height.toFixed(1) + 'px' )
          .css( 'top', (superParentTop).toFixed(2) + 'px' )
          //good debug:.a( 'title', subGr.dsg_id + ' suppar top=' + superParentTop.toFixed() )
          //todo ... move to global css
          .css( 'overflow', 'hidden' );
          ;


        $$.q('.repocard-parent-'+dsg_ix)
            .css( 'width',              ( width ).toFixed(2) + 'px' )
            .css( 'height',             height.toFixed(2) + 'px' )
            .css( 'transform',          'scale(' + scale.toFixed(4) + ')' )
            .css( 'transform-origin',   '0px 0px' )
            ;

        //ccc( superParentTop, height, (superParentTop*scale).toFixed(2) + 'px' );
        var BETWEEN_ICONS_VERT_GAP = 3;
        //.this "top" is adjusted to increment with scaled children content
        //superParentTop += height + BETWEEN_ICONS_VERT_GAP;
        mask__inldynCss({ dsg_ix, suppar_width, suppar_height, superParentTop, });

        superParentTop += suppar_height + BETWEEN_ICONS_VERT_GAP;
        //ccc( subGr.dsg_id + ' top='+superParentTop + ' height=' + height );

    });
    var crepo$ = $$.q( '.cards-repo' );
    crepo$.css( 'height', ( superParentTop + 50 ) + 'px' ); //todm 50 is a patch
    //---------------------------------------
    // \\// sets cards repo css
    //---------------------------------------
}



function mask__inldynCss({ dsg_ix, suppar_width, suppar_height, superParentTop })
{
    var masks = ns.sn( 'repoThumbMasks', aModel, [] );
    var mask = masks[ dsg_ix ];

    if( mask ) {
        var drag_box        = mask.dragSurface$.box();
        var suppar_bx       = mask.repocardSuperParent$.box();
        var maskLeft        = suppar_bx.left - drag_box.left;

        //based on dynamic styles
        //var maskTop       = suppar_bx.top - drag_box.top;
        //var maskTop       = superParentTop + crepoBox.top;

        //based on config:
        var maskTop         = superParentTop +
                                fconf.CONTROL_PANELS.APP_CP_HEIGHT +
                                fconf.CONTROL_PANELS.APP_CP_REPOCARDS;
        maskTop             -= $$.q( '.dashboard-right-side-bar' )().scrollTop;

        mask.dom$
            //.css( 'width', maskWidthStr + 'px' )
            .css( 'width', suppar_width + 'px' )
            .css( 'height', suppar_height + 'px' )
            .css( 'left', maskLeft + 'px' )
            .css( 'top', maskTop + 'px' )
            //good debug:.a( 'title', mask.dsg_id + ' mask top=' + maskTop.toFixed() )
            //good debug: removes masks from F12 tools: 
            //.css( 'display', 'none' )
            ;
        mask.dragsurf2mask  = [ maskLeft, maskTop, ];
    }
}



function setChosenClassOnCard() {
    var dt      = currtpl.docbody;
    var datGr   = dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ];
    datGr.subGrs.forEach( (ct,cardIx) => {
        var repoCont$ = $$.q('.repocard-parent-'+cardIx);
        //when scenario made wrong, this helps: if( !repoCont$() ) return;
        if( datGr.chosenIx === cardIx ) {
            repoCont$.addClass( 'chosen' );
        } else {
            repoCont$.removeClass( 'chosen' );
        }
    });
}

