import "./page-all.js";
import "./pages-css.js";

var ns          = window.b$l;
var $$          = ns.$$;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var aModel      = ns.sn('aModel', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.doc__internals = doc__internals;
fmethods.attachPagesDom2plugin      = attachPagesDom2plugin;
fapp.pagesPanelForceUpdate          = pagesPanelForceUpdate;
fmethods.pages__internals = pages__internals;








function doc__internals()
{
    //===========================================
    // //\\ cleans up dom garbage partially ...
    //===========================================
    ///todm: this is not a garbage collection
    if( aModel.pagesSuperparent$  ) {
        aModel.initHorizontalDividorDragModel_d8draw.removeEvents();
        aModel.initDragModel_d8draw.removeEvents();
        aModel.pgModels.forEach( pgModel => {
            //.checks for case if new page has been added
            if( !pgModel.hasOwnProperty( 'dom$$' ) ) return;
            Object.keys( pgModel.dom$$ ).forEach( key => {
                var dom$ = pgModel.dom$$[ key ];
                dom$().remove();
                delete pgModel.dom$$[ key ];
            })
        });
        aModel.pagesSuperparent$().remove();
        delete aModel.pagesSuperparent$;

    }
    //===========================================
    // \\// cleans up dom garbage partially ...
    //===========================================


    //===========================================
    // //\\ building doc placeholder
    //===========================================
    aModel.pagesSuperparent$ =
        $$.div()
        .addClass( 'pages-superparent' )
        .ch(
            aModel.pagesParent$ = $$.div()
            .addClass( 'pages-parent' )
        )            
        ;
    aModel.pagesSuperparent$.to( aModel.pluginRoot$ );
    aModel.initHorizontalDividorDragModel_d8draw =
        fmethods.initHorizontalDividorDragModel().d8draw;
    //===========================================
    // \\// building doc placeholder
    //===========================================


    //===========================================
    // //\\ building doc
    //===========================================
    pages__internals();
    //===========================================
    // \\// building doc
    //===========================================
}

function pages__internals()
{
    aModel.pgModels.forEach( pgModel => {
        fmethods.page__internals( pgModel );
    })
    aModel.initDragModel_d8draw = fmethods.initCardsD8D_model().d8draw;
}

function pagesPanelForceUpdate()
{
    ccc( 'pagesPanelForceUpdate' );
    fmethods.doc__internals();
    aModel.pagesSuperparent$.to( aModel.pluginRoot$ );
}


function attachPagesDom2plugin()
{
    aModel.pluginRoot$ = $$.q( '.plugin-root' );
    aModel.pagesSuperparent$.to( aModel.pluginRoot$ );
    ccc( 'added' );
}


