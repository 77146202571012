
export default function () {
    return  {
        series: [{
            caption : 'enter the caption',      //designed to be field GUI caption
            data: [ 'enter the text' ],
            cellstyle: [],                      //mapped to cells
        }],
    }
}




