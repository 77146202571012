
( function() {

    //==================================================
    // //\\ sets namespaces for plugin
    //==================================================
    var ns          = window.b$l        = window.b$l        || {};
    var fapp        = ns.fapp;
    var fconf       = ns.sn('fconf', fapp);
    var currtpl     = ns.sn('currtpl', fconf);
    var methods     = ns.methods        = ns.methods        || {};
    var nheap       = ns.nheap          = ns.nheap          || {};
    var ddDef       = nheap.ddDef       = nheap.ddDef       || {};
    //==================================================
    // \\// sets namespaces for plugin
    //==================================================


    var sn          = ns.sn;
    var ddCont      = nheap.ddCont      = nheap.ddCont      || [];

    var aModel      = sn('aModel', fapp);
    var pgModels     = sn('pgModels', aModel, [] );

    var ccc         = console.log; ccc && ( ccc = console.log );
    methods.pdfmake8pdfdoc__lifecycle = pdfmake8pdfdoc__lifecycle;
    return;











    ///**********************************************************
    // //\\ pdfmake8pdfdoc lifecycle
    /// builds HTML-mirror of report pages into
    /// doc. definitions, and imports these
    /// doc. definitions to pdfmake via callback arg;
    /// then executes pdfmake on doc and then opens or downloads
    ///**********************************************************
    function pdfmake8pdfdoc__lifecycle({
        win,
        pdfTask,
    }) {
        //=======================================
        // //\\ hides download or preview button
        //=======================================
        if( pdfTask === 'preview' ) {
            methods.enableButton( nheap.previewButton$, false );
        } else if( pdfTask === 'download' ) {
            methods.enableButton( nheap.downloadButton$, false );
        }
        //=======================================
        // \\// hides download or preview button
        //=======================================

        //=======================================
        // //\\ strip and cleanup
        //=======================================
        if( ns.h( window, 'pdfMake' ) ) {
            ////this is a Russian rulette ... is this safe ... what if
            ////pdfMake already set some global props. ... we don't delete them which
            ////defies our purpose to start from clean pdfmake load ...
            delete window.pdfMake;
        }
        //nheap.ddDef and nheap.ddDef.ddCont
        //are locally referenced in modules, so
        //we cannot just remove them, we
        //need purge these objects
        ddCont.length = 0;
        //.gets rid of indices too if any:
        Object.keys( nheap.ddDef ).forEach( dkey => { delete nheap.ddDef[ dkey ]; } );

        if( nheap.ddDefCloned ) {
            Object.keys( nheap.ddDefCloned ).forEach( dkey => {
                delete nheap.ddDefCloned[ dkey ];
            });
        }
        //=======================================
        // \\// strip and cleanup
        //=======================================


        //=======================================
        // //\\ loads pdfmake, rebuilds doc,
        //      compleetes PM, and executes its
        //      full life cycle
        //=======================================
        var script = document.createElement( 'script' );
        script.onload = () => { docmodel_2_ddDef8ddCont(
            () => {
                methods.loadCharts( () => {
                    methods.PM__establish8executeOnDoc( win, pdfTask );
                });
            }
        )};
        script.src = './vendor/pdfmake.min.js';
        document.head.appendChild( script );
        //=======================================
        // \\// loads pdfmake, rebuilds doc,
        //=======================================
    }
    ///**********************************************************
    // \\// pdfmake8pdfdoc lifecycle
    ///**********************************************************






    function docmodel_2_ddDef8ddCont( loadCharts )
    {
        doctpl_2_pdfdef();
        nheap.contCharts = [];

        //----------------------------------------------
        // //\\ pgmodels2ddCont
        //----------------------------------------------
        pgModels.forEach( ( pm,sortedIndex ) => {
            pm.pmcards.forEach( (pmcard,cardIx) => {
                methods.addCardForPDFExport({
                    pmcard,
                    pageMargins: currtpl.docbody.pstyle.PAGE_MARGINS[0],
                });
            });
            //c cc( 'cards are built: ddCont.length='+ddCont.length )
            if( sortedIndex < pgModels.length - 1 ) {
                ddCont[ ddCont.length - 1 ].pageBreak = 'after';
            }
        });
        ddDef.content = ddCont;
        //c cc( 'ddDef.content is created' );
        //----------------------------------------------
        // \\// pgmodels2ddCont
        //----------------------------------------------

        //methods.loadCharts();
        loadCharts();
    }





    function doctpl_2_pdfdef()
    {
        var metaDef =
        {
            pageSize : currtpl.docbody.pstyle.PDFMAKE_PAGE_SIZE,
            pageOrientation : currtpl.docbody.pstyle.PDFMAKE_PAGE_ORIENTATION,

            //very good: https://github.com/bpampuch/pdfmake/issues/808
            //??? header : fmethods.curPage_2_content,
        }; //metaDef

        if( currtpl.docbody.pstyle.PAGE_MARGINS ) {
            metaDef.pageMargins = currtpl.docbody.pstyle.PAGE_MARGINS;
        }
        ns.prop2prop( ddDef, metaDef );
    }

}) ();
