
import './bsl/core/ns.js';
import './reditor/css-sheets/dashb-theme.js';

var ns          = window.b$l;
var fconf       = ns.fconf;
var ccc         = console.log; ccc && ( ccc = console.log );


fconf.version =

  window.b$$l.version; //$$ is not a mistake:
                       //avoids a collision with b$l uniqueness check

fconf.DEVELOPER_MODE = false;
fconf.IN_SVG_EDITOR = true;
fconf.dynamicMockData = false;


fconf.SHOW_PDF_NOW = false;
fconf.hideDownloadButton = !true;
fconf.hidePreviewButton = !true;
fconf.ENABLE_HIGHCHARTS_CREDITS = false;

//======================================================================
//  database url
//----------------------------------------------------------------------
if( window.location.search.match( /(=|,)USE_LANDKEY_DB=yes(&|,|$|\s|#)/ ) ) {
    ////forces landkey db
    fconf.dburl = "http://landkey.net/z/bs/bwork/nightreports/";
} else if( window.location.hostname.match( /^localhost/ ) ) {
    fconf.dburl =  "http://localhost/bwork/nightrep/prj/version/public/" +
                   "report-editor/bsl/core/ajax/";
} else if( window.location.hostname.match( /^landkey.net/ ) ) {
    fconf.dburl = "http://landkey.net/z/bs/bwork/nightreports/";
}
//version for database file: valid only in DEV mode:
fconf.dbver = '2613'; //'2532'; //'2408'; //'2465'; //'2408'; //'2388'; //'2382';
              //'2368'; //'2344'; //'2252'; //'2184'; //'2178'; //'2154'; //'2140'; //'2119'; //'2105';
//======================================================================



//================================
// //\\ profiles
//================================
fconf.db2app_role_names = {
    'constructor' : 'CONSTRUCTOR',
    'user' : 'USER',
}
fconf.ROLE = 'CONSTRUCTOR';
//fconf.ROLE = 'USER';
//================================
// \\// misc config
//================================

//================================
// //\\ misc config
//================================
( function () {
    fconf.CARD_MARGINS = [ 10, 10 ];
    fconf.HIDE_HIGHCHARTS_CREDITS = !true;
    fconf.CP_OVERLAYS_MASTER_COLOR = '#064388';
    //fconf.CHARTS_MULTI_TPL = true; //core new format from about March 8
    fconf.FLUID_PDFMAKE_TABLES = true;

    //fconf.USE_APP_JS_CODE_REPO_FOR_CHART_TPL = true; //in effect when next option is true

    //style
    fconf.SHOW_PAGE_HEADER_BACKGROUND = false;
    fconf.SHOW_CAPTION_DATA = false;
    fconf.CHANGES_UNSAVED_TO_DB_COLOR = '#eebb44';

    //this statement overrides fconf (if there are options in URL-query-string)
    ns.url2conf( fconf );

})();
//================================
// \\// misc config
//================================



//in React framework, the following provides an additional way to export this module:
//the Earth-unique name test and top-level wrappers (function () {
//do become unnecessary.
export default fconf;
