import React, { Component } from "react";
import ReactPlayer from "react-player/youtube";

class VideoTours extends Component {


	constructor() {
		super()
		this.url = '/video-tours'
	}


	render() {
		return (
			<div className='card'>
				<div className='card-header'>
					<div className='row'>
						<div className='col'>
							<span className='h4'>Video Tours</span>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div className='row mb-2'>
						<h5>Getting to Know Your SleepSat</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=enCMCtT6LDg' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatScreen Connection Center App: Installation and Setup</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=QHxNUCzfDC4' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatCloud Portal Overview</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=HoWpu2Bu29Y' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatScreen Connection Center Use</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=sqqsa89yJc8' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>12/9/21 SleepSat training webinar</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/jOEwl8SJqXM' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SleepSat Patient Instructions</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=EVzKBD9vnDk' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SleepSAT Disposable Probes</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/DERcunyiyfs' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>Understanding Sleep Apnea</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/StrgM59czQU' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatScreen Oximetry Report Overview</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=aQZYH0TlWoc' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatScreen Report: RDI(hr)</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/w9nvd9TGIgk' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SatScreen Report: Cycling Time and Cycling Severity</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/S9NvSB2Kgok' controls={true} />
					</div>
					
					<div className='row mb-2'>
						<h5>SatScreen Report Review - Webinar Recording</h5>
					</div>
					<div className='row mb-2'>
						<ReactPlayer url='https://youtu.be/MEfMVgDZbm4' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>Update Credit Cards in SatCloud</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://www.youtube.com/watch?v=w3iNj-3MZ3M' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>SleepSAT: Wristband Placement for Small Wrists</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://youtu.be/nC_GgOyiF3c' controls={true} />
					</div>

					<div className='row mb-2'>
						<h5>Non-US Set-up: Changing Number Format Settings Before Uploading Reports</h5>
					</div>
					<div className='row  mb-2'>
						<ReactPlayer url='https://youtu.be/LIQ1O6JQT9I' controls={true} />
					</div>
					
				</div>
			</div>
		)
	}

}

export default VideoTours
