
var ns          = window.b$l;
var fconf       = ns.fconf;
var ccc         = console.log; ccc && ( ccc = console.log );



Object.assign( fconf, {
    DASHBOARD :
    {
        PAGE_MARGIN_LEFT_PX : 10,
        PAGE_MARGIN_TOP_PX : 40,
        UNSCALED_PAGE_MARGIN_TOP_PX : 10,
        GAP_BETWEEN_PAGES : 10,
        PAGES_LEFT_MARGIN : 10,
        PAGES_RIGHT_MARGIN : 10,
        PAGE_BORDER_WIDTH : 3,
        PAGES_SUPERPARENT_BG_COLOR : '#bbbbbb',
        COLORS:
        {
            BACKGROUND : '#f0f6ff',
            SB_DARK_BG : '#222532',

            //was: OVERLAYS_COLOR : '#064388',
            FOCUS_BORDER : '#399af2',
            EDITABLE_OVERLAYS : '#399af2', // '#00bb00',

            DOC_SELECTION_CONTROLS :'#2040b0',

            FONT_DARK :'#617182',
            FONT_SOFT :'#788db4',
            DROPDOWN_BG : '#ffffff',
        },
    },
    CONTROL_PANELS :
    {
        CAPTION : {
            CONSTRUCTOR: "Report Composer",
            USER: "Report Preview",
        },
        // controls reditor top position,
        // too small values cause site-header overlapping
        APP_CP_HEIGHT : 75,
        APP_CP_HEIGHT_NARROW_SCR : 100,
        APP_CP_HEIGHT_NARROW_SCR_BREAKPOINT : 800,
        APP_CP_BOTTOM_GAP : 20,
        APP_CP_REPOCARDS : 70,

        DASHBOARD_RIGHT_SIDEBAR_WIDTH_PERC : 15,
        DASHBOARD_LEFT_SIDEBAR_WIDTH_PERC : 15,
    }
});


