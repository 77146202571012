import React, {Component} from 'react'

class DeleteAlert extends Component {

	render(){

		if (this.props.message){
			return (
				<div className='alert alert-light mb-n2'>
					<div className='row'>
						<div className='col-10'>
							<span >{this.props.message}</span>
						</div>
						<div className='col-1'>
							<button className='btn btn-outline-danger' type='button' onClick={this.props.confirmHandler}>
								Delete
							</button>
						</div>
						<div className='col-1'>
							<button className='btn btn-outline-primary' type='button' onClick={this.props.cancelHandler}>
								Cancel
							</button>
						</div>	
					</div>
				</div>
			)
		} else {
			return (
				<div></div>
			)
		}
	}
}

export default DeleteAlert