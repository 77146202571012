import React, { Component } from 'react'
import $ from 'jquery'
import PaymentItem from './payment-list-item'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSave, faBan, faPlus} from '@fortawesome/free-solid-svg-icons'
import Alert from './alert'

class Payment extends Component {

	constructor(props){
		super(props)
		this.state = {
			alertType: 'alert-success',
			alertMessage: '',
			card: {
				name: '',
				exp_month: 1,
				exp_year: 2023,
				cvc: 0,
				number: 0
			},
			cardholder: {},
			cards: [],
			creating: false,
			months: [],
			years: [],
			defaultCard: {},
			applyToAll: false
			
		}
	}
	
	componentDidMount() {
		this.setDates()
		this.getCards()
	}

	setDates(){
		var months = []
		var years = []
		for (var i = 1; i < 13; i++){
			months.push(i)
		}
		var year = new Date()
		for (var ii = 0; ii < 21; ii++){
			years.push(year.getFullYear() + ii)
		}
		this.setState({
			months: months,
			years: years
		})
	}

	componentDidUpdate(prevProps,prevState){
		if (prevProps.organization.id !== this.props.organization.id){
			this.getCards()
		}
		if (this.state.applyToAll !== prevState.applyToAll && this.state.creating === true){
			this.handleSubmit()
		}
	}

	getCards(){
		var url = '/organizations/' + this.props.organization.id +'/cards'
		var success = this.handleCards.bind(this)
		$.ajax({
			url: url
		}).done(success)
	}

	handleCards(data){
		var card = data.data[0] || {}
		this.setState({ 
			card: card,
			cardholder: card,
			cards: data.data
		})
	}

	applyToAll(){
		this.setState({
			applyToAll: true
		})
	}

	handleSubmit(){
		var success = this.handleToken.bind(this)
		var card = this.state.card
		var url = '/tokens'
		$.ajax({
			url: url,
			method: 'POST',
			modalOpen: false,
			data: {
				card: {
					name: card.name,
					number: card.number,
					exp_month: card.exp_month,
					exp_year: card.exp_year,
					cvc: card.cvc
				}
			}
		}).done(success).fail(this.handleFailure.bind(this))
	}

	handleToken(data){
		if (data.error){
			alert(data.error.message)
			return
		}
		var url = '/organizations/' + this.props.organization.id + '/cards'
		var success = this.handleTokenSuccess.bind(this)
		var error = this.handleFailure.bind(this)
		$.ajax({
			url: url,
			method: 'POST',
			data: { source: data.id }
		}).done(success).fail(error)
	}

	handleFailure(err){
		this.setState({
			alertMessage: err.responseJSON.error.message,
			alertType: 'alert-danger',
			creating: false,
			applyToAll: false
		})
	}

	handleTokenSuccess(card){
		this.setState({
			alertMessage: 'Card successfully added',
			alertType: 'alert-success',
			creating: false,
			defaultCard: card
		}, () => this.setDefaultCard())
		this.getCards()
	}

	setDefaultCard(){
		if (this.state.applyToAll){
			this.getSubscriptions()
		}
	}
	
	getSubscriptions(){
		var id = this.props.organization.id
		var url = '/organizations/' + id + '/subscriptions'
		var success = this.handleSubscriptions.bind(this)
		$.get(url).done(success)
	}

	handleSubscriptions(data){
		var url = '/organizations/' + this.props.organization.id + '/default/' + data.customer.id
		$.ajax({
			url: url,
			method: 'PUT',
			data: {
				default_payment_method: this.state.defaultCard.id
			}
		})
		for (var i = 0; i < data.subscriptions.data.length; i++){
			url = '/organizations/' + this.props.organization.id + '/subscriptions/' + data.subscriptions.data[i].id
			$.ajax({
				url: url,
				method: 'PUT',
				data: { 
					default_source: this.state.defaultCard.id,
					default_payment_method: this.state.defaultCard.id
				}
			}).done(() => {
				this.props.subscriptions.getCards()
				this.props.subscriptions.getSubscriptions()
				
			})
		}
	}

	handleChange(event){
		var data = this.state.card
		var el = event.target
		if (el.name === 'name'){
			data[el.name] = el.value
		} else {
			data[el.name] = parseInt(el.value)
		}
		this.setState({ card: data })
	}

	renderCCItem(data, index){
		return (
			<PaymentItem key={index} data={data} organization={this.props.organization} payment={this}></PaymentItem>
		)
	}

	renderMonths(month){
		return (
			<option key={month}>{month}</option>
		)
	}

	renderYears(year){
		return (
			<option key={year}>{year}</option>
		)
	}

	toggleCreating(){
		this.setState({
			creating: !this.state.creating
		})
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

	renderAddCC(){
		var months = this.state.months.map(this.renderMonths.bind(this))
		var years = this.state.years.map(this.renderYears.bind(this))
		if (this.state.creating){
			return (
				<li className={`list-group-item border border-dark rounded mt-2`} style={{padding: 2}} key={this.props.index} >
					<form>
						<div className='row'>
							<div className='col text-center mt-2 mb-2'>
								<span className='font-weight-bold'></span>
								<div className='col text-center'>
									<label>Card Number</label>
									<input type="text" className="form-control"
									onChange={this.handleChange.bind(this)}
									name='number'/>
								</div>
							</div>
							<div className='col text-center mt-2 mb-2'>
							<label>Name on card</label>
							<input type="text" className="form-control"
							onChange={this.handleChange.bind(this)}
							name='name'/>
							</div>
							<div className='col text-center mt-2 mb-2'>
								<label>Expiration Date</label>
								<div className='row'>
									<div className='col-2'></div>
								<div className='col-4' style={{padding: 0}}>
									<select className="custom-select mr-sm-2"
									onChange={this.handleChange.bind(this)}
									name='exp_month'
									defaultValue='----'>
										<option hidden value= '----' key='null'>----</option>
										{months}
									</select>
								</div>
								<div className='col-4' style={{padding: 0}}>
									<select className="custom-select mr-sm-2"
									onChange={this.handleChange.bind(this)}
									name='exp_year'
									defaultValue='----'>
										<option value='----' hidden key='null'>----</option>
										{years}
									</select>
								</div>
								</div>
							</div>
							<div className='col text-center mt-2 mb-2'>
								<label>CVC</label>
								<input type="text" className="form-control"
								onChange={this.handleChange.bind(this)}
								name='cvc'/>
							</div>
							<div className='col text-center mt-2 mb-2'>
								<label></label>
								<div className='col'>
								<div className='row mt-2 justify-content-center'>
									<button type="button" className="btn btn-outline-success" data-toggle="modal" data-target="#applyToAllModal">
									<FontAwesomeIcon icon={ faSave } />
									</button>
									<button className='btn btn-outline-danger'
									type='button' 
									onClick={ this.toggleCreating.bind(this) }>
									<FontAwesomeIcon icon={ faBan } />
									</button>
								</div>
								</div>
							</div>
						</div>
					</form>
					<div className="modal fade" id="applyToAllModal" tabIndex="-1" role="dialog" aria-labelledby="applyToAllLabel" aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="applyToAllLabel">Add New Payment</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								Would you like to make this your default payment method? This will be applied to all active subscriptions. 
								If not, this payment method must be applied to subscriptions individually.
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={this.handleSubmit.bind(this)} data-dismiss="modal">No</button>
								<button type="button" className="btn btn-primary" value='applyToAll' onClick={this.applyToAll.bind(this)} data-dismiss='modal'>Yes</button>
							</div>
							</div>
						</div>
					</div>
				</li>
				
			)
		} else {
			return (
				<div></div>
			)
		}
		
	}

 	render(){
		var cardItems = this.state.cards.map(this.renderCCItem.bind(this))
		var removeHandler = this.removeAlertHandler.bind(this)
 		return (
				<div className='card mb-4'>
					<div className='card-header'>
						<span className='h4'>Payment Methods</span>	
					</div>
					<div className='card-body'>
						<div>
							<ul className='list-group mb-2'>
								<li className='list-group-item lead text-center'>
									<div className='row '>
										<div className='col'>
											<span className='h4'>Your credit and debit cards</span>
										</div>
										<div className='col'>
											<span>Name on card</span>
										</div>
										<div className='col'>
											<span>Expires on</span>
										</div>
										<div className='col'>
										</div>
									</div>
								</li>
								{cardItems}
							</ul>
							<button className='btn btn-outline-primary'
							onClick={this.toggleCreating.bind(this)}>
								<FontAwesomeIcon icon={ faPlus } />
							</button>
						</div>
						{this.renderAddCC()}
					</div>
					<Alert message={this.state.alertMessage} type={this.state.alertType } onExit={removeHandler}/>
				</div>
		)
	 }
}

export default Payment
